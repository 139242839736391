import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {ImageUploadModule} from "angular2-image-upload";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import {TermsModal} from "../../shared/terms-and-conditions/terms.modal";
import * as fromMerchantMembership from "./+state";
import {BecomeAMerchantModal} from "./become-a-merchant/become-a-merchant.modal";
import {EnrollMerchantModal} from "./enroll/enroll-merchant.modal";
import {JoinExistingMerchantModal} from "./join-existing-merchant/join-existing-merchant.modal";

@NgModule({
	declarations: [
		EnrollMerchantModal,
		BecomeAMerchantModal,
		JoinExistingMerchantModal,
		TermsModal,
	],
	entryComponents: [
		EnrollMerchantModal,
		BecomeAMerchantModal,
		JoinExistingMerchantModal,
		TermsModal
	],
	exports: [
		EnrollMerchantModal,
		BecomeAMerchantModal,
		JoinExistingMerchantModal,
		TermsModal
	],
	imports: [
		IonicModule,
		ImageUploadModule.forRoot(),
		MovebeSharedModule,
		StoreModule.forFeature(fromMerchantMembership.featureName, fromMerchantMembership.reducer),
		EffectsModule.forFeature(fromMerchantMembership.effects),
	],
	providers: []
})
export class MerchantMembershipModule {
}
