import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BenefitType} from "../../../../lib/model/benefit.model";
import {Offer} from "../../../../lib/model/offer.model";

@Component({
	selector: "movebe-merchant-offer",
	styleUrls: ["./merchant-offer.component.scss"],
	templateUrl: "./merchant-offer.component.html"
})
export class MerchantOfferComponent implements OnInit {

	BenefitType = BenefitType;
	@Input() activate: Offer;
	@Input() checkbox: Offer;
	@Input() deactivate: Offer;
	@Input() offer: Offer;
	@Output() offerActivated = new EventEmitter<Offer>();
	@Output() offerDeactivated = new EventEmitter<Offer>();
	@Input() selected: boolean;
	@Input() showStamps: boolean;
	stamps: any[];

	ngOnInit() {
		if (this.offer.requiredValidations > 1) {
			this.stamps = new Array(this.offer.requiredValidations).fill({});
		}
	}

	offerActivate(event: MouseEvent) {
		event.stopPropagation();
		this.offerActivated.emit(this.offer);
	}

	offerDeactivate(event: MouseEvent) {
		event.stopPropagation();
		this.offerDeactivated.emit(this.offer);
	}

}
