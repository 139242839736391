import {Directive, HostBinding, Input, OnInit} from "@angular/core";

@Directive({
	selector: "img[movebeLogo]"
})
export class MovebeLogoDirective implements OnInit {

	@HostBinding("style.height") height = "auto";
	@HostBinding("style.width") width = "auto";
	@HostBinding("src") src = "assets/img/movebe.icon.svg";

	@Input() imageSize = 30;

	ngOnInit() {
		this.height = `${this.imageSize}px`;
		this.width = `${this.imageSize}px`;
	}

}
