import {Component, EventEmitter, Input, OnChanges, Output} from "@angular/core";
import {OpeningHours, OpeningPeriod, OpeningPeriodRange} from "../../core/mapping/location.model";
import {collapseHorizontal} from "../animations/animations";

@Component({
	animations: [
		collapseHorizontal
	],
	selector: "movebe-opening-hours",
	templateUrl: "opening-hours.component.html"
})
export class OpeningHoursComponent implements OnChanges {

	@Input() openingHours: OpeningHours;
	@Output() openingHoursChange = new EventEmitter<OpeningHours>();
	openingPeriods: OpeningPeriodMinutes[] = [];
	readonly daysInWeek = 7;

	ngOnChanges(): void {
		if (this.openingHours) {
			this.openingPeriods = this.openingHours.days
				? this.openingHours.days
					.map((openingPeriod: OpeningPeriod) => ({
						closed: openingPeriod.closed,
						range: {
							lower: openingPeriod.closed ? 0 : (openingPeriod as OpeningPeriodRange).open,
							upper: openingPeriod.closed ? 0 : (openingPeriod as OpeningPeriodRange).close
						}
					}))
				: new Array(this.daysInWeek).fill({
					closed: true,
					range: {lower: 0, upper: 0}
				});
		}
	}

	emit() {
		this.openingHoursChange.emit(
			{
				alwaysOpen: this.openingHours.alwaysOpen || false,
				days: this.openingPeriods.map((openingPeriodMinutes): OpeningPeriod => {
					return openingPeriodMinutes.closed
						? {closed: true}
						: {closed: false, close: openingPeriodMinutes.range.upper, open: openingPeriodMinutes.range.lower};
				})
			});
	}

	rangeChanged(openingPeriodMinutes: OpeningPeriodMinutes) {
		if (openingPeriodMinutes.range.lower === openingPeriodMinutes.range.upper) {
			openingPeriodMinutes.closed = true;
		}
		this.emit();
	}

	reOpen(event: Event, openingPeriodMinutes: OpeningPeriodMinutes) {
		const defaultOpenTime = 540;
		const defaultCloseTime = 1200;
		event.preventDefault();
		event.stopPropagation();
		openingPeriodMinutes.closed = false;
		openingPeriodMinutes.range.lower = defaultOpenTime;
		openingPeriodMinutes.range.upper = defaultCloseTime;
		this.emit();
	}
}

interface OpeningPeriodMinutes {
	closed: boolean;
	range: {
		lower: number;
		upper: number;
	};
}
