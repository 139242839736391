import {Component, OnInit} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {
	EmploymentRelationship,
	MerchantRole,
	MerchantUserStatus
} from "../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {BusyService} from "../../../core/busy/busy.service";
import {DataImporterService} from "../../../core/data/data-importer.service";
import {FirestoreService} from "../../../core/firebase/firestore.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";

@Component({
	templateUrl: "./import.page.html"
})
export class ImportPage implements OnInit {
	readonly employmentRelationship: EmploymentRelationship = {
		role: MerchantRole.manager,
		status: MerchantUserStatus.active,
		universal: true
	};
	merchants$: Observable<Merchant[]>;

	constructor(private busyService: BusyService,
							private dataImporterService: DataImporterService,
							private firestoreService: FirestoreService,
							private logger: Logger,
							private merchantsService: MerchantsService,
							private movebeApiService: MovebeApiService) {
	}

	ngOnInit() {
		this.merchants$ = this.merchantsService.getMerchants();
	}

	/*tslint:disable:member-ordering*/
	cleanEnvironment() {
		this.busyService.setBusy(this.dataImporterService.cleanEnvironment());
	}

	deleteTransactions() {
		this.busyService.setBusy(this.dataImporterService.deleteTransactions());
	}

	importBenefits() {
		this.busyService.setBusy(this.dataImporterService.importBenefits());
	}

	importData() {
		this.busyService.setBusy(this.dataImporterService.importData());
	}

	importGateways() {
		this.busyService.setBusy(this.dataImporterService.importGateways());
	}

	importMerchants() {
		this.busyService.setBusy(this.dataImporterService.importMerchantData());
	}

	importProviders() {
		this.busyService.setBusy(this.dataImporterService.importProviders());
	}

	importPermitTags() {
		this.busyService.setBusy(this.dataImporterService.importPermitTags());
	}

	importSettings() {
		this.busyService.setBusy(this.dataImporterService.importSettings());
	}

	importSurveys() {
		this.busyService.setBusy(this.dataImporterService.importSurveys());
	}

	makeMeManager() {
		this.busyService.setBusy(new Promise<void>((resolve, reject) => {
			this.merchants$.first().subscribe((merchants: Merchant[]) => {
				merchants.forEach((merchant: Merchant) => {
					this.dataImporterService.addMerchantUser(merchant, this.employmentRelationship)
						.catch(error => this.logger.error(error));
				});
				resolve();
			});
		}));
	}

	replicateUsers() {
		return this.firestoreService.toListStream(this.firestoreService.getUserProfiles())
			.first()
			.toPromise()
			.then(users => {
				return Promise.all(users.map(user => this.movebeApiService.putUser(user)));
			}).catch(error => console.error(error));
	}
}
