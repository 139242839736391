export enum FirebaseAuthErrorCode {
	appDeleted = "auth/app-deleted",
	appNotAuthorized = "auth/app-not-authorized",
	argumentError = "auth/argument-error",
	emailAlreadyInUse = "auth/email-already-in-use",
	invalidApiKey = "auth/invalid-api-key",
	invalidUserToken = "auth/invalid-user-token",
	networkRequestFailed = "auth/network-request-failed",
	operationNotAllowed = "	auth/operation-not-allowed",
	requiresRecentLogin = "	auth/requires-recent-login",
	tooManyRequests = "auth/too-many-requests",
	unauthorizedDomain = "	auth/unauthorized-domain",
	userDisabled = "	auth/user-disabled",
	userTokenExpired = "	auth/user-token-expired",
	webStorageUnsupported = "auth/web-storage-unsupported",
}
