import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as FileSaver from "file-saver";
import * as moment from "moment/moment";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MovebeState} from "../../app/movebe-state.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../lib/user/+state";
import {FirebaseService} from "../firebase/firebase.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";
import {FloorPlan} from "./floor-plan.model";
import {FloorPlanPlacement, MarkerCodeGeotag} from "./marker-code-geotag.model";
import {MarkerDescriptor} from "./marker-descriptor.model";
import {MarkerScan} from "./marker-scan.model";

@Injectable()
export class MovebeMarkersService {

	readonly userId$: Observable<string>;

	constructor(private fb: FirebaseService,
							private movebeApiService: MovebeApiService,
							private store: Store<MovebeState>) {
		this.userId$ = this.store.select(fromUser.getUserId).pipe(filterNulls());
	}

	addMarkerCode(merchantId: string, locationId: string, markerCode: string) {
		this.fb.addMarkerCode(merchantId, locationId, markerCode);
	}

	addMarkerDescriptor(locationId: string, descriptorName: string) {
		this.fb.addMarkerDescriptor(locationId, descriptorName);
	}

	addMarkerDescriptorValue(locationId, descriptorId: string, value: string) {
		this.fb.addMarkerDescriptorValue(locationId, descriptorId, value);
	}

	deleteMarkerDescriptor(locationId: string, descriptorId: string) {
		this.fb.deleteMarkerDescriptor(locationId, descriptorId);
	}

	deleteMarkerDescriptorValue(locationId: string, descriptorId: string, valueId: string) {
		return this.fb.deleteMarkerDescriptorValue(locationId, descriptorId, valueId);
	}

	generateSigns(howMany): Promise<void> {
		return this.movebeApiService
			.getMarkerSignPdf(howMany)
			.toPromise()
			.then((fileBlob: Blob) => {
				const now = moment(new Date()).format("YYYY-MM-DD.HH:mm:ss");
				const filename = `MovebeMarkers.${howMany}-sign${howMany === 1 ? "" : "s"}.${now}.pdf`;
				FileSaver.saveAs(fileBlob, filename); //tslint:disable-line:no-unsafe-any
			});
	}

	getCurrentUserRecentMarkerScans(): Observable<MarkerScan[]> {
		return this.userId$.flatMap(userId => {
			return this.fb.toListStream(this.fb.getRecentMarkerScans(userId)).pipe(
				map(array => array.reverse()));
		});
	}

	getFloorPlan(merchantId: string, locationId: string, floorPlanId: string): Observable<FloorPlan | null> {
		return this.fb.toObjectStream(this.fb.getFloorPlan(merchantId, locationId, floorPlanId));
	}

	getFloorPlans(merchantId: string, locationId: string): Observable<FloorPlan[]> {
		return this.fb.toListStream(this.fb.getFloorPlans(merchantId, locationId));
	}

	getMarkerCodeGeotags(merchantId, locationId: string): Observable<MarkerCodeGeotag[]> {
		return this.fb.toListStream(this.fb.getMarkerCodeGeotags(locationId));

	}

	getMarkerData(markerCode: string) {
		return this.fb.getMarkerData(markerCode);
	}

	getMarkerDescriptors(locationId: string): Observable<MarkerDescriptor[]> {
		return this.fb.toListStream(this.fb.getMarkerDescriptors(locationId));
	}

	getMarkerScan(scanId: string): Observable<any> {
		return this.userId$.flatMap(userId => {
			return this.fb.getMarkerScan(scanId, userId);
		});
	}

	processMarkerScan(markerCode: string): Promise<string> {
		return this.getMarkerData(markerCode)
			.withLatestFrom(this.userId$)
			.first()
			.toPromise()
			.then(([markerData, userId]) => {
				return this.fb.logUserScannedMarker(markerCode, userId);
			});
	}

	setMarkerCodeDescriptorValue(locationId: string, markerCode: string, descriptor: string, value: string) {
		this.fb.setMarkerCodeDescriptorValue(locationId, markerCode, descriptor, value);
	}

	setMarkerCodeFloorPlanPlacement(markerCodeId: string, floorPlanPlacement: FloorPlanPlacement) {
		return this.fb.setFloorPlanPlacement(markerCodeId, floorPlanPlacement);
	}

	uploadFloorPlan(merchantId, locationId: string, name: string, img: Blob) {
		this.fb.uploadFloorPlan(merchantId, locationId, name, img);
	}

	validateMarkerCode(markerCode: string): Observable<boolean> {
		return this.movebeApiService.validateMarkerCode(markerCode);
	}
}
