import {Injectable} from "@angular/core";
import * as moment from "moment/moment";
import {Coupon} from "../../../../lib/model/benefit.model";
import {OfferDetails} from "../../../../lib/model/offer-details.model";
import {PendingReward} from "../../../../lib/model/reward.model";
import {FirebaseService} from "../firebase/firebase.service";
import {Voucher} from "./voucher.model";

@Injectable()
export class CouponService {

	duration: moment.Duration;

	constructor(private fb: FirebaseService) {
	}

	getCouponVoucher(pendingReward: PendingReward, offerDetails: OfferDetails): Voucher {
		const coupon: Coupon = offerDetails.benefit as Coupon;
		const expires = moment().add(moment.duration(offerDetails.offer.expires)).toISOString();
		return {
			barCodeType: coupon.barCodeType,
			barCodeValue: coupon.barCodeValue,
			description: coupon.description,
			title: coupon.title,
		};
	}

}
