import {Component, EventEmitter, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromUser from "../+state";
import {Employer} from "../../../../../lib/model/employment-relationship.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import * as fromMerchant from "../../../app-modes/merchant/+state";
import {AppActions} from "../../../app/+state/app.actions";
import * as fromApp from "../../../app/+state/index";
import {MovebeState} from "../../../app/movebe-state.model";
import {AppMode} from "../../../core/app-mode/app-mode.model";

@Component({
	selector: "movebe-app-mode-chooser",
	styleUrls: ["./app-mode-chooser.component.scss"],
	templateUrl: "./app-mode-chooser.component.html"
})
export class AppModeChooserComponent {

	AppMode = AppMode;
	appMode$: Observable<AppMode>;
	employers$: Observable<Employer[]>;
	@Output() appModeChanged = new EventEmitter<AppMode>();
	userProfile$: Observable<UserProfile | null>;

	constructor(private store: Store<MovebeState>) {
		this.appMode$ = this.store.select(fromApp.getAppMode);
		this.userProfile$ = this.store.select(fromUser.getUserProfile);
		this.employers$ = this.store.select(fromMerchant.getEmployers);
	}

	appModeSelectionChanged(appMode: AppMode) {
		this.employers$.first().subscribe( employers => {
			if (appMode !== AppMode.merchant || (employers && employers.length > 0)) {
				this.store.dispatch(new AppActions.SelectAppMode(appMode));
			}
			this.appModeChanged.emit(appMode);
		});
	}
}
