import {Injectable} from "@angular/core";
import {BarcodeScanner} from "@ionic-native/barcode-scanner/ngx";
import {AlertController, Platform} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {AppLink} from "../../../../lib/model/app-link.model";
import {AppLinkService} from "../app-links/app-link.service";

@Injectable()
export class ScanningService {

	constructor(private barcodeScanner: BarcodeScanner,
							private appLinkService: AppLinkService,
							private alertCtrl: AlertController,
							private platform: Platform,
							private translateService: TranslateService) {
	}

	/**
	 * Scans MoveBe QR code App Links using cordova a Barcode Scanner plugin
	 * @param scanPrompt
	 * @return {Promise<AppLink>}
	 */
	scan(scanPrompt: string): Promise<string> {
		return this.barcodeScanner
			.scan({
				formats: "QR_CODE",
				prompt: scanPrompt // scan prompt works on Android only
			}).then(scanResult => scanResult.cancelled
				? this.translateService.instant("SCAN.CANCELLED") as string
				: scanResult.text
			);
	}

}
