import {Component} from "@angular/core";
import {AlertController, ModalController, NavController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state/index";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {GeolocationService} from "../../../core/geolocation/geolocation.service";
import {MappingService} from "../../../core/mapping/mapping.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {collapseHorizontal} from "../../../shared/animations/animations";

@Component({
	animations: [
		collapseHorizontal
	],
	selector: "movebe-merchants-location",
	styleUrls: ["./location.modal.scss"],
	templateUrl: "./location.modal.html"
})
export class LocationModal {
	initialLatLng;
	readonly locationDigits = 4;
	merchant$: Observable<Merchant | undefined>;
	location: MerchantLocation;
	locationId: string | null;

	constructor(private alertCtrl: AlertController,
							private busyService: BusyService,
							private geolocationService: GeolocationService,
							private mappingService: MappingService,
							private merchantsService: MerchantsService,
							private navCtrl: NavController,
							private navParams: NavParams,
							private store: Store<MovebeState>,
							private translate: TranslateService,
							private modalController: ModalController) {
		this.location = {...this.navParams.get("location") as MerchantLocation};
		this.locationId = this.navParams.get("locationId") as string;
		this.merchant$ = this.store.select(fromMerchant.getCurrentMerchant);
	}

	saveMerchantLocation(): Promise<void> {
		if (!this.locationId) {
			const addLocationPromise = this.merchantsService.addLocation(this.location);
			this.busyService.setBusy(addLocationPromise);
			return addLocationPromise
				.then(() => this.modalController.dismiss({added: true}));
		} else {
			this.merchantsService.updateLocation({
				...this.location,
				$key: this.locationId,
				vicinity: this.location.vicinity || `${this.location.address!.street}, ${this.location.address!.municipality}`,
			});
			return this.modalController.dismiss();
		}
	}

	manageInMapChanged() {
		if (this.location.useGoogleMapsLocationData) {
			const getDetailsPromise = this.mappingService.getDetails(this.location.googlePlaceId!)
				.toPromise()
				.then(details => {
					this.location = {
						...this.location,
						...details
					};
				});
			this.busyService.setBusy(getDetailsPromise, this.translate.instant("LOCATIONS.GETTING_LOCATION_DETAILS"));
			return getDetailsPromise;
		}
	}

	dismiss() {
		return this.modalController.dismiss();
	}

}
