import {Component} from "@angular/core";
import {Nav} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {map, switchMap} from "rxjs/operators";
import * as fromMerchant from "../+state/index";
import {StakeholderBalances} from "../../../../../lib/model/merchant-balance.model";
import {Merchant, MerchantStatus} from "../../../../../lib/model/merchant.model";
import {RewardDetails} from "../../../../../lib/model/reward.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {OffersService} from "../../../core/offers/offers.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {CouponsPage} from "../coupons/coupons.page";
import {LocationsPage} from "../locations/locations.page";
import {OffersPage} from "../offers/offers.page";

@Component({
	selector: "page-mm-dashboard",
	styleUrls: ["./dashboard.page.scss"],
	templateUrl: "./dashboard.page.html"
})
export class DashboardPage {
	readonly currentMerchant$: Observable<Merchant | undefined>;
	readonly currentMerchantId$: Observable<string | undefined>;
	readonly currentMerchantLocationCount$: Observable<number>;
	readonly currentMerchantOfferCount$: Observable<number>;
	readonly currentMerchantCouponCount$: Observable<number>;
	readonly MerchantStatus = MerchantStatus;
	readonly recentValidations$: Observable<RewardDetails[]>;
	readonly merchantBalance$: Observable<StakeholderBalances | null>;

	constructor(private logger: Logger,
							private nav: Nav,
							private offersService: OffersService,
							private merchantsService: MerchantsService,
							private store: Store<MovebeState>) {

		this.currentMerchantId$ = this.store.pipe(select(fromMerchant.getCurrentMerchantId));
		this.currentMerchant$ = this.store.pipe(select(fromMerchant.getCurrentMerchant));
		this.merchantBalance$ = this.currentMerchantId$.pipe(
			filterNulls(),
			switchMap(merchantId => this.merchantsService.getMerchantBalance(merchantId))
		);

		this.currentMerchantLocationCount$ = this.store.pipe(
			select(fromMerchant.getMerchantLocations),
			map(locations => locations.length)
		);

		this.currentMerchantCouponCount$ = this.currentMerchantId$
			.pipe(
				filterNulls(),
				switchMap(merchantId => this.offersService.getCoupons(merchantId)),
				map(coupons => coupons.length)
			);

		this.currentMerchantOfferCount$ = this.store.pipe(
			select(fromMerchant.getMerchantOffers),
			map(offers => offers.filter(offer => offer.published).length)
		);

		this.recentValidations$ = this.store.pipe(
			select(fromMerchant.getMerchantRecentValidations),
			map(validations => validations)
		);

	}

	openLocations() {
		this.nav.push(LocationsPage)
			.catch(error => this.logger.error(error));
	}

	openCoupons() {
		this.nav.push(CouponsPage)
			.catch(error => this.logger.error(error));
	}

	openOffers() {
		this.nav.push(OffersPage)
			.catch(error => this.logger.error(error));
	}
}
