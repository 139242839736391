import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {OfferSearchModule} from "../../lib/offer-search/offer-search.module";
import {SurveyModule} from "../../lib/survey/survey.module";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import * as fromConsumer from "./+state";
import {AppPage} from "./app/app.page";
import {FindPage} from "./find/find.page";
import {HistoryPage} from "./history/history.page";
import {MarkerListPage} from "./markers/marker-list.page";
import {MarkerPage} from "./markers/marker.page";
import {MerchantLocationPage} from "./merchant-location/merchant-location.page";
import {OfferPage} from "./offer/offer.page";
import {RewardPage} from "./reward/reward.page";
import {RewardsPage} from "./rewards/rewards.page";
import {ScanPage} from "./scan/scan.page";

@NgModule({
	declarations: [
		AppPage,
		FindPage,
		HistoryPage,
		MarkerListPage,
		MarkerPage,
		MerchantLocationPage,
		OfferPage,
		ScanPage,
		RewardPage,
		RewardsPage,
	],
	entryComponents: [
		AppPage,
		FindPage,
		HistoryPage,
		MarkerListPage,
		MarkerPage,
		MerchantLocationPage,
		OfferPage,
		ScanPage,
		RewardPage,
		RewardsPage,
	],
	exports: [
		AppPage,
		FindPage,
		HistoryPage,
		MarkerListPage,
		MarkerPage,
		MerchantLocationPage,
		OfferPage,
		ScanPage,
		RewardPage,
		RewardsPage,
	],
	imports: [
		IonicModule,
		MovebeSharedModule,
		OfferSearchModule,
		StoreModule.forFeature(fromConsumer.featureName, fromConsumer.reducer),
		SurveyModule,
		EffectsModule.forFeature(fromConsumer.effects),
	],
	providers: []
})

export class MovebeModule {
}
