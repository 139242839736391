import {I18nLookupTable} from "../i18n/i18n-lookup-table.model";

export const MerchantTypes: I18nLookupTable = {
	accounting: {en: "Accounting"},
	airport: {en: "Airport"},
	amusement_park: {en: "Amusement Park"},
	aquarium: {en: "Aquarium"},
	art_gallery: {en: "Art Gallery"},
	bakery: {en: "Bakery"},
	bank: {en: "Bank"},
	bar: {en: "Bar"},
	beauty_salon: {en: "Beauty Salon"},
	bicycle_store: {en: "Bicycle Store"},
	book_store: {en: "Book Store"},
	bowling_alley: {en: "Bowling Alley"},
	bus_station: {en: "Bus Station"},
	cafe: {en: "Cafe"},
	campground: {en: "Campground"},
	car_dealer: {en: "Car Dealer"},
	car_rental: {en: "Car Rental"},
	car_repair: {en: "Car Repair"},
	car_wash: {en: "Car Wash"},
	casino: {en: "Casino"},
	//church: {en: "Church"},
	//city_hall: {en: "City Hall"},
	clothing_store: {en: "Clothing Store"},
	convenience_store: {en: "Convenience Store"},
	courthouse: {en: "Courthouse"},
	dentist: {en: "Dentist"},
	department_store: {en: "Department Store"},
	doctor: {en: "Doctor"},
	electrician: {en: "Electrician"},
	electronics_store: {en: "Electronics Store"},
	florist: {en: "Florist"},
	furniture_store: {en: "Furniture Store"},
	gas_station: {en: "Gas Station"},
	gym: {en: "Gym"},
	hair_care: {en: "Hair Care"},
	hardware_store: {en: "Hardware Store"},
	home_goods_store: {en: "Home Goods Store"},
	hospital: {en: "Hospital"},
	insurance_agency: {en: "Insurance Agency"},
	jewelry_store: {en: "Jewelry Store"},
	laundry: {en: "Laundry"},
	lawyer: {en: "Lawyer"},
	library: {en: "Library"},
	liquor_store: {en: "Liquor Store"},
	//local_government_office: {en: "Local Government Office"},
	locksmith: {en: "Locksmith"},
	lodging: {en: "Lodging"},
	meal_delivery: {en: "Meal Delivery"},
	meal_takeaway: {en: "Meal Takeaway"},
	movie_theater: {en: "Movie Theater"},
	//moving_company: {en: "Moving Company"},
	museum: {en: "Museum"},
	night_club: {en: "Night Club"},
	office: {en: "Office"},
	other: {en: "Other"},
	painter: {en: "Painter"},
	parking_lot: {en: "Parking Lot"},
	pet_store: {en: "Pet Store"},
	pharmacy: {en: "Pharmacy"},
	physiotherapist: {en: "Physiotherapist"},
	plumber: {en: "Plumber"},
	post_office: {en: "Post Office"},
	real_estate_agency: {en: "Real Estate Agency"},
	restaurant: {en: "Restaurant"},
	//roofing_contractor: {en: "Roofing Contractor"},
	//rv_park: {en: "Rv Park"},
	shoe_store: {en: "Shoe Store"},
	shopping_mall: {en: "Shopping Mall"},
	spa: {en: "Spa"},
	stadium: {en: "Stadium"},
	store: {en: "Store"},
	subway_station: {en: "Subway Station"},
	travel_agency: {en: "Travel Agency"},
	university: {en: "University"},
	veterinary_care: {en: "Veterinary Care"},
	zoo: {en: "Zoo"}
};
