import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {fade} from "../animations/animations";

@Component({
	animations: [
		fade
	],
	selector: "movebe-form-validation-message",
	styleUrls: ["form-validation-message.component.scss"],
	templateUrl: "form-validation-message.component.html",
})
export class FormValidationMessageComponent {
	@Input() ctrl: FormControl;
}
