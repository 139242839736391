import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "movebeDayOfWeek"})
export class DayOfWeekPipe implements PipeTransform {
	days = ["S", "M", "T", "W", "T", "F", "S"];

	transform(day: number): string {
		return this.days[day];
	}
}
