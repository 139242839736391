import {Component} from "@angular/core";
import {Nav, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {map} from "rxjs/operators";
import {Employee, EmploymentRelationship, MerchantRole} from "../../../../../lib/model/employment-relationship.model";
import {StakeholderBalances} from "../../../../../lib/model/merchant-balance.model";
import {Merchant, MerchantStatus} from "../../../../../lib/model/merchant.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../../app/movebe-state.model";

import {I18nLookupTable} from "../../../core/i18n/i18n-lookup-table.model";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {MerchantTypes} from "../../../core/merchant/merchant-types";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {CurrentUserService} from "../../../core/user/current-user.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {RecentValidationsPage} from "./recent-validations.page";

@Component({
	selector: "page-admin-merchant",
	styleUrls: ["./merchant.page.scss"],
	templateUrl: "./merchant.page.html"
})
export class MerchantPage {
	async: any;
	readonly locations$: Observable<MerchantLocation[]>;
	readonly merchant: Merchant;

	readonly merchantEmployees$: Observable<Employee[]>;
	readonly merchantManagers$: Observable<{profile: UserProfile; employmentRelationship: EmploymentRelationship}[]>;
	readonly MerchantStatus = MerchantStatus;
	readonly merchantTypes: I18nLookupTable;
	readonly balances$: Observable<StakeholderBalances | null>;

	constructor(private currentUserService: CurrentUserService,
							private merchantsService: MerchantsService,
							private store: Store<MovebeState>,
							private nav: Nav,
							private navParams: NavParams) {
		this.merchantTypes = MerchantTypes;
		this.merchant = this.navParams.get("merchant") as Merchant;
		this.locations$ = this.merchantsService.getLocations(this.merchant.$key!);
		this.balances$ = this.merchantsService.getMerchantBalance(this.merchant.$key!);
		this.merchantEmployees$ = this.merchantsService.getEmployees(this.merchant.$key!);
		this.merchantManagers$ = this.merchantEmployees$
			.map(employees => employees.filter(employee =>
				employee.employmentRelationship.role === MerchantRole.manager
				|| employee.employmentRelationship.role === MerchantRole.owner
			))
			//TODO: refactor to use childJoin
			.switchMap(employees => Observable
				.combineLatest(employees
					.map(employee =>
						this.currentUserService.getUserProfile(employee.user.key)
							.pipe(
								filterNulls(),
								map((profile: UserProfile) => {
									return {
										employmentRelationship: employee.employmentRelationship,
										profile
									};
								})
							)
					)
				)
			);

	}

	activateMerchant(merchant: Merchant) {
		merchant.status = MerchantStatus.approved;
		this.merchantsService.updateMerchant(merchant);
	}

	disableMerchant(merchant: Merchant) {
		merchant.status = MerchantStatus.disabled;
		this.merchantsService.updateMerchant(merchant);
	}

	rejectMerchant(merchant: Merchant) {
		merchant.status = MerchantStatus.rejected;
		this.merchantsService.updateMerchant(merchant);
	}

	getStatusIcon(merchantStatus) {
		return this.merchantsService.getStatusIcon(merchantStatus);
	}

	openRecentValidations() {
		return this.nav.push(RecentValidationsPage, {merchant: this.merchant});
	}
}
