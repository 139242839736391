import {Component, ElementRef} from "@angular/core";

@Component({
	selector: "movebe-scroll-list-item",
	styleUrls: ["scroll-list-item.component.scss"],
	templateUrl: "scroll-list-item.component.html"
})
export class ScrollListItemComponent {
	nativeElement: HTMLElement;

	constructor(elementRef: ElementRef) {
		this.nativeElement = elementRef.nativeElement as HTMLElement;
	}
}
