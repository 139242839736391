import {Injectable} from "@angular/core";
import {Actions, Effect} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {concatMap, map, switchMap, withLatestFrom} from "rxjs/operators";
import {ofType, toPayload} from "ts-action-operators";
import {AddedChatMessage, ChatMessage, MessageType} from "../../../../../lib/model/chat/chat-message.model";
import {Chat} from "../../../../../lib/model/chat/chat.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {FirestoreService} from "../../../core/firebase/firestore.service";
import {filterNulls} from "../../rxjs-operators/filter-nulls";
import * as fromUser from "../../user/+state";
import {ChatActions} from "./chat.actions";
import * as fromChat from "./index";

@Injectable()
export class ChatEffects {

	readonly currentChatId$ = this.store.pipe(select(fromChat.getChatId), filterNulls());

	readonly currentUserId$ = this.store.pipe(select(fromUser.getUserId), filterNulls());

	@Effect() readonly StartChatEffect$ = this.actions$.pipe(ofType(ChatActions.StartChat),
		switchMap(() => this.startChat()),
		map((chat) => new ChatActions.ChatReceived(chat!))
	);

	@Effect() readonly ChatReceivedEffect$ = this.actions$.pipe(ofType(ChatActions.ChatReceived), toPayload(),
		switchMap((chat: Chat) => this.getChatMessages(chat.$key!)),
		map((messages) => new ChatActions.ChatMessagesReceived(messages))
	);

	@Effect() readonly SendMessageEffect = this.actions$.pipe(ofType(ChatActions.SendMessage), toPayload(),
		withLatestFrom(this.currentChatId$, this.currentUserId$),
		concatMap(([message, chatId, userId]) => this.sendChatMessage(chatId, userId, message)),
		map(() => new ChatActions.MessageSent)
	);

	constructor(private actions$: Actions,
							private store: Store<MovebeState>,
							private firestoreService: FirestoreService) {
	}

	startChat(): Observable<Chat | null> {
		return Observable.fromPromise(
			this.firestoreService.getChats()
				.add({
					createdTimestamp: this.firestoreService.serverTimestamp()
				})
		).flatMap(chatRef => this.firestoreService.toObjectStream(this.firestoreService.getChat(chatRef.id)));
	}

	getChatMessages(chatId: string): Observable<ChatMessage[]> {
		return this.firestoreService.toListStream(this.firestoreService.getChatMessages(chatId, ref => ref.orderBy("timestamp", "asc")));
	}

	sendChatMessage(chatId: string, userId: string, message: string) {
		const addedChatMessage: AddedChatMessage = {
			from: userId,
			message,
			timestamp: this.firestoreService.serverTimestamp(),
			type: MessageType.request
		};
		return this.firestoreService.getChatMessages(chatId).add(addedChatMessage);
	}
}
