import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NguiMapComponent} from "@ngui/map";
import {Observable} from "rxjs/Observable";
import {LatLngLiteral} from "../../../core/mapping/lat-lng-literal";
import {MappingService} from "../../../core/mapping/mapping.service";
import {MapMarkerState, MapMarkerType} from "../map-marker-type.model";
import {MapMarker} from "../map-marker.class";
import {movebeMapStyles} from "../map-styles.data";

@Component({
	selector: "movebe-map-js",
	styleUrls: ["map-js.component.scss"],
	templateUrl: "map-js.component.html"
})
export class MapJsComponent implements OnInit {

	@Input() highlightedItem$: Observable<{itemNumber: number; itemType: MapMarkerType}>;
	@Input() mapCenter: LatLngLiteral;
	@Input() offerMarkers$: Observable<MapMarker[]>;
	@Input() parkingMarkers$: Observable<MapMarker[]>;

	@Output() onMapMoved = new EventEmitter<any>();
	@Output() onMapSized = new EventEmitter<number>();
	@Output() markerClicked = new EventEmitter<{itemNumber: number; itemType: MapMarkerType}>();

	highlightMarker$: Observable<MapMarker[]>;
	mapOptions: google.maps.MapOptions;

	constructor(private mappingService: MappingService) {
	}

	ngOnInit() {
		this.mapOptions = {
			center: {lat: this.mapCenter.lat, lng: this.mapCenter.lng},
			disableDefaultUI: true,
			styles: movebeMapStyles,
			zoom: 14
		};

		this.highlightMarker$ = Observable.combineLatest(
			this.offerMarkers$,
			this.parkingMarkers$,
			this.highlightedItem$,
			(offerMarkers, parkingMarkers, highlightedItem) => {
				return (highlightedItem.itemType === MapMarkerType.parking ? parkingMarkers : offerMarkers)
					.slice(highlightedItem.itemNumber, highlightedItem.itemNumber + 1)
					.map(marker => new MapMarker(marker.position, marker.markerType, MapMarkerState.highlight));
			}
		);
	}

	offerMarkerClick(itemNumber: number) {
		this.markerClicked.emit({itemNumber, itemType: MapMarkerType.merchant});
	}

	parkingMarkerClick(itemNumber: number) {
		this.markerClicked.emit({itemNumber, itemType: MapMarkerType.parking});
	}

	mapBoundsChanged(event: Event) {
		const map = event.target as any as google.maps.Map;
		const bounds = map.getBounds();
		const center = map.getCenter();
		if (bounds && center) {
			const ne = bounds.getNorthEast();
			this.onMapSized.emit(this.mappingService.computeDistanceBetween(center, ne));
		}
	}

	mapMoved(event: Event) {
		const target = event.target as any as NguiMapComponent;
		this.onMapMoved.emit(target.center.toJSON());	//tslint:disable-line:no-unsafe-any
	}
}
