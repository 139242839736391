import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {BecomeMerchantAction} from "./become-a-merchant-modal-result.model";

@Component({
	selector: "page-mm-become-a-merchant",
	styleUrls: ["./become-a-merchant.modal.scss"],
	templateUrl: "./become-a-merchant.modal.html"
})
export class BecomeAMerchantModal {

	constructor(private modalController: ModalController) {
	}

	enroll() {
		return this.modalController.dismiss({action: BecomeMerchantAction.EnrollNew});
	}

	join() {
		return this.modalController.dismiss({action: BecomeMerchantAction.JoinExisting});
	}

	dismiss() {
		return this.modalController.dismiss();
	}
}
