import {Dictionary} from "../model/dictionary.model";

export function indexBy<T>(list: T[], keyExtractor: ((item: T) => string)): Dictionary<T> {
	return list.reduce<Dictionary<T>>((dictionary, item) => ({
		...dictionary,
		[keyExtractor(item)]: item
	}), {});
}

export function groupBy<T>(list: T[], keyExtractor: ((item: T) => string)): Dictionary<T[]> {
	return list.reduce<Dictionary<T[]>>((dictionary, item) => {
		const key: string = keyExtractor(item);
		(dictionary[key] = dictionary[key] || []).push(item);
		return dictionary;
	}, {});
}
