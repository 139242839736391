import {Component, Input, OnInit} from "@angular/core";

@Component({
	selector: "movebe-icon",
	styleUrls: ["./movebe-icon.scss"],
	templateUrl: "./movebe-icon.html"
})

export class MovebeIconComponent implements OnInit {

	iconPath = "assets/icon/";
	@Input() iconName = "";

	ngOnInit() {
		this.setIconName(this.iconName);
	}

	setIconName(iconName = "") {
		this.iconPath = `${this.iconPath}${this.iconName}.svg`;
	}
}
