/* tslint:disable:object-literal-key-quotes */
/* tslint:disable:object-literal-sort-keys */

import {PermitTag} from "../../../../lib/model/permit-tag.model";

export const permitTagData: PermitTag[] = [
	{
		"id": "5798",
		"createTime": "17-05-19 6:13",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92035
	},
	{
		"id": "5799",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92036
	},
	{
		"id": "5800",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92037
	},
	{
		"id": "5801",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92038
	},
	{
		"id": "5802",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92039
	},
	{
		"id": "5803",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92040
	},
	{
		"id": "5804",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92041
	},
	{
		"id": "5805",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92042
	},
	{
		"id": "5806",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92043
	},
	{
		"id": "5807",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92044
	},
	{
		"id": "5808",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92045
	},
	{
		"id": "5809",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92046
	},
	{
		"id": "5810",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92047
	},
	{
		"id": "5811",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92048
	},
	{
		"id": "5812",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92049
	},
	{
		"id": "5813",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92050
	},
	{
		"id": "5814",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92051
	},
	{
		"id": "5815",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92052
	},
	{
		"id": "5816",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92053
	},
	{
		"id": "5817",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92054
	},
	{
		"id": "5818",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92055
	},
	{
		"id": "5819",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92056
	},
	{
		"id": "5820",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92057
	},
	{
		"id": "5821",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92058
	},
	{
		"id": "5822",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92059
	},
	{
		"id": "5823",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92060
	},
	{
		"id": "5824",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92061
	},
	{
		"id": "5825",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92062
	},
	{
		"id": "5826",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92063
	},
	{
		"id": "5827",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92064
	},
	{
		"id": "5828",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92065
	},
	{
		"id": "5829",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92066
	},
	{
		"id": "5830",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92067
	},
	{
		"id": "5831",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92068
	},
	{
		"id": "5832",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92069
	},
	{
		"id": "5833",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92070
	},
	{
		"id": "5834",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92071
	},
	{
		"id": "5835",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92072
	},
	{
		"id": "5836",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92073
	},
	{
		"id": "5837",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92074
	},
	{
		"id": "5838",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92075
	},
	{
		"id": "5839",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92076
	},
	{
		"id": "5840",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92077
	},
	{
		"id": "5841",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92078
	},
	{
		"id": "5842",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92079
	},
	{
		"id": "5843",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92080
	},
	{
		"id": "5844",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92081
	},
	{
		"id": "5845",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92082
	},
	{
		"id": "5846",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92083
	},
	{
		"id": "5847",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92084
	},
	{
		"id": "5848",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92085
	},
	{
		"id": "5849",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92086
	},
	{
		"id": "5850",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92087
	},
	{
		"id": "5851",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92088
	},
	{
		"id": "5852",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92089
	},
	{
		"id": "5853",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92090
	},
	{
		"id": "5854",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92091
	},
	{
		"id": "5855",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92092
	},
	{
		"id": "5856",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92093
	},
	{
		"id": "5857",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92094
	},
	{
		"id": "5858",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92095
	},
	{
		"id": "5859",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92096
	},
	{
		"id": "5860",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92097
	},
	{
		"id": "5861",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92098
	},
	{
		"id": "5862",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92099
	},
	{
		"id": "5863",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92100
	},
	{
		"id": "5864",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92101
	},
	{
		"id": "5865",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92102
	},
	{
		"id": "5866",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92103
	},
	{
		"id": "5867",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92104
	},
	{
		"id": "5868",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92105
	},
	{
		"id": "5869",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92106
	},
	{
		"id": "5870",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92107
	},
	{
		"id": "5871",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92108
	},
	{
		"id": "5872",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92109
	},
	{
		"id": "5873",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92110
	},
	{
		"id": "5874",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92111
	},
	{
		"id": "5875",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92112
	},
	{
		"id": "5876",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92113
	},
	{
		"id": "5877",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92114
	},
	{
		"id": "5878",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92115
	},
	{
		"id": "5879",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92116
	},
	{
		"id": "5880",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92117
	},
	{
		"id": "5881",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92118
	},
	{
		"id": "5882",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92119
	},
	{
		"id": "5883",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92120
	},
	{
		"id": "5884",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92121
	},
	{
		"id": "5885",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92122
	},
	{
		"id": "5886",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92123
	},
	{
		"id": "5887",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92124
	},
	{
		"id": "5888",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92125
	},
	{
		"id": "5889",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92126
	},
	{
		"id": "5890",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92127
	},
	{
		"id": "5891",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92128
	},
	{
		"id": "5892",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92129
	},
	{
		"id": "5893",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92130
	},
	{
		"id": "5894",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92131
	},
	{
		"id": "5895",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92132
	},
	{
		"id": "5896",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92133
	},
	{
		"id": "5897",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92134
	},
	{
		"id": "5898",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92135
	},
	{
		"id": "5899",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92136
	},
	{
		"id": "5900",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92137
	},
	{
		"id": "5901",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92138
	},
	{
		"id": "5902",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92139
	},
	{
		"id": "5903",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92140
	},
	{
		"id": "5904",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92141
	},
	{
		"id": "5905",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92142
	},
	{
		"id": "5906",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92143
	},
	{
		"id": "5907",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92144
	},
	{
		"id": "5908",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92145
	},
	{
		"id": "5909",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92146
	},
	{
		"id": "5910",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92147
	},
	{
		"id": "5911",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92148
	},
	{
		"id": "5912",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92149
	},
	{
		"id": "5913",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92150
	},
	{
		"id": "5914",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92151
	},
	{
		"id": "5915",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92152
	},
	{
		"id": "5916",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92153
	},
	{
		"id": "5917",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92154
	},
	{
		"id": "5918",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92155
	},
	{
		"id": "5919",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92156
	},
	{
		"id": "5920",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92157
	},
	{
		"id": "5921",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92158
	},
	{
		"id": "5922",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92159
	},
	{
		"id": "5923",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92160
	},
	{
		"id": "5924",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92161
	},
	{
		"id": "5925",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92162
	},
	{
		"id": "5926",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92163
	},
	{
		"id": "5927",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92164
	},
	{
		"id": "5928",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92165
	},
	{
		"id": "5929",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92166
	},
	{
		"id": "5930",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92167
	},
	{
		"id": "5931",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92168
	},
	{
		"id": "5932",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92169
	},
	{
		"id": "5933",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92170
	},
	{
		"id": "5934",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92171
	},
	{
		"id": "5935",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92172
	},
	{
		"id": "5936",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92173
	},
	{
		"id": "5937",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92174
	},
	{
		"id": "5938",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92175
	},
	{
		"id": "5939",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92176
	},
	{
		"id": "5940",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92177
	},
	{
		"id": "5941",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92178
	},
	{
		"id": "5942",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92179
	},
	{
		"id": "5943",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92180
	},
	{
		"id": "5944",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92181
	},
	{
		"id": "5945",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92182
	},
	{
		"id": "5946",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92183
	},
	{
		"id": "5947",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92184
	},
	{
		"id": "5948",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92185
	},
	{
		"id": "5949",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92186
	},
	{
		"id": "5950",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92187
	},
	{
		"id": "5951",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92188
	},
	{
		"id": "5952",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92189
	},
	{
		"id": "5953",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92190
	},
	{
		"id": "5954",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92191
	},
	{
		"id": "5955",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92192
	},
	{
		"id": "5956",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92193
	},
	{
		"id": "5957",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92194
	},
	{
		"id": "5958",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92195
	},
	{
		"id": "5959",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92196
	},
	{
		"id": "5960",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92197
	},
	{
		"id": "5961",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92198
	},
	{
		"id": "5962",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92199
	},
	{
		"id": "5963",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92200
	},
	{
		"id": "5964",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92201
	},
	{
		"id": "5965",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92202
	},
	{
		"id": "5966",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92203
	},
	{
		"id": "5967",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92204
	},
	{
		"id": "5968",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92205
	},
	{
		"id": "5969",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92206
	},
	{
		"id": "5970",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92207
	},
	{
		"id": "5971",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92208
	},
	{
		"id": "5972",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92209
	},
	{
		"id": "5973",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92210
	},
	{
		"id": "5974",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92211
	},
	{
		"id": "5975",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92212
	},
	{
		"id": "5976",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92213
	},
	{
		"id": "5977",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92214
	},
	{
		"id": "5978",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92215
	},
	{
		"id": "5979",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92216
	},
	{
		"id": "5980",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92217
	},
	{
		"id": "5981",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92218
	},
	{
		"id": "5982",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92219
	},
	{
		"id": "5983",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92220
	},
	{
		"id": "5984",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92221
	},
	{
		"id": "5985",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92222
	},
	{
		"id": "5986",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92223
	},
	{
		"id": "5987",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92224
	},
	{
		"id": "5988",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92225
	},
	{
		"id": "5989",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92226
	},
	{
		"id": "5990",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92227
	},
	{
		"id": "5991",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92228
	},
	{
		"id": "5992",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92229
	},
	{
		"id": "5993",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92230
	},
	{
		"id": "5994",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92231
	},
	{
		"id": "5995",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92232
	},
	{
		"id": "5996",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92233
	},
	{
		"id": "5997",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92234
	},
	{
		"id": "5998",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92235
	},
	{
		"id": "5999",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92236
	},
	{
		"id": "6000",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92237
	},
	{
		"id": "6001",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92238
	},
	{
		"id": "6002",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92239
	},
	{
		"id": "6003",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92240
	},
	{
		"id": "6004",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92241
	},
	{
		"id": "6005",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92242
	},
	{
		"id": "6006",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92243
	},
	{
		"id": "6007",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92244
	},
	{
		"id": "6008",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92245
	},
	{
		"id": "6009",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92246
	},
	{
		"id": "6010",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92247
	},
	{
		"id": "6011",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92248
	},
	{
		"id": "6012",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92249
	},
	{
		"id": "6013",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92250
	},
	{
		"id": "6014",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92251
	},
	{
		"id": "6015",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92252
	},
	{
		"id": "6016",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92253
	},
	{
		"id": "6017",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92254
	},
	{
		"id": "6018",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92255
	},
	{
		"id": "6019",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92256
	},
	{
		"id": "6020",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92257
	},
	{
		"id": "6021",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92258
	},
	{
		"id": "6022",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92259
	},
	{
		"id": "6023",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92260
	},
	{
		"id": "6024",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92261
	},
	{
		"id": "6025",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92262
	},
	{
		"id": "6026",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92263
	},
	{
		"id": "6027",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92264
	},
	{
		"id": "6028",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92265
	},
	{
		"id": "6029",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92266
	},
	{
		"id": "6030",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92267
	},
	{
		"id": "6031",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92268
	},
	{
		"id": "6032",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92269
	},
	{
		"id": "6033",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92270
	},
	{
		"id": "6034",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92271
	},
	{
		"id": "6035",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92272
	},
	{
		"id": "6036",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92273
	},
	{
		"id": "6037",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92274
	},
	{
		"id": "6038",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92275
	},
	{
		"id": "6039",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92276
	},
	{
		"id": "6040",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92277
	},
	{
		"id": "6041",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92278
	},
	{
		"id": "6042",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92279
	},
	{
		"id": "6043",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92280
	},
	{
		"id": "6044",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92281
	},
	{
		"id": "6045",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92282
	},
	{
		"id": "6046",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92283
	},
	{
		"id": "6047",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92284
	},
	{
		"id": "6048",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92285
	},
	{
		"id": "6049",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92286
	},
	{
		"id": "6050",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92287
	},
	{
		"id": "6051",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92288
	},
	{
		"id": "6052",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92289
	},
	{
		"id": "6053",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92290
	},
	{
		"id": "6054",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92291
	},
	{
		"id": "6055",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92292
	},
	{
		"id": "6056",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92293
	},
	{
		"id": "6057",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92294
	},
	{
		"id": "6058",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92295
	},
	{
		"id": "6059",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92296
	},
	{
		"id": "6060",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92297
	},
	{
		"id": "6061",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92298
	},
	{
		"id": "6062",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92299
	},
	{
		"id": "6063",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92300
	},
	{
		"id": "6064",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92301
	},
	{
		"id": "6065",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92302
	},
	{
		"id": "6066",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92303
	},
	{
		"id": "6067",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92304
	},
	{
		"id": "6068",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92305
	},
	{
		"id": "6069",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92306
	},
	{
		"id": "6070",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92307
	},
	{
		"id": "6071",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92308
	},
	{
		"id": "6072",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92309
	},
	{
		"id": "6073",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92310
	},
	{
		"id": "6074",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92311
	},
	{
		"id": "6075",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92312
	},
	{
		"id": "6076",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92313
	},
	{
		"id": "6077",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92314
	},
	{
		"id": "6078",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92315
	},
	{
		"id": "6079",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92316
	},
	{
		"id": "6080",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92317
	},
	{
		"id": "6081",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92318
	},
	{
		"id": "6082",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92319
	},
	{
		"id": "6083",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92320
	},
	{
		"id": "6084",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92321
	},
	{
		"id": "6085",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92322
	},
	{
		"id": "6086",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92323
	},
	{
		"id": "6087",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92324
	},
	{
		"id": "6088",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92325
	},
	{
		"id": "6089",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92326
	},
	{
		"id": "6090",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92327
	},
	{
		"id": "6091",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92328
	},
	{
		"id": "6092",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92329
	},
	{
		"id": "6093",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92330
	},
	{
		"id": "6094",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92331
	},
	{
		"id": "6095",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92332
	},
	{
		"id": "6096",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92333
	},
	{
		"id": "6097",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92334
	},
	{
		"id": "6098",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92335
	},
	{
		"id": "6099",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92336
	},
	{
		"id": "6100",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92337
	},
	{
		"id": "6101",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92338
	},
	{
		"id": "6102",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92339
	},
	{
		"id": "6103",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92340
	},
	{
		"id": "6104",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92341
	},
	{
		"id": "6105",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92342
	},
	{
		"id": "6106",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92343
	},
	{
		"id": "6107",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92344
	},
	{
		"id": "6108",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92345
	},
	{
		"id": "6109",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92346
	},
	{
		"id": "6110",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92347
	},
	{
		"id": "6111",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92348
	},
	{
		"id": "6112",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92349
	},
	{
		"id": "6113",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92350
	},
	{
		"id": "6114",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92351
	},
	{
		"id": "6115",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92352
	},
	{
		"id": "6116",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92353
	},
	{
		"id": "6117",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92354
	},
	{
		"id": "6118",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92355
	},
	{
		"id": "6119",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92356
	},
	{
		"id": "6120",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92357
	},
	{
		"id": "6121",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92358
	},
	{
		"id": "6122",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92359
	},
	{
		"id": "6123",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92360
	},
	{
		"id": "6124",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92361
	},
	{
		"id": "6125",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92362
	},
	{
		"id": "6126",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92363
	},
	{
		"id": "6127",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92364
	},
	{
		"id": "6128",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92365
	},
	{
		"id": "6129",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92366
	},
	{
		"id": "6130",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92367
	},
	{
		"id": "6131",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92368
	},
	{
		"id": "6132",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92369
	},
	{
		"id": "6133",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92370
	},
	{
		"id": "6134",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92371
	},
	{
		"id": "6135",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92372
	},
	{
		"id": "6136",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92373
	},
	{
		"id": "6137",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92374
	},
	{
		"id": "6138",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92375
	},
	{
		"id": "6139",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92376
	},
	{
		"id": "6140",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92377
	},
	{
		"id": "6141",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92378
	},
	{
		"id": "6142",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92379
	},
	{
		"id": "6143",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92380
	},
	{
		"id": "6144",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92381
	},
	{
		"id": "6145",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92382
	},
	{
		"id": "6146",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92383
	},
	{
		"id": "6147",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92384
	},
	{
		"id": "6148",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92385
	},
	{
		"id": "6149",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92386
	},
	{
		"id": "6150",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92387
	},
	{
		"id": "6151",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92388
	},
	{
		"id": "6152",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92389
	},
	{
		"id": "6153",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92390
	},
	{
		"id": "6154",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92391
	},
	{
		"id": "6155",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92392
	},
	{
		"id": "6156",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92393
	},
	{
		"id": "6157",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92394
	},
	{
		"id": "6158",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92395
	},
	{
		"id": "6159",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92396
	},
	{
		"id": "6160",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92397
	},
	{
		"id": "6161",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92398
	},
	{
		"id": "6162",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92399
	},
	{
		"id": "6163",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92400
	},
	{
		"id": "6164",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92401
	},
	{
		"id": "6165",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92402
	},
	{
		"id": "6166",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92403
	},
	{
		"id": "6167",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92404
	},
	{
		"id": "6168",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92405
	},
	{
		"id": "6169",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92406
	},
	{
		"id": "6170",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92407
	},
	{
		"id": "6171",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92408
	},
	{
		"id": "6172",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92409
	},
	{
		"id": "6173",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92410
	},
	{
		"id": "6174",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92411
	},
	{
		"id": "6175",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92412
	},
	{
		"id": "6176",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92413
	},
	{
		"id": "6177",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92414
	},
	{
		"id": "6178",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92415
	},
	{
		"id": "6179",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92416
	},
	{
		"id": "6180",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92417
	},
	{
		"id": "6181",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92418
	},
	{
		"id": "6182",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92419
	},
	{
		"id": "6183",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92420
	},
	{
		"id": "6184",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92421
	},
	{
		"id": "6185",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92422
	},
	{
		"id": "6186",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92423
	},
	{
		"id": "6187",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92424
	},
	{
		"id": "6188",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92425
	},
	{
		"id": "6189",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92426
	},
	{
		"id": "6190",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92427
	},
	{
		"id": "6191",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92428
	},
	{
		"id": "6192",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92429
	},
	{
		"id": "6193",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92430
	},
	{
		"id": "6194",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92431
	},
	{
		"id": "6195",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92432
	},
	{
		"id": "6196",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92433
	},
	{
		"id": "6197",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92434
	},
	{
		"id": "6198",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92435
	},
	{
		"id": "6199",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92436
	},
	{
		"id": "6200",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92437
	},
	{
		"id": "6201",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92438
	},
	{
		"id": "6202",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92439
	},
	{
		"id": "6203",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92440
	},
	{
		"id": "6204",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92441
	},
	{
		"id": "6205",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92442
	},
	{
		"id": "6206",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92443
	},
	{
		"id": "6207",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92444
	},
	{
		"id": "6208",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92445
	},
	{
		"id": "6209",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92446
	},
	{
		"id": "6210",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92447
	},
	{
		"id": "6211",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92448
	},
	{
		"id": "6212",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92449
	},
	{
		"id": "6213",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92450
	},
	{
		"id": "6214",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92451
	},
	{
		"id": "6215",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92452
	},
	{
		"id": "6216",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92453
	},
	{
		"id": "6217",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92454
	},
	{
		"id": "6218",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92455
	},
	{
		"id": "6219",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92456
	},
	{
		"id": "6220",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92457
	},
	{
		"id": "6221",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92458
	},
	{
		"id": "6222",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92459
	},
	{
		"id": "6223",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92460
	},
	{
		"id": "6224",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92461
	},
	{
		"id": "6225",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92462
	},
	{
		"id": "6226",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92463
	},
	{
		"id": "6227",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92464
	},
	{
		"id": "6228",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92465
	},
	{
		"id": "6229",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92466
	},
	{
		"id": "6230",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92467
	},
	{
		"id": "6231",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92468
	},
	{
		"id": "6232",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92469
	},
	{
		"id": "6233",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92470
	},
	{
		"id": "6234",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92471
	},
	{
		"id": "6235",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92472
	},
	{
		"id": "6236",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92473
	},
	{
		"id": "6237",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92474
	},
	{
		"id": "6238",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92475
	},
	{
		"id": "6239",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92476
	},
	{
		"id": "6240",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92477
	},
	{
		"id": "6241",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92478
	},
	{
		"id": "6242",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92479
	},
	{
		"id": "6243",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92480
	},
	{
		"id": "6244",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92481
	},
	{
		"id": "6245",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92482
	},
	{
		"id": "6246",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92483
	},
	{
		"id": "6247",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92484
	},
	{
		"id": "6248",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92485
	},
	{
		"id": "6249",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92486
	},
	{
		"id": "6250",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92487
	},
	{
		"id": "6251",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92488
	},
	{
		"id": "6252",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92489
	},
	{
		"id": "6253",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92490
	},
	{
		"id": "6254",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92491
	},
	{
		"id": "6255",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92492
	},
	{
		"id": "6256",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92493
	},
	{
		"id": "6257",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92494
	},
	{
		"id": "6258",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92495
	},
	{
		"id": "6259",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92496
	},
	{
		"id": "6260",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92497
	},
	{
		"id": "6261",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92498
	},
	{
		"id": "6262",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92499
	},
	{
		"id": "6263",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92500
	},
	{
		"id": "6264",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92501
	},
	{
		"id": "6265",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92502
	},
	{
		"id": "6266",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92503
	},
	{
		"id": "6267",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92504
	},
	{
		"id": "6268",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92505
	},
	{
		"id": "6269",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92506
	},
	{
		"id": "6270",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92507
	},
	{
		"id": "6271",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92508
	},
	{
		"id": "6272",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92509
	},
	{
		"id": "6273",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92510
	},
	{
		"id": "6274",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92511
	},
	{
		"id": "6275",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92512
	},
	{
		"id": "6276",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92513
	},
	{
		"id": "6277",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92514
	},
	{
		"id": "6278",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92515
	},
	{
		"id": "6279",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92516
	},
	{
		"id": "6280",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92517
	},
	{
		"id": "6281",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92518
	},
	{
		"id": "6282",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92519
	},
	{
		"id": "6283",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92520
	},
	{
		"id": "6284",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92521
	},
	{
		"id": "6285",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92522
	},
	{
		"id": "6286",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92523
	},
	{
		"id": "6287",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92524
	},
	{
		"id": "6288",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92525
	},
	{
		"id": "6289",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92526
	},
	{
		"id": "6290",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92527
	},
	{
		"id": "6291",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92528
	},
	{
		"id": "6292",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92529
	},
	{
		"id": "6293",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92530
	},
	{
		"id": "6294",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92531
	},
	{
		"id": "6295",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92532
	},
	{
		"id": "6296",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92533
	},
	{
		"id": "6297",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92534
	},
	{
		"id": "6298",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92535
	},
	{
		"id": "6299",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92536
	},
	{
		"id": "6300",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92537
	},
	{
		"id": "6301",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92538
	},
	{
		"id": "6302",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92539
	},
	{
		"id": "6303",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92540
	},
	{
		"id": "6304",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92541
	},
	{
		"id": "6305",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92542
	},
	{
		"id": "6306",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92543
	},
	{
		"id": "6307",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92544
	},
	{
		"id": "6308",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92545
	},
	{
		"id": "6309",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92546
	},
	{
		"id": "6310",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92547
	},
	{
		"id": "6311",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92548
	},
	{
		"id": "6312",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92549
	},
	{
		"id": "6313",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92550
	},
	{
		"id": "6314",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92551
	},
	{
		"id": "6315",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92552
	},
	{
		"id": "6316",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92553
	},
	{
		"id": "6317",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92554
	},
	{
		"id": "6318",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92555
	},
	{
		"id": "6319",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92556
	},
	{
		"id": "6320",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92557
	},
	{
		"id": "6321",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92558
	},
	{
		"id": "6322",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92559
	},
	{
		"id": "6323",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92560
	},
	{
		"id": "6324",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92561
	},
	{
		"id": "6325",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92562
	},
	{
		"id": "6326",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92563
	},
	{
		"id": "6327",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92564
	},
	{
		"id": "6328",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92565
	},
	{
		"id": "6329",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92566
	},
	{
		"id": "6330",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92567
	},
	{
		"id": "6331",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92568
	},
	{
		"id": "6332",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92569
	},
	{
		"id": "6333",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92570
	},
	{
		"id": "6334",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92571
	},
	{
		"id": "6335",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92572
	},
	{
		"id": "6336",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92573
	},
	{
		"id": "6337",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92574
	},
	{
		"id": "6338",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92575
	},
	{
		"id": "6339",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92576
	},
	{
		"id": "6340",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92577
	},
	{
		"id": "6341",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92578
	},
	{
		"id": "6342",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92579
	},
	{
		"id": "6343",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92580
	},
	{
		"id": "6344",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92581
	},
	{
		"id": "6345",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92582
	},
	{
		"id": "6346",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92583
	},
	{
		"id": "6347",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92584
	},
	{
		"id": "6348",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92585
	},
	{
		"id": "6349",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92586
	},
	{
		"id": "6350",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92587
	},
	{
		"id": "6351",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92588
	},
	{
		"id": "6352",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92589
	},
	{
		"id": "6353",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92590
	},
	{
		"id": "6354",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92591
	},
	{
		"id": "6355",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92592
	},
	{
		"id": "6356",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92593
	},
	{
		"id": "6357",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92594
	},
	{
		"id": "6358",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92595
	},
	{
		"id": "6359",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92596
	},
	{
		"id": "6360",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92597
	},
	{
		"id": "6361",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92598
	},
	{
		"id": "6362",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92599
	},
	{
		"id": "6363",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92600
	},
	{
		"id": "6364",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92601
	},
	{
		"id": "6365",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92602
	},
	{
		"id": "6366",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92603
	},
	{
		"id": "6367",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92604
	},
	{
		"id": "6368",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92605
	},
	{
		"id": "6369",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92606
	},
	{
		"id": "6370",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92607
	},
	{
		"id": "6371",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92608
	},
	{
		"id": "6372",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92609
	},
	{
		"id": "6373",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92610
	},
	{
		"id": "6374",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92611
	},
	{
		"id": "6375",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92612
	},
	{
		"id": "6376",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92613
	},
	{
		"id": "6377",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92614
	},
	{
		"id": "6378",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92615
	},
	{
		"id": "6379",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92616
	},
	{
		"id": "6380",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92617
	},
	{
		"id": "6381",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92618
	},
	{
		"id": "6382",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92619
	},
	{
		"id": "6383",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92620
	},
	{
		"id": "6384",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92621
	},
	{
		"id": "6385",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92622
	},
	{
		"id": "6386",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92623
	},
	{
		"id": "6387",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92624
	},
	{
		"id": "6388",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92625
	},
	{
		"id": "6389",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92626
	},
	{
		"id": "6390",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92627
	},
	{
		"id": "6391",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92628
	},
	{
		"id": "6392",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92629
	},
	{
		"id": "6393",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92630
	},
	{
		"id": "6394",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92631
	},
	{
		"id": "6395",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92632
	},
	{
		"id": "6396",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92633
	},
	{
		"id": "6397",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92634
	},
	{
		"id": "6398",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92635
	},
	{
		"id": "6399",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92636
	},
	{
		"id": "6400",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92637
	},
	{
		"id": "6401",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92638
	},
	{
		"id": "6402",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92639
	},
	{
		"id": "6403",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92640
	},
	{
		"id": "6404",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92641
	},
	{
		"id": "6405",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92642
	},
	{
		"id": "6406",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92643
	},
	{
		"id": "6407",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92644
	},
	{
		"id": "6408",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92645
	},
	{
		"id": "6409",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92646
	},
	{
		"id": "6410",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92647
	},
	{
		"id": "6411",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92648
	},
	{
		"id": "6412",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92649
	},
	{
		"id": "6413",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92650
	},
	{
		"id": "6414",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92651
	},
	{
		"id": "6415",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92652
	},
	{
		"id": "6416",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92653
	},
	{
		"id": "6417",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92654
	},
	{
		"id": "6418",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92655
	},
	{
		"id": "6419",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92656
	},
	{
		"id": "6420",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92657
	},
	{
		"id": "6421",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92658
	},
	{
		"id": "6422",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92659
	},
	{
		"id": "6423",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92660
	},
	{
		"id": "6424",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92661
	},
	{
		"id": "6425",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92662
	},
	{
		"id": "6426",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92663
	},
	{
		"id": "6427",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92664
	},
	{
		"id": "6428",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92665
	},
	{
		"id": "6429",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92666
	},
	{
		"id": "6430",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92667
	},
	{
		"id": "6431",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92668
	},
	{
		"id": "6432",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92669
	},
	{
		"id": "6433",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92670
	},
	{
		"id": "6434",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92671
	},
	{
		"id": "6435",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92672
	},
	{
		"id": "6436",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92673
	},
	{
		"id": "6437",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92674
	},
	{
		"id": "6438",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92675
	},
	{
		"id": "6439",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92676
	},
	{
		"id": "6440",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92677
	},
	{
		"id": "6441",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92678
	},
	{
		"id": "6442",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92679
	},
	{
		"id": "6443",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92680
	},
	{
		"id": "6444",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92681
	},
	{
		"id": "6445",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92682
	},
	{
		"id": "6446",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92683
	},
	{
		"id": "6447",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92684
	},
	{
		"id": "6448",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92685
	},
	{
		"id": "6449",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92686
	},
	{
		"id": "6450",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92687
	},
	{
		"id": "6451",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92688
	},
	{
		"id": "6452",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92689
	},
	{
		"id": "6453",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92690
	},
	{
		"id": "6454",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92691
	},
	{
		"id": "6455",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92692
	},
	{
		"id": "6456",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92693
	},
	{
		"id": "6457",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92694
	},
	{
		"id": "6458",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92695
	},
	{
		"id": "6459",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92696
	},
	{
		"id": "6460",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92697
	},
	{
		"id": "6461",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92698
	},
	{
		"id": "6462",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92699
	},
	{
		"id": "6463",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92700
	},
	{
		"id": "6464",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92701
	},
	{
		"id": "6465",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92702
	},
	{
		"id": "6466",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92703
	},
	{
		"id": "6467",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92704
	},
	{
		"id": "6468",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92705
	},
	{
		"id": "6469",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92706
	},
	{
		"id": "6470",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92707
	},
	{
		"id": "6471",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92708
	},
	{
		"id": "6472",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92709
	},
	{
		"id": "6473",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92710
	},
	{
		"id": "6474",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92711
	},
	{
		"id": "6475",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92712
	},
	{
		"id": "6476",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92713
	},
	{
		"id": "6477",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92714
	},
	{
		"id": "6478",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92715
	},
	{
		"id": "6479",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92716
	},
	{
		"id": "6480",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92717
	},
	{
		"id": "6481",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92718
	},
	{
		"id": "6482",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92719
	},
	{
		"id": "6483",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92720
	},
	{
		"id": "6484",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92721
	},
	{
		"id": "6485",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92722
	},
	{
		"id": "6486",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92723
	},
	{
		"id": "6487",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92724
	},
	{
		"id": "6488",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92725
	},
	{
		"id": "6489",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92726
	},
	{
		"id": "6490",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92727
	},
	{
		"id": "6491",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92728
	},
	{
		"id": "6492",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92729
	},
	{
		"id": "6493",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92730
	},
	{
		"id": "6494",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92731
	},
	{
		"id": "6495",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92732
	},
	{
		"id": "6496",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92733
	},
	{
		"id": "6497",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92734
	},
	{
		"id": "6498",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92735
	},
	{
		"id": "6499",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92736
	},
	{
		"id": "6500",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92737
	},
	{
		"id": "6501",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92738
	},
	{
		"id": "6502",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92739
	},
	{
		"id": "6503",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92740
	},
	{
		"id": "6504",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92741
	},
	{
		"id": "6505",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92742
	},
	{
		"id": "6506",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92743
	},
	{
		"id": "6507",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92744
	},
	{
		"id": "6508",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92745
	},
	{
		"id": "6509",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92746
	},
	{
		"id": "6510",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92747
	},
	{
		"id": "6511",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92748
	},
	{
		"id": "6512",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92749
	},
	{
		"id": "6513",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92750
	},
	{
		"id": "6514",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92751
	},
	{
		"id": "6515",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92752
	},
	{
		"id": "6516",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92753
	},
	{
		"id": "6517",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92754
	},
	{
		"id": "6518",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92755
	},
	{
		"id": "6519",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92756
	},
	{
		"id": "6520",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92757
	},
	{
		"id": "6521",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92758
	},
	{
		"id": "6522",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92759
	},
	{
		"id": "6523",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92760
	},
	{
		"id": "6524",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92761
	},
	{
		"id": "6525",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92762
	},
	{
		"id": "6526",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92763
	},
	{
		"id": "6527",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92764
	},
	{
		"id": "6528",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92765
	},
	{
		"id": "6529",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92766
	},
	{
		"id": "6530",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92767
	},
	{
		"id": "6531",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92768
	},
	{
		"id": "6532",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92769
	},
	{
		"id": "6533",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92770
	},
	{
		"id": "6534",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92771
	},
	{
		"id": "6535",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92772
	},
	{
		"id": "6536",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92773
	},
	{
		"id": "6537",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92774
	},
	{
		"id": "6538",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92775
	},
	{
		"id": "6539",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92776
	},
	{
		"id": "6540",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92777
	},
	{
		"id": "6541",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92778
	},
	{
		"id": "6542",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92779
	},
	{
		"id": "6543",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92780
	},
	{
		"id": "6544",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92781
	},
	{
		"id": "6545",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92782
	},
	{
		"id": "6546",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92783
	},
	{
		"id": "6547",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92784
	},
	{
		"id": "6548",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92785
	},
	{
		"id": "6549",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92786
	},
	{
		"id": "6550",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92787
	},
	{
		"id": "6551",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92788
	},
	{
		"id": "6552",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92789
	},
	{
		"id": "6553",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92790
	},
	{
		"id": "6554",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92791
	},
	{
		"id": "6555",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92792
	},
	{
		"id": "6556",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92793
	},
	{
		"id": "6557",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92794
	},
	{
		"id": "6558",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92795
	},
	{
		"id": "6559",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92796
	},
	{
		"id": "6560",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92797
	},
	{
		"id": "6561",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92798
	},
	{
		"id": "6562",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92799
	},
	{
		"id": "6563",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92800
	},
	{
		"id": "6564",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92801
	},
	{
		"id": "6565",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92802
	},
	{
		"id": "6566",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92803
	},
	{
		"id": "6567",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92804
	},
	{
		"id": "6568",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92805
	},
	{
		"id": "6569",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92806
	},
	{
		"id": "6570",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92807
	},
	{
		"id": "6571",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92808
	},
	{
		"id": "6572",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92809
	},
	{
		"id": "6573",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92810
	},
	{
		"id": "6574",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92811
	},
	{
		"id": "6575",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92812
	},
	{
		"id": "6576",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92813
	},
	{
		"id": "6577",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92814
	},
	{
		"id": "6578",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92815
	},
	{
		"id": "6579",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92816
	},
	{
		"id": "6580",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92817
	},
	{
		"id": "6581",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92818
	},
	{
		"id": "6582",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92819
	},
	{
		"id": "6583",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92820
	},
	{
		"id": "6584",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92821
	},
	{
		"id": "6585",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92822
	},
	{
		"id": "6586",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92823
	},
	{
		"id": "6587",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92824
	},
	{
		"id": "6588",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92825
	},
	{
		"id": "6589",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92826
	},
	{
		"id": "6590",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92827
	},
	{
		"id": "6591",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92828
	},
	{
		"id": "6592",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92829
	},
	{
		"id": "6593",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92830
	},
	{
		"id": "6594",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92831
	},
	{
		"id": "6595",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92832
	},
	{
		"id": "6596",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92833
	},
	{
		"id": "6597",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92834
	},
	{
		"id": "6598",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92835
	},
	{
		"id": "6599",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92836
	},
	{
		"id": "6600",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92837
	},
	{
		"id": "6601",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92838
	},
	{
		"id": "6602",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92839
	},
	{
		"id": "6603",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92840
	},
	{
		"id": "6604",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92841
	},
	{
		"id": "6605",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92842
	},
	{
		"id": "6606",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92843
	},
	{
		"id": "6607",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92844
	},
	{
		"id": "6608",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92845
	},
	{
		"id": "6609",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92846
	},
	{
		"id": "6610",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92847
	},
	{
		"id": "6611",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92848
	},
	{
		"id": "6612",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92849
	},
	{
		"id": "6613",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92850
	},
	{
		"id": "6614",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92851
	},
	{
		"id": "6615",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92852
	},
	{
		"id": "6616",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92853
	},
	{
		"id": "6617",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92854
	},
	{
		"id": "6618",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92855
	},
	{
		"id": "6619",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92856
	},
	{
		"id": "6620",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92857
	},
	{
		"id": "6621",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92858
	},
	{
		"id": "6622",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92859
	},
	{
		"id": "6623",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92860
	},
	{
		"id": "6624",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92861
	},
	{
		"id": "6625",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92862
	},
	{
		"id": "6626",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92863
	},
	{
		"id": "6627",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92864
	},
	{
		"id": "6628",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92865
	},
	{
		"id": "6629",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92866
	},
	{
		"id": "6630",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92867
	},
	{
		"id": "6631",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92868
	},
	{
		"id": "6632",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92869
	},
	{
		"id": "6633",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92870
	},
	{
		"id": "6634",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92871
	},
	{
		"id": "6635",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92872
	},
	{
		"id": "6636",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92873
	},
	{
		"id": "6637",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92874
	},
	{
		"id": "6638",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92875
	},
	{
		"id": "6639",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92876
	},
	{
		"id": "6640",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92877
	},
	{
		"id": "6641",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92878
	},
	{
		"id": "6642",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92879
	},
	{
		"id": "6643",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92880
	},
	{
		"id": "6644",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92881
	},
	{
		"id": "6645",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92882
	},
	{
		"id": "6646",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92883
	},
	{
		"id": "6647",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92884
	},
	{
		"id": "6648",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92885
	},
	{
		"id": "6649",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92886
	},
	{
		"id": "6650",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92887
	},
	{
		"id": "6651",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92888
	},
	{
		"id": "6652",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92889
	},
	{
		"id": "6653",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92890
	},
	{
		"id": "6654",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92891
	},
	{
		"id": "6655",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92892
	},
	{
		"id": "6656",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92893
	},
	{
		"id": "6657",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92894
	},
	{
		"id": "6658",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92895
	},
	{
		"id": "6659",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92896
	},
	{
		"id": "6660",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92897
	},
	{
		"id": "6661",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92898
	},
	{
		"id": "6662",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92899
	},
	{
		"id": "6663",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92900
	},
	{
		"id": "6664",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92901
	},
	{
		"id": "6665",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92902
	},
	{
		"id": "6666",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92903
	},
	{
		"id": "6667",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92904
	},
	{
		"id": "6668",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92905
	},
	{
		"id": "6669",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92906
	},
	{
		"id": "6670",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92907
	},
	{
		"id": "6671",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92908
	},
	{
		"id": "6672",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92909
	},
	{
		"id": "6673",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92910
	},
	{
		"id": "6674",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92911
	},
	{
		"id": "6675",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92912
	},
	{
		"id": "6676",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92913
	},
	{
		"id": "6677",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92914
	},
	{
		"id": "6678",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92915
	},
	{
		"id": "6679",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92916
	},
	{
		"id": "6680",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92917
	},
	{
		"id": "6681",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92918
	},
	{
		"id": "6682",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92919
	},
	{
		"id": "6683",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92920
	},
	{
		"id": "6684",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92921
	},
	{
		"id": "6685",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92922
	},
	{
		"id": "6686",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92923
	},
	{
		"id": "6687",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92924
	},
	{
		"id": "6688",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92925
	},
	{
		"id": "6689",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92926
	},
	{
		"id": "6690",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92927
	},
	{
		"id": "6691",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92928
	},
	{
		"id": "6692",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92929
	},
	{
		"id": "6693",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92930
	},
	{
		"id": "6694",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92931
	},
	{
		"id": "6695",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92932
	},
	{
		"id": "6696",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92933
	},
	{
		"id": "6697",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92934
	},
	{
		"id": "6698",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92935
	},
	{
		"id": "6699",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92936
	},
	{
		"id": "6700",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92937
	},
	{
		"id": "6701",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92938
	},
	{
		"id": "6702",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92939
	},
	{
		"id": "6703",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92940
	},
	{
		"id": "6704",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92941
	},
	{
		"id": "6705",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92942
	},
	{
		"id": "6706",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92943
	},
	{
		"id": "6707",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92944
	},
	{
		"id": "6708",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92945
	},
	{
		"id": "6709",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92946
	},
	{
		"id": "6710",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92947
	},
	{
		"id": "6711",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92948
	},
	{
		"id": "6712",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92949
	},
	{
		"id": "6713",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92950
	},
	{
		"id": "6714",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92951
	},
	{
		"id": "6715",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92952
	},
	{
		"id": "6716",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92953
	},
	{
		"id": "6717",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92954
	},
	{
		"id": "6718",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92955
	},
	{
		"id": "6719",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92956
	},
	{
		"id": "6720",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92957
	},
	{
		"id": "6721",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92958
	},
	{
		"id": "6722",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92959
	},
	{
		"id": "6723",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92960
	},
	{
		"id": "6724",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92961
	},
	{
		"id": "6725",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92962
	},
	{
		"id": "6726",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92963
	},
	{
		"id": "6727",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92964
	},
	{
		"id": "6728",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92965
	},
	{
		"id": "6729",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92966
	},
	{
		"id": "6730",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92967
	},
	{
		"id": "6731",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92968
	},
	{
		"id": "6732",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92969
	},
	{
		"id": "6733",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92970
	},
	{
		"id": "6734",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92971
	},
	{
		"id": "6735",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92972
	},
	{
		"id": "6736",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92973
	},
	{
		"id": "6737",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92974
	},
	{
		"id": "6738",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92975
	},
	{
		"id": "6739",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92976
	},
	{
		"id": "6740",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92977
	},
	{
		"id": "6741",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92978
	},
	{
		"id": "6742",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92979
	},
	{
		"id": "6743",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92980
	},
	{
		"id": "6744",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92981
	},
	{
		"id": "6745",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92982
	},
	{
		"id": "6746",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92983
	},
	{
		"id": "6747",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92984
	},
	{
		"id": "6748",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92985
	},
	{
		"id": "6749",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92986
	},
	{
		"id": "6750",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92987
	},
	{
		"id": "6751",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92988
	},
	{
		"id": "6752",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92989
	},
	{
		"id": "6753",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92990
	},
	{
		"id": "6754",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92991
	},
	{
		"id": "6755",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92992
	},
	{
		"id": "6756",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92993
	},
	{
		"id": "6757",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92994
	},
	{
		"id": "6758",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92995
	},
	{
		"id": "6759",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92996
	},
	{
		"id": "6760",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92997
	},
	{
		"id": "6761",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92998
	},
	{
		"id": "6762",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 92999
	},
	{
		"id": "6763",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93000
	},
	{
		"id": "6764",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93001
	},
	{
		"id": "6765",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93002
	},
	{
		"id": "6766",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93003
	},
	{
		"id": "6767",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93004
	},
	{
		"id": "6768",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93005
	},
	{
		"id": "6769",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93006
	},
	{
		"id": "6770",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93007
	},
	{
		"id": "6771",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93008
	},
	{
		"id": "6772",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93009
	},
	{
		"id": "6773",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93010
	},
	{
		"id": "6774",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93011
	},
	{
		"id": "6775",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93012
	},
	{
		"id": "6776",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93013
	},
	{
		"id": "6777",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93014
	},
	{
		"id": "6778",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93015
	},
	{
		"id": "6779",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93016
	},
	{
		"id": "6780",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93017
	},
	{
		"id": "6781",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93018
	},
	{
		"id": "6782",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93019
	},
	{
		"id": "6783",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93020
	},
	{
		"id": "6784",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93021
	},
	{
		"id": "6785",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93022
	},
	{
		"id": "6786",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93023
	},
	{
		"id": "6787",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93024
	},
	{
		"id": "6788",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93025
	},
	{
		"id": "6789",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93026
	},
	{
		"id": "6790",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93027
	},
	{
		"id": "6791",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93028
	},
	{
		"id": "6792",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93029
	},
	{
		"id": "6793",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93030
	},
	{
		"id": "6794",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93031
	},
	{
		"id": "6795",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93032
	},
	{
		"id": "6796",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93033
	},
	{
		"id": "6797",
		"createTime": "17-05-19 6:14",
		"beginTime": "17-05-19 6:13",
		"expiryTime": "17-06-02 5:00",
		"permitDescription": "Flat Rate: $5",
		"groupId": "xi3duf9tny4ie12tu53x",
		"merchantCode": 8000005,
		"permitTag": 59458,
		"detailPermitTag": 93034
	}
];
