import {Component} from "@angular/core";

@Component({
	selector: "page-mm-movebe-markers",
	styleUrls: ["./movebe-markers.page.scss"],
	templateUrl: "./movebe-markers.page.html",
})
export class MovebeMarkersPage {

}
