//tslint:disable:max-classes-per-file
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ErrorHandler, NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {IonicModule} from "@ionic/angular";
import {IonicStorageModule} from "@ionic/storage";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {ElasticModule} from "ng-elastic";
import * as Raven from "raven-js";
import "rxjs-compat/add/observable/bindCallback";
import "rxjs-compat/add/observable/combineLatest";
import "rxjs-compat/add/observable/empty";
import "rxjs-compat/add/observable/forkJoin";
import "rxjs-compat/add/observable/from";
import "rxjs-compat/add/observable/fromEvent";
import "rxjs-compat/add/observable/fromPromise";
import "rxjs-compat/add/observable/interval";
import "rxjs-compat/add/observable/merge";
import "rxjs-compat/add/observable/of";
import "rxjs-compat/add/observable/timer";
import "rxjs-compat/add/operator/catch";
import "rxjs-compat/add/operator/combineLatest";
import "rxjs-compat/add/operator/concatAll";
import "rxjs-compat/add/operator/concatMap";
import "rxjs-compat/add/operator/delay";
import "rxjs-compat/add/operator/distinctUntilChanged";
import "rxjs-compat/add/operator/do";
import "rxjs-compat/add/operator/filter";
import "rxjs-compat/add/operator/first";
import "rxjs-compat/add/operator/last";
import "rxjs-compat/add/operator/mapTo";
import "rxjs-compat/add/operator/merge";
import "rxjs-compat/add/operator/mergeMap";
import "rxjs-compat/add/operator/pairwise";
import "rxjs-compat/add/operator/shareReplay";
import "rxjs-compat/add/operator/startWith";
import "rxjs-compat/add/operator/switchMap";
import "rxjs-compat/add/operator/take";
import "rxjs-compat/add/operator/takeUntil";
import "rxjs-compat/add/operator/withLatestFrom";
import "rxjs-compat/add/operator/zip";
import "rxjs/Rx";
import {AdminModule} from "../app-modes/admin/admin.module";
import {MovebeModule} from "../app-modes/consumer/movebe.module";
import {MovebeMerchantsModule} from "../app-modes/merchant/movebe-merchants.module";
import {MovebeCoreModule} from "../core/core.module";
import {MovebeApiInterceptor} from "../core/movebe-api/movebe-api.interceptor";
import {ChatModule} from "../lib/chat/chat.module";
import {MerchantMembershipModule} from "../lib/merchant-membership/merchant-membership.module";
import {UserModule} from "../lib/user/user.module";
import {MovebeSharedModule} from "../shared/movebe-shared.module";
import * as fromApp from "./+state/index";
import {appEnvironment} from "./app-environment";
import {AppInfoPage} from "./app-info/app-info.page";
import {AppComponent} from "./app.component";

Raven
	.config("https://ebec6d34f5104c279cfdb837783bec13@sentry.io/296180",
		{
			autoBreadcrumbs: {console: false}
		})
	.install();

Raven.setTagsContext({
	projectId: appEnvironment.firebaseConfig.projectId,
});
Raven.setExtraContext({
	apiUrl: appEnvironment.apiUrl,
	isProduction: appEnvironment.isProduction,
	projectId: appEnvironment.firebaseConfig.projectId,
});

export class RavenErrorHandler implements ErrorHandler {

	handleError(err: any): void {
		console.error(err);
		Raven.captureException(err);	//tslint:disable-line:no-unsafe-any
		alert("An unexpected error has occurred.  Please restart MoveBe");
	}
}

@NgModule({
	bootstrap: [AppComponent],
	declarations: [
		AppInfoPage,
		AppComponent,
	],
	entryComponents: [
		AppInfoPage,

	],
	exports: [],
	imports: [
		//These NgRx modules must be imported in this order and before any feature modules are imported
		// (see https://github.com/ngrx/platform/issues/624)
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25
		}),
		StoreModule.forFeature(fromApp.featureName, fromApp.reducer),
		EffectsModule.forFeature(fromApp.effects),
		//--------------
		AdminModule,
		BrowserAnimationsModule,
		BrowserModule,
		ChatModule,
		ElasticModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot(),
		MovebeCoreModule,
		MovebeMerchantsModule,
		MovebeModule,
		MovebeSharedModule,
		ReactiveFormsModule,
		UserModule,
		MerchantMembershipModule,

	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: RavenErrorHandler,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: MovebeApiInterceptor,
		},
	]
})

export class AppModule {
}
