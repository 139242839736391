import {Component, OnInit, ViewChild} from "@angular/core";
import {AlertController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import {filter, first, map, startWith, withLatestFrom} from "rxjs/operators";
import * as fromMerchant from "../+state";
import {Benefit, Coupon} from "../../../../../lib/model/benefit.model";
import {Employer} from "../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Offer} from "../../../../../lib/model/offer.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {OffersService} from "../../../core/offers/offers.service";
import {MerchantTargetedScanRequestParams} from "../../../core/scan-request/scan-request-params.model";
import {ScanRequest, ScanRequestAction, ScanRequestStatus} from "../../../core/scan-request/scan-request.model";
import {ScanRequestService} from "../../../core/scan-request/scan-request.service";
import {CurrentUserService} from "../../../core/user/current-user.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {ignoreNewNulls} from "../../../lib/rxjs-operators/ingore-new-nulls";
import {UserActions} from "../../../lib/user/+state/user.actions";
import {OfferScanRequestReviewComponent} from "./offer-scan-request-review.component";
import {RewardScanRequestReviewComponent} from "./reward-scan-request-review.component";
import {ScanRequestReviewComponent} from "./scan-request-review-component.model";

@Component({
	selector: "page-mm-scan-request",
	styleUrls: ["./scan-request-review.page.scss"],
	templateUrl: "./scan-request-review.page.html"
})
export class ScanRequestReviewPage implements OnInit {
	ScanRequestStatus = ScanRequestStatus;
	ScanRequestAction = ScanRequestAction;

	benefit: Benefit | Coupon;
	coupon: Coupon;
	location: MerchantLocation;
	merchant: Merchant;
	offer: Offer;
	readonly scanRequest$: Observable<ScanRequest | null>;
	readonly scanRequestId: string;
	private employers$: Observable<Employer[]>;
	private currentMerchant$: Observable<Merchant>;

	isSubmitDisabled = false;
	@ViewChild("validateOfferScanRequest") validateOfferScanRequest: OfferScanRequestReviewComponent;
	@ViewChild("redeemRewardScanRequest") redeemRewardScanRequest: RewardScanRequestReviewComponent;
	@ViewChild("joinMerchantScanRequest") joinMerchantScanRequest: RewardScanRequestReviewComponent;

	constructor(private alertCtrl: AlertController,
							private busyService: BusyService,
							private navParams: NavParams,
							private currentUserService: CurrentUserService,
							private scanRequestService: ScanRequestService,
							private offersService: OffersService,
							private merchantsService: MerchantsService,
							private store: Store<MovebeState>,
							private translate: TranslateService,
							private logger: Logger) {

		this.scanRequestId = this.navParams.get("scanRequestId") as string;
		this.scanRequest$ = this.scanRequestService
			.getScanRequest(this.scanRequestId)
			.pipe(
				ignoreNewNulls(),
				startWith(undefined),
			);
		this.employers$ = this.store.select(fromMerchant.getEmployers);
		this.currentMerchant$ = this.store.select(fromMerchant.getCurrentMerchant).pipe(filterNulls());

		this.scanRequest$.pipe(
			filterNulls(),
			filter(scanRequest => (scanRequest.action === ScanRequestAction.redeemReward || scanRequest.action === this.ScanRequestAction.validateOffer)),
			map(scanRequest => scanRequest.requestParams as MerchantTargetedScanRequestParams),
			withLatestFrom(this.currentMerchant$, this.employers$),
			first()
		).subscribe(([requestParams, currentMerchant, employers]) => {
			const requestedMerchantId = requestParams.merchantId;
			if (requestedMerchantId !== currentMerchant.$key!) {
				const neededMerchant = employers.find(employer => employer.merchant.key === requestedMerchantId);
				if (neededMerchant) {
					this.store.dispatch(new UserActions.SelectCurrentMerchant(neededMerchant));
					this.alertCtrl
						.create({
							buttons: [this.translate.instant("BUTTONS.OK") as string],
							header: this.translate.instant("SCAN_REQUEST.SWITCHING_MERCHANT") as string,
							subHeader: this.translate.instant("SCAN_REQUEST.SWITCHING_MERCHANT_TO", {merchantName: neededMerchant.merchant.name}) as string,
						})
						.then(alert => alert.present())
						.catch(error => this.logger.error(error));
				} else {
					throw new Error(`Cannot process validation request for merchant ${requestedMerchantId}`);
				}
			}
		});
	}

	ngOnInit() {
		this.scanRequest$
			.pipe(
				filterNulls(),
				filter(scanRequest => scanRequest.status === ScanRequestStatus.pending),
				first()
			)
			.subscribe(scanRequest => {
				this.scanRequestService
					.updateScanRequest(scanRequest.$key!, ScanRequestStatus.scanned)
					.catch(error => this.logger.error(error));
			});
	}

	reject() {
		const rejectPromise = this.scanRequestService
			.updateScanRequest(this.scanRequestId, ScanRequestStatus.rejected)
			.catch(error => this.logger.error(error));
		this.busyService.setBusy(rejectPromise);
	}

	approve(scanRequest: ScanRequest) {
		const validatePromise = this.getScanRequestReviewChildComponent(scanRequest.action)
			.approve()
			.then(responseData => {
				return this.scanRequestService
					.updateScanRequest(this.scanRequestId, ScanRequestStatus.approved, responseData);
			});
		this.busyService.setBusy(validatePromise, this.translate.instant(`SCAN_REQUEST.ACTION.${scanRequest.action.toUpperCase()}.PROCESSING_APPROVAL`));
	}

	isValidChanged(isValid: boolean) {
		this.isSubmitDisabled = !isValid;
	}

	getScanRequestReviewChildComponent(scanRequestAction: ScanRequestAction): ScanRequestReviewComponent {
		switch (scanRequestAction) {
			case ScanRequestAction.validateOffer:
				return this.validateOfferScanRequest;
			case ScanRequestAction.redeemReward:
				return this.redeemRewardScanRequest;
			case ScanRequestAction.joinMerchant:
				return this.joinMerchantScanRequest;
		}

	}

}
