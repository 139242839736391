import {I18nLookupTable} from "../i18n/i18n-lookup-table.model";

export type TimeUnitType = "minutes" | "hours" | "days" | "weeks" | undefined;

export interface TimeSlice {
	quantity?: number;
	unit: TimeUnitType;
}

//tslint:disable:object-literal-sort-keys
export const TimeUnitTypes: I18nLookupTable = {
	minutes: {en: "Minutes"},
	hours: {en: "Hours"},
	days: {en: "Days"},
	weeks: {en: "Weeks"}
};
