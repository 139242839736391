import {Component, OnInit} from "@angular/core";
import {Nav} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {Merchant, MerchantStatus} from "../../../../../lib/model/merchant.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {MerchantPage} from "../merchant/merchant.page";

@Component({
	selector: "page-admin-merchants",
	styleUrls: ["./merchants.page.scss"],
	templateUrl: "./merchants.page.html"
})
export class MerchantsPage implements OnInit {
	merchants$: Observable<Merchant[]>;
	readonly MerchantStatus = MerchantStatus;

	constructor(private logger: Logger,
							private merchantsService: MerchantsService,
							private nav: Nav) {
	}

	ngOnInit() {
		this.merchants$ = this.merchantsService.getMerchants();
	}

	/*tslint:disable:member-ordering*/
	activateMerchant(event: MouseEvent, merchant: Merchant) {
		event.stopPropagation();
		merchant.status = MerchantStatus.approved;
		this.merchantsService.updateMerchant(merchant);
	}

	disableMerchant(event: MouseEvent, merchant: Merchant) {
		event.stopPropagation();
		merchant.status = MerchantStatus.disabled;
		this.merchantsService.updateMerchant(merchant);
	}

	getStatusIcon(merchantStatus): string | undefined {
		return this.merchantsService.getStatusIcon(merchantStatus);
	}

	/*tslint:enable:member-ordering*/
	rejectMerchant(event: MouseEvent, merchant: Merchant) {
		event.stopPropagation();
		merchant.status = MerchantStatus.rejected;
		this.merchantsService.updateMerchant(merchant);
	}

	viewMerchant(event: MouseEvent, merchant: Merchant) {
		event.stopPropagation();
		this.nav.push(MerchantPage, {merchant}).catch(error => this.logger.error(error));
	}
}
