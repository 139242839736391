import {Component} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import * as currency from "currency.js";
import {Observable} from "rxjs/Observable";
import {Disbursement} from "../../../../../lib/model/disbursement.model";
import {GatewayAccount} from "../../../../../lib/model/gateway-account.model";
import {Gateway} from "../../../../../lib/model/gateway.model";
import {Provider} from "../../../../../lib/model/provider.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {GatewaysService} from "../../../core/gateways/gateways.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";
import {ProvidersService} from "../../../core/providers/providers.service";

@Component({
	templateUrl: "./disbursement.page.html"
})
export class DisbursementPage {
	balanceBooked = 0;
	blankDisbursement: Disbursement = {
		amount: 0,
		comment: "",
		currency: "",
		feeFlat: 0,
		feePercent: 0,
		gatewayId: "",
		providerId: "",
		total: 0,
		transactionId: ""
	};
	disbursement: Disbursement = JSON.parse(JSON.stringify(this.blankDisbursement)) as Disbursement;
	gatewayAccounts: GatewayAccount[] = [];
	gateways: Gateway[];
	readonly numberToPercentDivisor = 100;
	provider;
	readonly providers$: Observable<Provider[]>;

	constructor(private alertCtrl: AlertController,
							private busyService: BusyService,
							private gatewayService: GatewaysService,
							private movebeApiService: MovebeApiService,
							private providersService: ProvidersService,
							private store: Store<MovebeState>,
							private translate: TranslateService) {
		this.providers$ = this.providersService.getProviders();
		this.gatewayService.getGateways().first().subscribe(gateways => this.gateways = gateways);
	}

	cancelEdit() {
		this.disbursement = JSON.parse(JSON.stringify(this.blankDisbursement)) as Disbursement;
	}

	saveDisbursement() {
		const savePromise = this.movebeApiService.putDisbursement(this.disbursement)
			.then(() => {
				this.disbursement = JSON.parse(JSON.stringify({
					...this.blankDisbursement,
					currency: this.disbursement.currency,
					provider: this.disbursement.providerId
				})) as Disbursement;
				return this.alertCtrl.create({
					buttons: [this.translate.instant("BUTTONS.OK") as string],
					header: this.translate.instant("TRANSACTIONS.POSTED") as string,
					subHeader: this.translate.instant("TRANSACTIONS.YOUR_DISBURSEMENT_HAS_BEEN_SAVED") as string,
				});
			})
			.then(alert => alert.present())
			.catch(error => console.error(error));
		this.busyService.setBusy(savePromise, this.translate.instant("TRANSACTIONS.SAVING_DISBURSEMENT"));
	}

	updateProvider(providerId: string) {
		if (providerId) {
			this.providers$.first().subscribe((providers: Provider[]) => {
				const currentProvider = providers.find((provider: Provider) => provider.id === providerId);
				this.gatewayAccounts = (currentProvider ? currentProvider.gatewayAccounts : [])
					.map(gatewayAccount => {
						gatewayAccount.type = this.gateways.find(gateway => gateway.$key === gatewayAccount.gatewayId)!.type;
						return gatewayAccount;
					});
			});
			const getBalancePromise = this.movebeApiService.getStakeholderBalances(providerId)
			//TODO strongly type this response
				.then((response: { booked: number; currency_code: string; pending: number }) => {
					this.balanceBooked = response.booked;
					this.disbursement.currency = response.currency_code;
				});
			this.busyService.setBusy(getBalancePromise, this.translate.instant("TRANSACTIONS.RETRIEVING_BALANCE"));
		}
	}

	updateTotal() {
		const precision = 10;
		const feePercent = currency(this.disbursement.feePercent, {precision}).divide(this.numberToPercentDivisor);
		const feeFactor = currency(1, {precision}).subtract(feePercent);
		this.disbursement.total = currency(this.disbursement.amount, {precision}).multiply(feeFactor).subtract(this.disbursement.feeFlat).value;
//		this.disbursement.total = this.disbursement.amount * (1 - (this.disbursement.feePercent / 100)) - this.disbursement.feeFlat; //tslint:disable-line:no-magic-numbers
	}
}
