import {Component} from "@angular/core";
import {NavController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state/index";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {AddLocationsModal} from "../add-locations/add-locations.modal";
import {LocationModal} from "../location/location.modal";

@Component({
	selector: "page-mm-locations",
	styleUrls: ["./locations.page.scss"],
	templateUrl: "./locations.page.html"
})
export class LocationsPage {
	async: any;
	readonly locations$: Observable<MerchantLocation[]>;

	constructor(private modalCtrl: PromiseModalController,
							private navCtrl: NavController,
							private logger: Logger,
							private store: Store<MovebeState>) {
		this.locations$ = this.store.select(fromMerchant.getMerchantLocations);
	}

	/*tslint:disable:member-ordering*/
	addLocations(): void {
		this.modalCtrl.presentModal(AddLocationsModal)
			.catch(error => this.logger.error(error));
	}

	deleteLocation(event: MouseEvent, location: MerchantLocation): void {
		event.stopPropagation();
	}

	setLocationHidden(event: MouseEvent, location: MerchantLocation, hidden: boolean): void {
		event.stopPropagation();
	}

	editLocation(location: MerchantLocation): void {
		this.modalCtrl.presentModal(LocationModal, {
			location,
			locationId: location.$key,
		})
			.catch(error => this.logger.error(error));
	}
}
