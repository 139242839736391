import {Component} from "@angular/core";
import {ModalController, NavParams} from "@ionic/angular";

@Component({
	selector: "page-mm-terms",
	styleUrls: ["./terms.modal.scss"],
	templateUrl: "./terms.modal.html"
})
export class TermsModal {

	readonly termsTemplate: string;

	constructor(private navParams: NavParams,
							private modalController: ModalController) {
		this.termsTemplate = navParams.get("termsTemplate") as string;
	}

	dismiss() {
		return this.modalController.dismiss();
	}

	userAgreeToTerms() {
		return this.modalController.dismiss(true);
	}

}
