import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {UserProfile} from "../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../app/movebe-state.model";
import * as fromUser from "../../lib/user/+state/index";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {MappingService} from "../mapping/mapping.service";
import {ScanningService} from "../scanning/scanning.service";
import {CurrentUserService} from "../user/current-user.service";

@Injectable()
export class InitRemoteServicesService {
	userProfile$: Observable<UserProfile | null>;

	constructor(private firebaseService: FirebaseService,
							private firestoreService: FirestoreService,
							private currentUserService: CurrentUserService,
							private scanningService: ScanningService,
							private mappingService: MappingService,
							private store: Store<MovebeState>) {
		this.userProfile$ = this.store.select(fromUser.getUserProfile);
	}

	init(): Promise<any> {
		//TODO:  cleanly handle use case where app starts without internet connection (display message in default language, wait for connection to resume, then run init)
		return Promise.all([
			this.mappingService.init(),
			this.firestoreService.init(),
			this.firebaseService.init()
				.then(() => this.currentUserService.init())
		]);
	}
}
