import {Component, ViewChild} from "@angular/core";
import {ScanComponent} from "../../../shared/scanning/scan.component";

@Component({
	selector: "page-mb-scan",
	templateUrl: "scan.page.html"
})
export class ScanPage {

	@ViewChild(ScanComponent) scanComponent: ScanComponent;

	ionViewDidEnter() {
		this.scanComponent.scan();
	}

}
