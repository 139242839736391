import {Pipe, PipeTransform} from "@angular/core";
import * as formatstring from "formatstring";

@Pipe({name: "movebeFormatString"})
export class FormatStringPipe implements PipeTransform {
	//tslint:disable:no-unsafe-any
	transform(value: string, args?: any): string {
		return formatstring(value, ...args);
	}
}
