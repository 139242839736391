import {HttpErrorResponse} from "@angular/common/http";
import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {BusyState} from "../../../../lib/model/busy-state.model";
import {AppMode} from "../../core/app-mode/app-mode.model";
import {AppActions} from "./app.actions";

export interface AppState {
	appMode: AppMode;
	busyState?: BusyState;
	httpError?: HttpErrorResponse;
}

const defaultState: AppState = {
	appMode: AppMode.init
};

export function appReducer(state: AppState = defaultState, action: Action): AppState {
	if (isType(action, AppActions.SelectAppMode)) {
		return {...state, appMode: action.payload};
	}
	if (isType(action, AppActions.HttpErrorOccurred)) {
		return {...state, httpError: action.payload};
	}
	return state;
}

export const getAppMode = (state: AppState) => state.appMode;
export const getBusyState = (state: AppState) => state.busyState;
