/* tslint:disable:object-literal-sort-keys */

import {BenefitType, ProviderBenefit} from "../../../../lib/model/benefit.model";
import {Dictionary} from "../../../../lib/model/dictionary.model";

export const benefitData: Dictionary<ProviderBenefit> = {
	"bike-share-0250": {
		type: BenefitType.bikeShare,
		amount: 2.5,
		currency: "CAD",
		description: "get $2.50 towards a bike share",
	},
	"bike-share-0500": {
		type: BenefitType.bikeShare,
		amount: 5,
		currency: "CAD",
		description: "get $5.00 towards a bike share",
	},
	"ferry-0250": {
		type: BenefitType.ferry,
		amount: 2.5,
		currency: "CAD",
		description: "get $2.50 off your your ferry fare",
	},
	"ferry-0500": {
		type: BenefitType.ferry,
		amount: 5,
		currency: "CAD",
		description: "get $5.00 off your your ferry fare",
	},
	"ferry-1000": {
		type: BenefitType.ferry,
		amount: 10,
		currency: "CAD",
		description: "get $10.00 off your your ferry fare",
	},
	"fuel-0250": {
		type: BenefitType.fuel,
		amount: 2.5,
		currency: "CAD",
		description: "get $2.50 in fuel at a participating gas station",
	},
	"fuel-0500": {
		type: BenefitType.fuel,
		amount: 5,
		currency: "CAD",
		description: "get $5.00 in fuel at a participating gas station",
	},
	"fuel-5000": {
		type: BenefitType.fuel,
		amount: 50,
		currency: "CAD",
		description: "get $50.00 in fuel at a participating gas station",
	},
	"parking-0250": {
		type: BenefitType.parking,
		amount: 2.5,
		currency: "CAD",
		description: "get $2.50 off your parking at a supported parking lot",
	},
	"parking-0500": {
		type: BenefitType.parking,
		amount: 5,
		currency: "CAD",
		description: "get $5 off your parking at a supported parking lot",
	},
	"parking-0750": {
		type: BenefitType.parking,
		amount: 7.5,
		currency: "CAD",
		description: "get $7.50 off your parking at a supported parking lot",
	},
	"parking-1000": {
		type: BenefitType.parking,
		amount: 10,
		currency: "CAD",
		description: "get $10 off your parking at a supported parking lot",
	},
	"parking-1500": {
		type: BenefitType.parking,
		amount: 15,
		currency: "CAD",
		description: "get $15 off your parking at a supported parking lot",
	},
	"parking-2000": {
		type: BenefitType.parking,
		amount: 20,
		currency: "CAD",
		description: "get $20 off your parking at a supported parking lot",
	},
	"parking-2500": {
		type: BenefitType.parking,
		amount: 25,
		currency: "CAD",
		description: "get $25 off your parking at a supported parking lot",
	},
	"parking-3000": {
		type: BenefitType.parking,
		amount: 30,
		currency: "CAD",
		description: "get $30 off your parking at a supported parking lot",
	},
	"parking-4000": {
		type: BenefitType.parking,
		amount: 40,
		currency: "CAD",
		description: "get $40 off your parking at a supported parking lot",
	},
	"parking-5000": {
		type: BenefitType.parking,
		amount: 50,
		currency: "CAD",
		description: "get $50 off your parking at a supported parking lot",
	},
	"ride-hail-0250": {
		type: BenefitType.rideHail,
		amount: 2.5,
		currency: "CAD",
		description: "get $2.50 towards a ride with a cab or ride hailing app",
	},
	"ride-hail-0500": {
		type: BenefitType.rideHail,
		amount: 2.5,
		currency: "CAD",
		description: "get $5.00 towards a ride with a cab or ride hailing app",
	},
	"ride-hail-1500": {
		type: BenefitType.rideHail,
		amount: 15,
		currency: "CAD",
		description: "get $15.00 towards a ride with a cab or ride hailing app",
	},
	"toll-0250": {
		type: BenefitType.toll,
		amount: 2.5,
		currency: "CAD",
		description: "get $2.50 towards your highway toll",
	},
	"toll-0500": {
		type: BenefitType.toll,
		amount: 5,
		currency: "CAD",
		description: "get $5.00 towards your highway toll",
	},
	"toll-1500": {
		type: BenefitType.toll,
		amount: 15,
		currency: "CAD",
		description: "get $15.00 towards your highway toll",
	},
	"transit-0300": {
		type: BenefitType.transit,
		amount: 3,
		currency: "CAD",
		description: "get $3.00 loaded onto your transit card",
	},
	"transit-1-day": {
		type: BenefitType.transit,
		amount: 7.5,
		currency: "CAD",
		description: "get a 1 day pass issued to your fare card",
	},
	"transit-3-day": {
		type: BenefitType.transit,
		amount: 15,
		currency: "CAD",
		description: "get a 3 day pass issued to your fare card",
	},
	"transit-week": {
		type: BenefitType.transit,
		amount: 45,
		currency: "CAD",
		description: "get a 1 week pass issued to your fare card",
	}
};
