import {CommonModule} from "@angular/common";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {IonicModule} from "@ionic/angular";
import {NguiMapModule} from "@ngui/map";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import {NgBusyModule} from "ng-busy";
import {NgxBarcodeModule} from "ngx-barcode";
import {ClipboardModule} from "ngx-clipboard";
import {MomentModule} from "ngx-moment";
import {BarCodeComponent} from "./bar-code/bar-code.component";
import {Base26Pipe} from "./base26/base26.pipe";
import {FormValidationMessageComponent} from "./form-validation/form-validation-message.component";
import {FormatStringPipe} from "./format-string/format-string.pipe";
import {SentenceCasePipe} from "./format-string/sentence-case.pipe";
import {ModalHeaderComponent} from "./header/modal-header.component";
import {PageHeaderComponent} from "./header/page-header.component";
import {ImageUploadComponent} from "./image-upload/image-upload.component";
import {LocationFinderComponent} from "./location-finder/location-finder.component";
import {LocationMapComponent} from "./location-map/location-map.component";
import {MovebeLogoDirective} from "./logo/movebe-logo.directive";
import {MapJsComponent} from "./map/js/map-js.component";
import {MapComponent} from "./map/map.component";
import {MerchantOfferListComponent} from "./merchant-offer-list/merchant-offer-list.component";
import {MerchantOfferComponent} from "./merchant-offer/merchant-offer.component";
import {MerchantLocationProfileComponent} from "./merchant/merchant-location-profile.component";
import {MerchantLogoDirective} from "./merchant/merchant-logo.directive";
import {MerchantRoleColorPipe} from "./merchant/merchantr-role-color.pipe";
import {ProviderLogoDirective} from "./merchant/provider-logo.directive";
import {MovebeIconComponent} from "./movebe-icon/movebe-icon";
import {MovebeMarkerMapComponent} from "./movebe-marker-map/movebe-marker-map.component";
import {ObjectByKeyPipe} from "./object-by-key/object-by-key.pipe";
import {DayOfWeekPipe} from "./opening-hours/day-of-week.pipe";
import {MinutesToTimePipe} from "./opening-hours/minutes-to-time.pipe";
import {OpeningHoursComponent} from "./opening-hours/opening-hours.component";
import {ParkingLotFloorPlanDirective} from "./parking-lot/parking-lot-floor-plan.directive";
import {PinchZoomComponent} from "./pinch-zoom/pinch-zoom.component";
import {QRCodeComponent} from "./qr-code/qr-code.component";
import {RewardListComponent} from "./reward-list/reward-list.component";
import {BenefitTypeIconDirective} from "./reward/benefit-type-icon.directive";
import {IsExpiredPipe} from "./reward/is-expired.pipe";
import {RewardCardComponent} from "./reward/reward-card.component";
import {RewardComponent} from "./reward/reward.component";
import {ScanRequestEnlargedModal} from "./scan-request/scan-request-enlarged.modal";
import {ScanRequestComponent} from "./scan-request/scan-request.component";
import {ScanComponent} from "./scanning/scan.component";
import {ScrollListItemComponent} from "./scroll-list/scroll-list-item.component";
import {ScrollListComponent} from "./scroll-list/scroll-list.component";
import {StarRatingComponent} from "./star-rating/star-rating.component";
import {TelephoneNumberPipe} from "./telephone-number/telephone-number.pipe";
import {ConsumerTermsAndConditionsComponent} from "./terms-and-conditions/consumer-terms-and-conditions.component";
import {MerchantTermsAndConditionsComponent} from "./terms-and-conditions/merchant-terms-and-conditions.component";
import {ZenscrollDirective} from "./zenscroll/zenscroll.directive";
// import {MapNativeComponent} from "./map/native/map-native.component"; //TODO: re-implement native maps
// import {GoogleMaps} from "@ionic-native/google-maps"; //TODO: re-implement native maps

@NgModule({
	declarations: [
		BarCodeComponent,
		Base26Pipe,
		BenefitTypeIconDirective,
		ConsumerTermsAndConditionsComponent,
		DayOfWeekPipe,
		FormatStringPipe,
		FormValidationMessageComponent,
		PageHeaderComponent,
		ImageUploadComponent,
		IsExpiredPipe,
		LocationFinderComponent,
		LocationMapComponent,
		MapComponent,
		MapJsComponent,
		// MapNativeComponent, TODO: reimplement native maps
		MerchantLocationProfileComponent,
		MerchantLogoDirective,
		MerchantOfferComponent,
		MerchantOfferListComponent,
		MerchantRoleColorPipe,
		MerchantTermsAndConditionsComponent,
		MinutesToTimePipe,
		ModalHeaderComponent,
		MovebeLogoDirective,
		MovebeMarkerMapComponent,
		ObjectByKeyPipe,
		OpeningHoursComponent,
		ParkingLotFloorPlanDirective,
		PinchZoomComponent,
		ProviderLogoDirective,
		QRCodeComponent,
		ScanComponent,
		ScrollListComponent,
		ScrollListItemComponent,
		SentenceCasePipe,
		StarRatingComponent,
		TelephoneNumberPipe,
		ScanRequestComponent,
		ScanRequestEnlargedModal,
		RewardCardComponent,
		RewardComponent,
		RewardListComponent,
		ZenscrollDirective,
		MovebeIconComponent
	],
	entryComponents: [
		ScanRequestEnlargedModal
	],
	exports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ReactiveFormsModule,
		BarCodeComponent,
		BenefitTypeIconDirective,
		BrowserAnimationsModule,
		ConsumerTermsAndConditionsComponent,
		DayOfWeekPipe,
		FormatStringPipe,
		FormValidationMessageComponent,
		PageHeaderComponent,
		ImageUploadComponent,
		IsExpiredPipe,
		LocationFinderComponent,
		LocationMapComponent,
		MapComponent,
		MerchantLocationProfileComponent,
		MerchantLogoDirective,
		MerchantOfferComponent,
		MerchantOfferListComponent,
		MerchantRoleColorPipe,
		MerchantTermsAndConditionsComponent,
		MinutesToTimePipe,
		ModalHeaderComponent,
		MomentModule,
		MovebeMarkerMapComponent,
		MovebeLogoDirective,
		NgBusyModule,
		ObjectByKeyPipe,
		OpeningHoursComponent,
		ParkingLotFloorPlanDirective,
		ParkingLotFloorPlanDirective,
		PinchZoomComponent,
		ProviderLogoDirective,
		QRCodeComponent,
		ScanComponent,
		ScrollListComponent,
		ScrollListItemComponent,
		SentenceCasePipe,
		StarRatingComponent,
		TelephoneNumberPipe,
		TranslateModule,
		ScanRequestComponent,
		ScanRequestEnlargedModal,
		RewardCardComponent,
		RewardComponent,
		RewardListComponent,
		ZenscrollDirective,
		MovebeIconComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxBarcodeModule,
		NgBusyModule,
		FlexLayoutModule,
		HttpClientModule,
		IonicModule.forRoot(),
		MomentModule,
		NguiMapModule,
		TranslateModule.forRoot({
			loader: {
				deps: [HttpClient],
				provide: TranslateLoader,
				useFactory: (createTranslateLoader)
			}
		}),
		ZXingScannerModule.forRoot(),
		ClipboardModule,
	],
	providers: [
		//GoogleMaps, TODO: reimplement native maps
	]
})
export class MovebeSharedModule {
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
