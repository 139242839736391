import {Component, Input} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Logger} from "../../core/logger/logger.service";

@Component({
	selector: "ion-header[movebeModalHeader]",
	styleUrls: ["./modal-header.component.scss"],
	templateUrl: "./modal-header.component.html"
})
export class ModalHeaderComponent {

	@Input() title: string;
	@Input() hideDismissButton = false;

	constructor(private  logger: Logger,
							private modalCtrl: ModalController) {
	}

	dismiss() {
		this.modalCtrl
			.dismiss()
			.catch(error => this.logger.error(error));
	}

}
