import {Injectable} from "@angular/core";
import * as moment from "moment/moment";
import {OfferDetails} from "../../../../lib/model/offer-details.model";
import {PermitTag} from "../../../../lib/model/permit-tag.model";
import {PendingReward} from "../../../../lib/model/reward.model";
import {BarCodeType} from "../coupon/coupon.model";
import {Voucher} from "../coupon/voucher.model";
import {FirebaseService} from "../firebase/firebase.service";

@Injectable()
export class ParkingValidationService {

	constructor(private fb: FirebaseService) {
	}

	getParkingVoucher(pendingReward: PendingReward, offerDetails: OfferDetails): Promise<Voucher> {
		const expires = moment().add(moment.duration(offerDetails.offer.expires)).toISOString();
		return this.fb
			.issueParkingValidationCode("loco-mobi", pendingReward.consumerId)
			.then((code: PermitTag) => ({
				barCodeType: BarCodeType.qr,
				barCodeValue: `mdp${code.permitTag}-${code.detailPermitTag}`,
				description: offerDetails.benefit.description,
				expires,
				title: "Your ParkingPaid Voucher",
			}));
	}

}
