import {HttpErrorResponse} from "@angular/common/http";
import {action, payload} from "ts-action";
import {AppMode} from "../../core/app-mode/app-mode.model";

export module AppActions {

	export const SelectAppMode = action("[App] Select App Mode", payload<AppMode>());

	export const HttpErrorOccurred = action("[App] HTTP Error Occurred", payload<HttpErrorResponse>());

	export const HttpErrorNotified = action("[App] HTTP Error Notified");

}
