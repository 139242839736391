import {Action} from "@ngrx/store";
import {User} from "firebase/app";
import {isType} from "ts-action";
import {AppLink} from "../../../../../lib/model/app-link.model";
import {Language} from "../../../../../lib/model/language.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {AppMode} from "../../../core/app-mode/app-mode.model";
import {UserActions} from "./user.actions";

export interface UserState {
	authAppLinkEvent?: AppLink;
	authState: User | null;
	profile: UserProfile;
}

const defaultProfile: UserProfile = {
	appMode: AppMode.init,
	language: Language.en,
	partnerMode: "movebe",
	referringPartner: "movebe"
};

const defaultState: UserState = {
	authState: null,
	profile: defaultProfile
};

export function userReducer(state: UserState = defaultState, action: Action): UserState {
	if (isType(action, UserActions.AuthStateReceived)) {
		return {...state, authState: action.payload};
	}
	if (isType(action, UserActions.ProfileReceived)) {
		return {...state, profile: action.payload};
	}
	return state;
}

export const getAuthAppLinkEvent = (state: UserState) => state.authAppLinkEvent;
export const getProfile = (state: UserState) => state.profile;
export const getAuthState = (state: UserState) => state.authState;
export const getIsUserAccountLinkedWithEmail = (state: UserState) => state.authState ? state.authState.providerData.some(pd => pd!.providerId === "password") : false;
export const getIsUserEmailVerified = (state: UserState) => state.authState ? state.authState.emailVerified : false;
export const getIsUserSignedIn = (state: UserState) => state.authState ? !state.authState.isAnonymous : false;
export const getAppMode = (state: UserState) => state.profile.appMode;
export const getLanguage = (state: UserState) => state.profile.language;
export const getPartnerMode = (state: UserState) => state.profile.partnerMode;
export const getUserId = (state: UserState) => state.authState ? state.authState.uid : undefined;
export const getCurrentMerchant = (state: UserState) => state.profile.currentMerchant;
