/*tslint:disable:member-ordering*/
import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import {NgMapApiLoader} from "@ngui/map";
import {Observable, throwError as observableThrowError} from "rxjs";
import "rxjs/add/operator/map";
import {MerchantLocation} from "../merchant/merchant-location.model";
import {LocationAddress} from "./address.model";
import {LatLngLiteral} from "./lat-lng-literal";
import {LocationInfo, OpeningHours, OpeningPeriod} from "./location.model";
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import LatLng = google.maps.LatLng;
import PlaceResult = google.maps.places.PlaceResult;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

@Injectable()
export class MappingService {

	private geocoder: google.maps.Geocoder;
	private places: google.maps.places.PlacesService;

	constructor(private platform: Platform,
							private ngMapApiLoader: NgMapApiLoader) {
	}

	init() {
		return new Promise<void>((resolve) => {
			this.ngMapApiLoader.load();
			this.ngMapApiLoader.api$.subscribe(() => {
				resolve();
			});
		}).then(() => {
			const el = document.createElement("div");
			this.geocoder = new google.maps.Geocoder();
			this.places = new google.maps.places.PlacesService(el);
		});
	}

	computeDistanceBetween(from: google.maps.LatLng, to: google.maps.LatLng) {
		return google.maps.geometry.spherical.computeDistanceBetween(from, to);
	}

	getPlaces(query: string, location: google.maps.LatLng): Observable<PlaceResult[]> {
		return Observable
			.bindCallback(this.places.textSearch, (results: PlaceResult[], status: PlacesServiceStatus) => {
				return status === google.maps.places.PlacesServiceStatus.OK
					? results
					: status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
						? []
						: observableThrowError(status);
			})
			.call(this.places, {query, location, radius: 5000}) as Observable<PlaceResult[]>;
	}

	getMerchantLocations(merchantName: string, location: LatLngLiteral, radius: number): Promise<PlaceResult[]> {
		return new Promise((resolve, reject) => {
			this.places.nearbySearch({
					keyword: merchantName,
					location,
					rankBy: google.maps.places.RankBy.DISTANCE
				},
				((results, status) => {
					if (status === google.maps.places.PlacesServiceStatus.OK) {
						resolve(results);
					} else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
						resolve([]);
					} else {
						reject(status);
					}
				}));
		});
	}

	getLocation(query: string, location: LatLng): Promise<PlaceResult[]> {
		return new Promise((resolve, reject) => {
			this.places.textSearch({location, query}, ((results: PlaceResult[], status: PlacesServiceStatus) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					resolve(results);
				} else {
					reject(status);
				}
			}));
		});
	}

	getDetails(placeId: string): Observable<LocationInfo> {
		return Observable
			.bindCallback(this.places.getDetails, (place: PlaceResult, status: PlacesServiceStatus) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					return this.googlePlaceToLocation(place);
				} else {
					throw  {status, placeId, place};
				}
			})
			.call(this.places, {placeId}) as Observable<LocationInfo>;
	}

	addLocationDetails(location: MerchantLocation, place: PlaceResult): Promise<LocationInfo> {
		return new Promise((resolve, reject) => {
			this.places.getDetails({placeId: place.place_id}, (details: PlaceResult, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					if (details.hasOwnProperty("vicinity")) {
						resolve(this.addGooglePlaceInfoToLocation(location, details));
					} else {
						reject("Places query did not return a vicinity");
					}
				} else {
					reject(status);
				}
			});
		});
	}

	private addGooglePlaceInfoToLocation(location: LocationInfo, place: PlaceResult): LocationInfo {
		return {
			...location,
			...this.googlePlaceToLocation(place)
		};
	}

	googlePlaceToLocation(place: PlaceResult): LocationInfo {
		const openingHours = this.parseGoogleOpeningHours(place.opening_hours);
		return {
			address: this.googleAddressComponentsToAddress(place.address_components),
			geo: this.googleGeometryToLatLng(place.geometry),
			googlePlaceId: place.place_id,
			telephone: place.international_phone_number || "",
			vicinity: place.vicinity,
			...(openingHours ? {openingHours} : null)
		};
	}

	private parseGoogleOpeningHours(googleHours: google.maps.places.OpeningHours): OpeningHours | undefined {
		const openingHrs = googleHours && googleHours.periods
			? (googleHours.periods.length === 1)
				? {alwaysOpen: true}
				: {days: this.parseGoogleOpeningPeriods(googleHours.periods)}
			: undefined;
		return openingHrs;
	}

	private parseGoogleOpeningPeriods(periods: google.maps.places.OpeningPeriod[]): OpeningPeriod[] {
		const daysInWeek = 7;
		const minutesInDay = 1440;
		return periods.reduce((result: OpeningPeriod[], item: google.maps.places.OpeningPeriod) => {
			result[item.open.day] = {
				close: item.close
					? this.hhmmToMinutes(item.close.time) + (item.close.day - item.open.day) * minutesInDay
					: minutesInDay,
				closed: false,
				open: this.hhmmToMinutes(item.open.time)
			};
			return result;

		}, new Array<OpeningPeriod>(daysInWeek).fill({closed: true}));
	}

	/**
	 * Converts a time of day in the format used by google maps to a number of minutes
	 * returns 0 of if the input string is improperly formatted
	 * @param {string} hhmm: a string in a "hhmm" format
	 * @returns {number} the number of minutes.
	 */
	hhmmToMinutes(hhmm: string): number {
		const minutesPerHour = 60;
		const stringSplitLocation = 2;
		return Number(hhmm.slice(0, stringSplitLocation)) * minutesPerHour + Number(hhmm.slice(stringSplitLocation)) || 0;
	}

	googleGeometryToLatLng(geometry: google.maps.places.PlaceGeometry): LatLngLiteral {
		return {
			lat: geometry.location.lat(),
			lng: geometry.location.lng()
		};
	}

	googleAddressComponentsToAddress(addressComponents: GeocoderAddressComponent[]): LocationAddress {

		//function to parse weird Google response address format.
		//see: https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
		const find = (fieldName: string) => {
			const found = addressComponents
				.find((addressComponent: GeocoderAddressComponent) =>
					addressComponent
						.types
						.includes(fieldName)
				);
			return found ? found.short_name : "";
		};

		return {
			country: find("country"),
			municipality: find("locality"),
			postalCode: find("postal_code"),
			state: find("administrative_area_level_1"),
			street: `${find("street_number")} ${find("route")}`
		};
	}

	toBase26(n: number): string {
		const base = 26;
		let alpha = "";
		while (n > 0) {
			alpha += String.fromCharCode("A".charCodeAt(0) + (--n % base));
			n = Math.floor(n / base);
		}
		return alpha.split("").reverse().join("");
	}

	getLabelledMarkerUrl(text, backGroundColor, textColor) {
		return encodeURI(`https://chart.googleapis.com/chart?chst=d_bubble_text_small_withshadow&chld=bbT|${text}|${backGroundColor}|${textColor}`);
	}

	getParkingMarkerUrl(): string {
		return this.getCordovaLocalFileUrl("assets/img/map-markers/parking-marker-highlight.png");
	}

	getMerchantMarkerUrl(): string {
		return this.getCordovaLocalFileUrl("assets/img/map-markers/merchant-marker-highlight.png");
	}

	private getCordovaLocalFileUrl(path: string): string {
		return this.platform.is("cordova") && this.platform.is("android") ? `file:///android_asset/www/${path}` : path;
	}

}
