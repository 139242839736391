import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as Raven from "raven-js";
import {Observable} from "rxjs/Observable";
import {UserProfile} from "../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../app/movebe-state.model";

@Injectable()
export class Logger {

	readonly userProfile$: Observable<UserProfile>;

	constructor(private store: Store<MovebeState>) {
		this.userProfile$ = this.store.select((state: MovebeState) => state.user.profile);
	}

	init() {

		this.userProfile$.subscribe((userProfile: UserProfile) => {
			Raven.setUserContext({
				appMode: userProfile.appMode,
				displayName: userProfile.displayName,
				email: userProfile.email,
				phoneNumber: userProfile.phoneNumber,
				...userProfile.currentMerchant
					? {
						currentMerchantName: userProfile.currentMerchant.merchant.name,
						currentMerchantRole: userProfile.currentMerchant.employmentRelationship.role,
					} : {}
			});
		});

	}

	error(error: any) {
		console.error(error);
		Raven.captureException(error); //tslint:disable-line:no-unsafe-any
	}

	info(message?: any, ...optionalParams: any[]) {
		console.info(message, optionalParams);
	}

	log(message?: any, ...optionalParams: any[]) {
		console.log(message, optionalParams); //tslint:disable-line:no-console
	}

}
