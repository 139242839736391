import {Pipe, PipeTransform} from "@angular/core";
import {MerchantRole} from "../../../../lib/model/employment-relationship.model";

@Pipe({name: "movebeMerchantRoleColor"})
export class MerchantRoleColorPipe implements PipeTransform {
	transform(role: MerchantRole): string {
		switch (role) {
			case MerchantRole.owner:
				return "primary";
			case MerchantRole.manager:
				return "secondary";
			case MerchantRole.agent:
				return "light";
			default:
				return "";
		}
	}
}
