import {Component} from "@angular/core";
import {NavController} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {switchMap} from "rxjs/operators";
import * as fromMerchant from "../+state";
import {Coupon} from "../../../../../lib/model/benefit.model";
import {Offer} from "../../../../../lib/model/offer.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {OffersService} from "../../../core/offers/offers.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {CouponModal} from "../coupon/coupon.modal";

@Component({
	selector: "page-mm-coupons",
	templateUrl: "./coupons.page.html"
})
export class CouponsPage {
	readonly currentMerchantId$: Observable<string | undefined>;
	readonly coupons$: Observable<Coupon[]>;
	offersPublished: Offer[];
	offersUnpublished: Offer[];

	constructor(private modalCtrl: PromiseModalController,
							private navCtrl: NavController,
							private offersService: OffersService,
							private store: Store<MovebeState>) {
		this.currentMerchantId$ = this.store.pipe(select(fromMerchant.getCurrentMerchantId));
		this.coupons$ = this.currentMerchantId$
			.pipe(
				filterNulls(),
				switchMap(merchantId => this.offersService.getCoupons(merchantId))
			);
	}

	editCoupon(coupon) {
		return this.modalCtrl.presentModal(CouponModal, {coupon});
	}

	addNewCoupon() {
		return this.modalCtrl.presentModal(CouponModal);
	}

}
