import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {UUID} from "angular2-uuid";
import {Observable} from "rxjs";
import {AppLinkType} from "../../../../lib/model/app-link.model";
import {MovebeState} from "../../app/movebe-state.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../lib/user/+state";
import {FirebaseService} from "../firebase/firebase.service";
import {Logger} from "../logger/logger.service";
import {OffersService} from "../offers/offers.service";
import {RewardsService} from "../rewards/rewards.service";
import {ScanRequestParams, ScanRequestResponse} from "./scan-request-params.model";
import {ScanRequest, ScanRequestAction, ScanRequestStatus} from "./scan-request.model";

@Injectable()
export class ScanRequestService {
	readonly userId$: Observable<string>;

	constructor(private fb: FirebaseService,
							private logger: Logger,
							private rewardsService: RewardsService,
							private offerService: OffersService,
							private store: Store<MovebeState>) {
		this.userId$ = this.store.select(fromUser.getUserId).pipe(filterNulls());
	}

	generateScanRequest(action: ScanRequestAction, requestParams: ScanRequestParams): Observable<ScanRequest | null> {
		const requestId = UUID.UUID();
		const scanRequest = this.fb.getScanRequest(requestId);
		this.userId$.first()
			.subscribe(userId => {
				const request: ScanRequest = {
					action,
					appLink: {type: AppLinkType.scanRequest, value: requestId},
					requestParams,
					requestedByUserId: userId,
					status: ScanRequestStatus.pending,
				};
				scanRequest.query.ref.onDisconnect()
					.remove()
					.catch(error => this.logger.error(error));
				scanRequest.set(request)
					.catch(error => this.logger.error(error));
			});
		return this.fb.toObjectStream(scanRequest);
	}

	getScanRequest(requestId: string): Observable<ScanRequest | null> {
		return this.fb.toObjectStream(this.fb.getScanRequest(requestId));
	}

	getScanRequests(count: number): Observable<ScanRequest[]> {
		return this.fb.toListStream(this.fb.getScanRequests(count));
	}

	removeScanRequest(requestId: string) {
		return this.fb.getScanRequest(requestId).remove();
	}

	updateScanRequest(requestId: string, status: ScanRequestStatus, responseData?: ScanRequestResponse) {
		return this.fb.updateScanRequest(requestId, status, responseData);
	}

}
