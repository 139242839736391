import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Content, ModalController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {Observable} from "rxjs/Observable";
import {filter, first} from "rxjs/operators";
import * as fromChat from "../+state";
import {ChatActions} from "../+state/chat.actions";
import {ChatMessage} from "../../../../../lib/model/chat/chat-message.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";

@Component({
	selector: "movebe-chat",
	styleUrls: ["chat.page.scss"],
	templateUrl: "chat.page.html"
})
export class ChatPage implements OnInit, OnDestroy {

	@ViewChild("chatTextInput") chatTextInput: ElementRef;
	@ViewChild(Content) content: Content;
	readonly focusDelayMilliseconds = 350;
	readonly scrollAnimationDurationMilliseconds = 300;
	readonly messages$: Observable<ChatMessage[]>;
	private readonly done$ = new Subject<void>();
	message: string;
	waitingForMovebotMessage: string;

	constructor(private busyService: BusyService,
							private navParams: NavParams,
							private logger: Logger,
							private store: Store<MovebeState>,
							private translateService: TranslateService,
							private modalController: ModalController) {
		this.messages$ = store.select(fromChat.getMessages);
		this.messages$
			.takeUntil(this.done$)
			.subscribe(messages => {
					this.scrollToBottom();
				}
			);

		this.busyService.setBusy(
			this.messages$
				.pipe(
					filter(messages => messages.length > 0),
					first()
				)
				.toPromise(),
			this.translateService.instant("SUPPORT.WAITING_FOR_MOVEBOT")
		)
		;
	}

	ngOnInit() {
		this.store.dispatch(new ChatActions.StartChat());
		//wait until page creation animation is complete, or else focus will get lost during animation
		//TODO: figure out how to trigger this with an event when animation is complete
		setTimeout(() => {
			this.focusInput();
		}, this.focusDelayMilliseconds);
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
		this.store.dispatch(new ChatActions.EndChat());
	}

	onEnterKeyPress(event: Event) {
		event.preventDefault();
		this.sendMessage();
	}

	sendMessage(event?: Event) {
		this.store.dispatch(new ChatActions.SendMessage(this.message));
		this.message = "";
		this.focusInput();
	}

	focusInput() {
		(this.chatTextInput.nativeElement as HTMLTextAreaElement).focus();
	}

	scrollToBottom() {
		//TODO: (ionic 4) fix scroll to bottom
		// setTimeout(() => {
		// 	this.content.getScrollElement().scrollToBottom(this.scrollAnimationDurationMilliseconds)
		// 		.catch(error => this.logger.error(error));
		// });
	}

	dismiss() {
		this.modalController.dismiss()
			.catch(error => this.logger.error(error));
	}
}
