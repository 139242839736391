export interface RewardSearchParams {
	benefitType?: string;
	consumerId?: string;
	count?: number;
	filter?: RewardSearchFilter;
	merchantId?: string;
}

export enum RewardSearchFilter {
	all = "All",
	current = "Current",
	old = "Old"
}
