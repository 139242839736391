import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ChatEffects} from "./chat.effects";
import * as fromChatStore from "./chat.reducer";

export const featureName = "chat";

export interface FeatureState {
	chat: ChatState; //the name of this property must match the value of featureName const above
}

export type ChatState = fromChatStore.ChatState;

export const reducer = fromChatStore.chatReducer;
export const effects = [ChatEffects];

export const getChatState = createFeatureSelector<ChatState>(featureName);

export const getChat = createSelector(getChatState, fromChatStore.getChat);
export const getChatId = createSelector(getChatState, fromChatStore.getChatId);
export const getMessages = createSelector(getChatState, fromChatStore.getMessages);
