import {Component, Input} from "@angular/core";
import {ModalController, Nav} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Merchant} from "../../../../lib/model/merchant.model";
import {Observable} from "../../../node_modules/rxjs/Observable";
import {ScanPage as ConsumerScanPage} from "../../app-modes/consumer/scan/scan.page";
import * as fromMerchant from "../../app-modes/merchant/+state";
import {ChooseMerchantModal} from "../../app-modes/merchant/merchants/choose-merchant.modal";
import {ScanPage as MerchantScanPage} from "../../app-modes/merchant/scan/scan.page";
import * as fromApp from "../../app/+state";
import {MovebeState} from "../../app/movebe-state.model";
import {AppMode} from "../../core/app-mode/app-mode.model";
import {GeolocationService} from "../../core/geolocation/geolocation.service";
import {Logger} from "../../core/logger/logger.service";
import {LatLngLiteral} from "../../core/mapping/lat-lng-literal";
import * as fromUser from "../../lib/user/+state";
import {AccountModal} from "../../lib/user/account/account.modal";

@Component({
	selector: "ion-header[movebePageHeader]",
	styleUrls: ["./page-header.component.scss"],
	templateUrl: "./page-header.component.html"
})
export class PageHeaderComponent {

	@Input() title: string;
	readonly currentMerchant$: Observable<Merchant | undefined>;
	readonly appMode$: Observable<AppMode | undefined>;
	readonly AppMode = AppMode;
	readonly isUserSignedIn$: Observable<boolean>;
	readonly currentLatLng$: Observable<LatLngLiteral>;

	constructor(private logger: Logger,
							private nav: Nav,
							private store: Store<MovebeState>,
							private modalCtrl: ModalController,
							private geolocationService: GeolocationService
	) {
		this.currentMerchant$ = this.store.pipe(select(fromMerchant.getCurrentMerchant));
		this.appMode$ = this.store.pipe(select(fromApp.getAppMode));
		this.isUserSignedIn$ = this.store.pipe(select(fromUser.getIsUserSignedIn));
		this.currentLatLng$ = this.geolocationService.currentLatLng$;
	}

	openMerchantsPage() {
		this.modalCtrl.create({component: ChooseMerchantModal})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}

	openScanPage(appMode: AppMode) {
		this.nav
			.push(appMode === AppMode.merchant ? MerchantScanPage : ConsumerScanPage)
			.catch(error => this.logger.error(error));
	}

	openAccountPage() {
		this.modalCtrl
			.create({
				component: AccountModal
			})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}

}
