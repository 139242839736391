import {Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild} from "@angular/core";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {ModalController, Nav, Platform} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {IBusyConfig} from "ng-busy";
import {Observable} from "rxjs/Observable";
import {filter, first, flatMap, map, switchMap} from "rxjs/operators";
import {Settings} from "../../../lib/model/settings.model";
import {TermsAgreement} from "../../../lib/model/terms-agreement.model";
import {UserProfile} from "../../../lib/model/user/user-profile.model";
import {AppPage as AdminAppPage} from "../app-modes/admin/app/app.page";
import {AppPage as ConsumerAppPage} from "../app-modes/consumer/app/app.page";
import {CurrentUserActions} from "../app-modes/merchant/+state/current-user/current-user.actions";
import {AppPage as MerchantAppPage} from "../app-modes/merchant/app/app.page";
import {AppLinkService} from "../core/app-links/app-link.service";
import {AppMode} from "../core/app-mode/app-mode.model";
import {BusyService} from "../core/busy/busy.service";
import {ConnectionStatus} from "../core/connection-status/connection-status.model";
import {ConnectionStatusService} from "../core/connection-status/connection-status.service";
import {GeolocationService} from "../core/geolocation/geolocation.service";
import {InitRemoteServicesService} from "../core/init-remote-services.service/init-remote-services.service";
import {Logger} from "../core/logger/logger.service";
import {NavService} from "../core/nav/nav.service";
import {ScanningService} from "../core/scanning/scanning.service";
import {SettingsService} from "../core/settings/settings.service";
import {filterNulls} from "../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../lib/user/+state";
import {UserActions} from "../lib/user/+state/user.actions";
import {AccountModal} from "../lib/user/account/account.modal";
import {TermsModal} from "../shared/terms-and-conditions/terms.modal";
import * as fromApp from "./+state";
import {appEnvironment} from "./app-environment";
import {MovebeState} from "./movebe-state.model";

@Component({
	selector: "app-root", //tslint:disable-line
	styleUrls: ["./app.component.scss"],
	templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
	appMode$: Observable<AppMode>;
	rootPage$: Observable<typeof AdminAppPage | typeof ConsumerAppPage | typeof MerchantAppPage | undefined>;

	isProduction: boolean;
	readonly isUserSignedIn$ = this.store.pipe(
		select(fromUser.getIsUserSignedIn)
	);
	readonly settings$ = this.settingsService.getSettings();
	readonly userProfile$ = this.store.pipe(
		select(fromUser.getUserProfile),
		filterNulls()
	);
	busy$: Observable<IBusyConfig>;
	busy: IBusyConfig;

	@HostBinding("class.mouse-down") cursorDown = false;
	@HostBinding("class.hidden") appHidden = false;
	@ViewChild("nav") nav: ElementRef<Nav>;

	readonly AppModeRootPages: { name: AppMode; rootPage: typeof AdminAppPage | typeof ConsumerAppPage | typeof MerchantAppPage }[] = [
		{name: AppMode.admin, rootPage: AdminAppPage},
		{name: AppMode.consumer, rootPage: ConsumerAppPage},
		{name: AppMode.merchant, rootPage: MerchantAppPage}
	];

	constructor(private logger: Logger,
							private appLinkService: AppLinkService,
							private busyService: BusyService,
							private connectionStatusService: ConnectionStatusService,
							private initRemoteServicesService: InitRemoteServicesService,
							private geolocationService: GeolocationService,
							private modalCtrl: ModalController,
							private platform: Platform,
							private scanningService: ScanningService,
							private settingsService: SettingsService,
							private navSevice: NavService,
							private splashScreen: SplashScreen,
							private statusBar: StatusBar,
							private store: Store<MovebeState>,
							private translate: TranslateService) {

		this.busy$ = busyService.busy$;

		this.appMode$ = this.store.pipe(
			select(fromUser.getUserAuthState),
			filterNulls(),
			switchMap(() => this.store.pipe(
				select(fromApp.getAppMode))
			)
		);

		this.isProduction = appEnvironment.isProduction;

		this.rootPage$ = this.appMode$
			.pipe(
				map(newAppMode => {
					const item = this.AppModeRootPages.find(appMode => appMode.name === newAppMode);
					return item ? item.rootPage : undefined;
				}),
				filterNulls()
			);

		platform.ready()
			.then(() => this.initializeServices())
			.then(() => this.initializeConnectionMonitoring())
			.then(() => this.checkTermsAndConditions())
			.catch(error => this.logger.error(error));

		platform.resume.subscribe(() => {
			this.appLinkService.checkDeepLinks();
		});

	}

	ngOnInit() {
		this.navSevice.navCtrl = this.nav.nativeElement;
		this.store.dispatch(new CurrentUserActions.QueryEmployers());
	}

	initializeServices(): Promise<any> {
		if (this.platform.is("cordova")) {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
		}
		this.translate.use("en"); //TODO remove hardcoded language
		this.logger.init();
		const loading = this.initRemoteServicesService.init()
			.then(() => {
				this.appLinkService.checkDeepLinks();
			})
			.catch(error => this.logger.error(error));
		this.busyService.setBusy(loading);
		return loading;
	}

	checkTermsAndConditions(): Promise<any> {
		return Observable.combineLatest(
			this.settings$,
			this.userProfile$.pipe(filter(userProfile => userProfile.appMode !== AppMode.init))
		).pipe(
			flatMap(([settings, userProfile]: [Settings, UserProfile]) => {
				const termsAgreements: TermsAgreement[] = userProfile.termsAgreements || [];
				if (!termsAgreements.some((agreed: TermsAgreement) => agreed.type === "consumer" && Math.trunc(agreed.version) === Math.trunc(settings.termsAndConditionsVersions.consumer))) {
					const termsModalPromise = this.modalCtrl
						.create({
							backdropDismiss: false,
							component: TermsModal,
							componentProps: {termsTemplate: "consumer"},
						});
					termsModalPromise
						.then(modal => modal
							.onDidDismiss(results => {
								const didAgree = results.data;
								if (didAgree) {
									termsAgreements.push({
										date: new Date(),
										type: "consumer",
										version: settings.termsAndConditionsVersions.consumer
									});
									return this.store.dispatch(new UserActions.UpdateUserProfile({termsAgreements}));
								}
								else {
									return this.checkTermsAndConditions();
								}
							})
						)
						.catch(error => this.logger.error(error));
					return termsModalPromise.then(modal => modal.present());
				}
				return Promise.resolve();
			}),
			first()
		).toPromise();
	}

	initializeConnectionMonitoring() {
		this.connectionStatusService.disconnectAlert$.subscribe(disconnectAlert =>
			this.busyService.setBusy(
				disconnectAlert.connectionStatusEndedPromise,
				this.translate.instant(disconnectAlert.connectionStatus === ConnectionStatus.noFirebaseConnection
					? "BUSY.WAITING_TO_CONNECT_TO_MOVEBE"
					: "BUSY.WAITING_FOR_INTERNET_CONNECTION")
			));
		return Promise.resolve();
	}

	@HostListener("mousedown")
	mouseDown() {
		this.cursorDown = true;
	}

	@HostListener("mouseup")
	mouseUp() {
		this.cursorDown = false;
	}

	openAccountPage() {
		this.modalCtrl
			.create({
				component: AccountModal
			})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}
}
