import {Component, OnDestroy} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {Observable} from "rxjs/Observable";
import * as fromUser from "../+state/index";
import {UserActions} from "../+state/user.actions";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {CurrentUserService} from "../../../core/user/current-user.service";

@Component({
	selector: "page-mb-verify-email-modal",
	templateUrl: "./verify-email.modal.html"
})
export class VerifyEmailModal implements OnDestroy {

	private done$ = new Subject<void>();
	userProfile$: Observable<UserProfile>;

	verificationEmailResent = false;

	constructor(private busyService: BusyService,
							private currentUserService: CurrentUserService,
							private store: Store<MovebeState>,
							private translate: TranslateService,
							private modalController: ModalController) {

		this.store.select(fromUser.getIsUserEmailVerified)
			.filter(isVerified => !!isVerified)
			.takeUntil(this.done$)
			.subscribe(() => this.dismiss(true));

		this.userProfile$ = this.store.select(fromUser.getUserProfile);
	}

	refreshAuth() {
		this.store.dispatch(new UserActions.ReloadAuthState());
	}

	resendVerificationEmail() {
		this.verificationEmailResent = false;
		const emailSentPromise = this.currentUserService.sendEmailVerification();

		this.busyService.setBusy(emailSentPromise, this.translate.instant("AUTH.SENDING_VERIFICATION_EMAIL"));
		this.verificationEmailResent = true;
		return emailSentPromise;
	}

	dismiss(emailVerified: boolean) {
		return this.modalController.dismiss(emailVerified);
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

}
