import {Injectable} from "@angular/core";
import {AlertController, ModalController} from "@ionic/angular";
import {Actions, Effect} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {AngularFireAuth} from "angularfire2/auth";
import {FirebaseError, User} from "firebase";
import * as firebase from "firebase/app";
import {TelephoneNumber} from "libphonenumber-js";
import {Observable} from "rxjs/Observable";
import {concatMap, distinctUntilChanged, filter, first, map, switchMap, withLatestFrom} from "rxjs/operators";
import {ofType, toPayload} from "ts-action-operators";
import {Language} from "../../../../../lib/model/language.model";
import {Credentials} from "../../../../../lib/model/user/credentials.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {AppActions} from "../../../app/+state/app.actions";
import {MovebeState} from "../../../app/movebe-state.model";
import {AppMode} from "../../../core/app-mode/app-mode.model";
import {BusyService} from "../../../core/busy/busy.service";
import {FirebaseService} from "../../../core/firebase/firebase.service";
import {Logger} from "../../../core/logger/logger.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";
import {UsersService} from "../../../core/user/users.service";
import {TelephoneNumberPipe} from "../../../shared/telephone-number/telephone-number.pipe";
import {filterNulls} from "../../rxjs-operators/filter-nulls";
import {AddEmailModal} from "../add-email/add-email.modal";
import * as fromUser from "./index";
import {UserActions} from "./user.actions";

@Injectable()
export class UserEffects {

	readonly currentUserProfile$ = this.store
		.select(fromUser.getUserProfile)
		.pipe(filterNulls());

	readonly currentUserId$ = this.store
		.select(fromUser.getUserId)
		.pipe(filterNulls());

	readonly currentAuthState$ = this.store
		.select(fromUser.getUserAuthState)
		.pipe(filterNulls());

	@Effect() readonly queryAuthStateEffect$ = this.actions$.pipe(
		ofType(UserActions.QueryAuthState),
		switchMap(() => this.getAuthState()),
		map((authState: User) => new UserActions.AuthStateReceived(authState)),
	);

	@Effect() readonly reloadAuthStateEffect$ = this.actions$.pipe(
		ofType(UserActions.ReloadAuthState),
		switchMap(() => this.refreshAuthState()),
		map(() => new UserActions.QueryAuthState()),
	);

	@Effect() readonly authStateReceivedEffect$ = this.actions$.pipe(
		ofType(UserActions.AuthStateReceived),
		toPayload(),
		map(authState => authState.uid),
		distinctUntilChanged(),
		switchMap(userId => this.getUserProfile(userId)),
		map((userProfile: UserProfile) => userProfile
			? new UserActions.ProfileReceived(userProfile)
			: new UserActions.InitializeProfile,
		),
	);

	//ensures that displayName,email,phoneNumber fields are always synced from auth to firestore profile
	@Effect() readonly authStateAndProfileReceivedEffect$ = Observable.combineLatest(
		this.actions$.pipe(ofType(UserActions.AuthStateReceived), toPayload()),
		this.actions$.pipe(ofType(UserActions.ProfileReceived), toPayload()),
	)
		.pipe(
			filter(([user, userProfile]) =>
				user.uid === userProfile.$key! && (
					user.displayName !== userProfile.displayName
					|| user.email !== userProfile.email
					|| user.phoneNumber !== userProfile.phoneNumber
				)
			),
			map(([user, userProfile]) => new UserActions.UpdateUserProfile({
				displayName: user.displayName!,
				email: user.email!,
				phoneNumber: user.phoneNumber!,
			})),
		);

	//ensures that user has verfied email if they are not in AppMode.consumer
	@Effect() readonly authStateAndProfileReceivedEffect2$ = Observable.combineLatest(
		this.actions$.pipe(ofType(UserActions.AuthStateReceived), toPayload()),
		this.actions$.pipe(ofType(UserActions.ProfileReceived), toPayload()),
	)
		.pipe(
			filter(([user, userProfile]) => userProfile.appMode !== AppMode.consumer && !user.emailVerified),
			map(() => new AppActions.SelectAppMode(AppMode.consumer)),
		);

	@Effect() readonly updateUserProfileEffect$ = this.actions$.pipe(ofType(UserActions.UpdateUserProfile), toPayload(),
		withLatestFrom(this.currentUserId$),
		concatMap(([userProfile, userId]) => this.updateUserProfile(userId, userProfile)),
		map(() => new UserActions.UserProfileUpdated()),
	);

	@Effect() readonly initializeProfileEffect$ = this.actions$.pipe(ofType(UserActions.InitializeProfile),
		withLatestFrom(this.currentUserId$, this.currentAuthState$),
		concatMap(([language, userId, user]) => this.createUserProfile(userId, {
			appMode: AppMode.consumer,
			displayName: user.displayName!,
			email: user.email!,
			language: Language.en,
			partnerMode: "movebe",
			phoneNumber: user.phoneNumber!,
			referringPartner: "movebe",
		})),
		map(result => new UserActions.ProfileInitialized()),
	);

	@Effect() readonly profileReceivedEffectLoadTranslations$ = this.actions$.pipe(ofType(UserActions.ProfileReceived), toPayload(),
		map(profile => profile.language),
		distinctUntilChanged(),
		concatMap(language => this.translate.use(language)),
		map(() => new UserActions.TranslationsLoaded()),
	);

	@Effect() readonly profileReceivedEffectSelectAppMode$ = this.actions$.pipe(ofType(UserActions.ProfileReceived), toPayload(),
		first(), //only retrieve appMode from firebase once (so it doesn't update everywhere if you are running in multiple windows simultaneously)
		map(profile => new AppActions.SelectAppMode(profile.appMode)),
	);

	@Effect() readonly selectLanguageEffect$ = this.actions$.pipe(ofType(UserActions.SelectLanguage), toPayload(),
		withLatestFrom(this.currentUserId$),
		concatMap(([language, userId]) => this.updateUserProfile(userId, {language})),
		map(result => new UserActions.LanguageChanged()),
	);

	@Effect() readonly selectPartnerModeEffect$ = this.actions$.pipe(ofType(UserActions.SelectPartnerMode), toPayload(),
		withLatestFrom(this.currentUserId$),
		concatMap(([partnerMode, userId]) => this.updateUserProfile(userId, {partnerMode})),
		map(result => new UserActions.PartnerModeChanged()),
	);

	@Effect() readonly selectAppModeEffect$ = this.actions$.pipe(ofType(UserActions.SelectAppMode), toPayload(),
		withLatestFrom(this.currentUserId$),
		concatMap(([appMode, userId]) => this.updateUserProfile(userId, {appMode})),
		map(result => new UserActions.AppModeChanged()),
	);

	@Effect() readonly selectCurrentMerchantEffect$ = this.actions$.pipe(ofType(UserActions.SelectCurrentMerchant), toPayload(),
		withLatestFrom(this.currentUserId$),
		concatMap(([currentMerchant, userId]) => this.updateUserProfile(userId, {currentMerchant})),
		map(result => new UserActions.CurrentMerchantChanged()),
	);

	@Effect() readonly refreshLoginEffect$ = this.actions$.pipe(ofType(UserActions.RefreshLogin), toPayload(),
		withLatestFrom(this.currentAuthState$),
		map(([passwordProviderCredentials, user]) => new UserActions.SendAuthSms(user.phoneNumber!)),
	);

	@Effect() readonly signedInEffect$ = this.actions$.pipe(ofType(UserActions.SignedIn), toPayload())
		.concatMap(user => this.notifySignedIn(user))
		.map(() => new UserActions.SignInNotified);

	@Effect() readonly signInAnonymouslyEffect$ = this.actions$.pipe(ofType(UserActions.SignInAnonymously),
		concatMap(() => this.signInAnonymously()),
		map(user => new UserActions.SignedInAnonymously()),
	);

	//TODO: proper error handling for case where AppLinkAuthId is expired or not in the database (prompt to send new SMS)
	@Effect() readonly signInWithAppLinkAuthIdEffect$ = this.actions$.pipe(ofType(UserActions.SignInWithAppLinkAuthId), toPayload(),
		concatMap(authId => this.signInWithAppLinkAuthId(authId)),
		map(user => new UserActions.SignedIn(user)),
	);

	@Effect() readonly signInWithEmailEffect$ = this.actions$.pipe(ofType(UserActions.SignInWithEmail), toPayload(),
		concatMap((credentials: Credentials) =>
			this.signInWithEmail(credentials.email, credentials.password),
		),
		map(user => (user
				? new UserActions.SignedIn(user)
				: new UserActions.SignInFailed()
		)),
	);

	@Effect() readonly sendAuthSmsEffect$ = this.actions$.pipe(ofType(UserActions.SendAuthSms), toPayload(),
		concatMap(mobileNumber => this.sendAuthSMS(mobileNumber)),
		map(() => new UserActions.AuthSmsSent()),
	);

	@Effect() readonly signOutEffect$ = this.actions$.pipe(ofType(UserActions.SignOut),
		map(() => new UserActions.SignInAnonymously()),
	);

	constructor(private logger: Logger,
							private actions$: Actions,
							private afAuth: AngularFireAuth,
							private alertCtrl: AlertController,
							private api: MovebeApiService,
							private busyService: BusyService,
							private fb: FirebaseService,
							private modalCtrl: ModalController,
							private store: Store<MovebeState>,
							private telephoneNumberPipe: TelephoneNumberPipe,
							private usersService: UsersService,
							private translate: TranslateService) {
	}

	createUserProfile(userId: string, userProfile: UserProfile): Promise<void> {
		return this.usersService.setUser(userId, userProfile);
	}

	getAuthState(): Observable<any> {
		const authState$ = this.afAuth.authState;
		authState$.first()
			.filter(authState => !authState)
			.subscribe((authState: User) => {
				this.afAuth.auth.signInAnonymously()
					.catch(error => this.logger.error(error));
			});
		return authState$.pipe(filterNulls());
	}

	refreshAuthState(): Promise<void> {
		return this.afAuth.auth.currentUser!.reload();
	}

	getUserProfile(userId: string): Observable<UserProfile | null> {
		return this.usersService.getUser(userId);
	}

	sendAuthSMS(mobileNumber: TelephoneNumber, email?: string, password?: string) {
		const numberFormatted = this.telephoneNumberPipe.transform(mobileNumber, "US", "E.164");
		return this.api.sendAuthSMS(numberFormatted, email!, password!).toPromise();
	}

	signInAnonymously(): Promise<User> {
		return this.afAuth.auth
			.signInAnonymously()
			.then(userCredential => userCredential.user);
	}

	signInWithAppLinkAuthId(authId: string): Promise<User> {
		const signedInPromise = this.api.getAuthTokenForAuthId(authId)
			.toPromise()
			.then(authToken => this.afAuth.auth.signInWithCustomToken(authToken))
			.then(userCredential => userCredential.user);
		this.busyService.setBusy(signedInPromise, this.translate.instant("AUTH.SIGNING_IN") as string);
		return signedInPromise;
	}

	signInWithEmail(email: string, password: string): Promise<User | null> {
		return this.afAuth.auth.signInWithCredential(firebase.auth.EmailAuthProvider.credential(email, password))
			.catch((error: FirebaseError) => {
				if (error.code && error.code.startsWith("auth")) {
					return null; //bad email or password just returns null user
				}
				throw(error); //any other error (network error/server issue?) gets rethrown
			});
	}

	signOut(): Promise<any> {
		return this.signInAnonymously();
	}

	updateUserProfile(userId: string, userProfile: Partial<UserProfile>): Promise<void> {
		return this.usersService.updateUser(userId, userProfile);
	}

	private notifySignedIn(user: User) {
		return user.providerData.find(provider => provider!.providerId === "password")
			? this.alertCtrl
				.create({
					buttons: [this.translate.instant("BUTTONS.OK") as string],
					header: this.translate.instant("AUTH.SIGNED_IN") as string,
					subHeader: this.translate.instant("AUTH.SIGN_IN_SUCCESSFUL") as string,
				})
				.then(alert => alert.present())

			: this.modalCtrl
				.create({
					component: AddEmailModal,
					componentProps: {justSignedIn: true}
				})
				.then(modal => modal.present());

	}

}
