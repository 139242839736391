import {ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import {Validators} from "@angular/forms";
import {Content, Nav, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {AbstractControl, FormBuilder, FormGroup} from "ngx-strongly-typed-forms";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state";
import {Benefit, BenefitSummary, BenefitType, Coupon, SummaryFromBenefit} from "../../../../../lib/model/benefit.model";
import {Dictionary} from "../../../../../lib/model/dictionary.model";
import {Language} from "../../../../../lib/model/language.model";
import {Offer} from "../../../../../lib/model/offer.model";
import {groupBy, indexBy} from "../../../../../lib/util/array-map";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {TimeUnitTypes} from "../../../core/misc/time-slice.model";
import {BenefitTypes} from "../../../core/offers/benefit-types";
import {OffersService} from "../../../core/offers/offers.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../../lib/user/+state";
import {OfferForm} from "./offer-form.model";

@Component({
	selector: "page-mm-offer",
	templateUrl: "./offer.page.html"
})
export class OfferPage {
	@ViewChild(Content) content: Content;
	readonly benefits$: Observable<Benefit[]>;
	readonly availableBenefits$: Observable<Benefit[] | Coupon[]>;
	readonly benefitTypes = BenefitTypes;
	readonly currentMerchantId$: Observable<string>;
	readonly coupons$: Observable<Coupon[]>;
	readonly durationTypes = Object.keys(TimeUnitTypes);
	language$: Observable<Language>;
	locations$: Observable<MerchantLocation[]>;
	readonly productTypes = Object.keys(BenefitTypes);
	readonly timeUnitTypes = TimeUnitTypes;
	BenefitType = BenefitType;
	originalOffer$: Observable<Offer>;
	resultOffer$: Observable<Offer>;
	readonly offerForm: FormGroup<OfferForm>;
	readonly resizeDebounceTime = 250;

	get benefitIdControl(): AbstractControl<string> {
		return this.offerForm.get("benefitId")!;
	}

	get benefitTypeControl(): AbstractControl<string> {
		return this.offerForm.get("benefitType")!;
	}

	get criteriaControl(): AbstractControl<string> {
		return this.offerForm.get("criteria")!;
	}

	get expiresQuantityControl(): AbstractControl<string> {
		return this.offerForm.get("expiresQuantity")!;
	}

	get expiresUnitControl(): AbstractControl<string> {
		return this.offerForm.get("expiresUnit")!;
	}

	get locationControl() {
		return this.offerForm.get("location")!;
	}

	constructor(private busyService: BusyService,
							private changeDetector: ChangeDetectorRef,
							private nav: Nav,
							private offersService: OffersService,
							private logger: Logger,
							private navParams: NavParams,
							private store: Store<MovebeState>,
							private formBuilder: FormBuilder) {

		const offerId: string = this.navParams.get("offerId") as string;

		this.offerForm = formBuilder.group<OfferForm>({
			benefitId: ["", Validators.required],
			benefitType: [BenefitType.parking, Validators.required],
			criteria: ["", Validators.required],
			expiresQuantity: ["", Validators.required],
			expiresUnit: ["", Validators.required],
			location: [""],
		});

		this.language$ = this.store.select(fromUser.getLanguage);

		this.currentMerchantId$ = this.store
			.select(fromMerchant.getCurrentMerchantId)
			.pipe(filterNulls());

		this.coupons$ = this.currentMerchantId$
			.switchMap(merchantId => this.offersService.getCoupons(merchantId));

		this.locations$ = this.store.select(fromMerchant.getMerchantLocations);
		this.coupons$ = this.currentMerchantId$
			.switchMap(merchantId => this.offersService.getCoupons(merchantId));

		this.benefits$ = this.offersService.getBenefits();

		const benefitsDictionary$: Observable<Dictionary<Benefit | Coupon>> = Observable
			.combineLatest(
				this.benefits$,
				this.coupons$
			)
			.map(([benefits, coupons]) => indexBy(benefits.concat(coupons), benefit => benefit.$key!));

		const benefitsGroupedByType$: Observable<Dictionary<Benefit[]>> = this.benefits$
			.map(benefits => groupBy(benefits, benefit => benefit.type));

		this.availableBenefits$ = Observable.combineLatest(
			benefitsGroupedByType$,
			this.coupons$,
			this.benefitTypeControl.valueChanges
		)
			.map(([benefitsGrouped, coupons, selectedBenefitType]) =>
				selectedBenefitType === this.BenefitType.coupon
					? coupons
					: benefitsGrouped[selectedBenefitType] || []
			);

		this.benefitTypeControl.valueChanges
			.subscribe(() => {
				if (!this.benefitTypeControl.pristine) {
					this.benefitIdControl.setValue("");
				}
			});

		this.originalOffer$ = ((offerId
			? this.currentMerchantId$.switchMap(merchantId => this.offersService.getOffer(merchantId, offerId))
			: Observable.of(this.offersService.getNewOffer()).delay(0)) as Observable<Offer>)
			.pipe(filterNulls())
			.first()
			.shareReplay();

		this.originalOffer$.subscribe((offer: Offer) => {
			const expiresUnit: string = Object.keys(offer.expires || {})[0];
			this.offerForm.patchValue({
				benefitId: offer.benefit.key || "",
				benefitType: offer.benefit.type || "",
				criteria: offer.criteria || "",
				expiresQuantity: expiresUnit ? offer.expires[expiresUnit] as string : "",
				expiresUnit: expiresUnit || "",
				location: offer.universal ? "all" : offer.location
			});
		});

		this.resultOffer$ = Observable.combineLatest(
			this.originalOffer$,
			this.offerForm.valueChanges,
			benefitsDictionary$,
		)
			.map(([offer, formOffer, benefitsDictionary]) => {

				const formValue = this.offerForm.value;
				const benefitType = formOffer.benefitType as BenefitType;
				const newBenefit: BenefitSummary = formOffer.benefitId
					? SummaryFromBenefit(benefitsDictionary[formOffer.benefitId])
					: benefitType === BenefitType.coupon
						? {
							description: "",
							key: "",
							type: benefitType,
						}
						: {
							amount: 0,
							currency: "",
							description: "",
							key: "",
							type: benefitType,
						};
				const patch: Partial<Offer> = {
					benefit: newBenefit,
					criteria: formOffer.criteria,
					expires: {
						[formOffer.expiresUnit]: parseInt(formOffer.expiresQuantity, 10)
					},
					universal: formOffer.location === "all",
					...formOffer.location !== "all"
						? {location: formOffer.location}
						: {}
				};
				Object.assign(offer, patch);
				return offer;
			})
			.shareReplay();

		//TODO: check auto resize() and delete
		// this.resultOffer$
		// 	.pipe(filterNulls())
		// 	.debounceTime(this.resizeDebounceTime)
		// 	.subscribe(() => {
		// 		this.content.resize();
		// 	});

	}

	cancelEdit() {
		this.nav.pop()
			.catch(error => this.logger.error(error));
	}

	saveOffer(): Promise<any> {
		const savingOfferPromise = Observable.combineLatest(this.currentMerchantId$, this.resultOffer$)
			.first()
			.toPromise()
			.then(([merchantId, offer]) =>
				(offer.$key
					? this.offersService.updateOffer(merchantId, offer)
					: this.offersService.addOffer(merchantId, offer)) as Promise<any>
			)
			.then(() => this.nav.pop());

		this.busyService.setBusy(savingOfferPromise);
		return savingOfferPromise;
	}

}
