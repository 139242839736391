import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {IonicModule} from "@ionic/angular";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import {AppPage} from "./app/app.page";
import {DisbursementPage} from "./disbursement/disbursement.page";
import {ImportPage} from "./import/import.page";
import {MerchantPage} from "./merchant/merchant.page";
import {RecentValidationsPage} from "./merchant/recent-validations.page";
import {MerchantsPage} from "./merchants/merchants.page";
import {SettlementPage} from "./settlement/settlement.page";
import {StakeholderModal} from "./stakeholder.modal/stakeholder.modal";
import {TransactionsPage} from "./transactions/transactions.page";

@NgModule({
	declarations: [
		AppPage,
		DisbursementPage,
		ImportPage,
		MerchantPage,
		MerchantsPage,
		RecentValidationsPage,
		SettlementPage,
		StakeholderModal,
		TransactionsPage
	],
	entryComponents: [
		AppPage,
		DisbursementPage,
		ImportPage,
		MerchantPage,
		MerchantsPage,
		RecentValidationsPage,
		SettlementPage,
		StakeholderModal,
		TransactionsPage
	],
	exports: [
		AppPage,
		DisbursementPage,
		ImportPage,
		MerchantPage,
		MerchantsPage,
		RecentValidationsPage,
		SettlementPage,
		TransactionsPage
	],
	imports: [
		FlexLayoutModule,
		IonicModule,
		MovebeSharedModule
	],
	providers: []
})
export class AdminModule {
}
