import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {AlertController, ModalController, Nav} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {BenefitType} from "../../../../lib/model/benefit.model";
import {Merchant} from "../../../../lib/model/merchant.model";
import {Provider, ProviderType} from "../../../../lib/model/provider.model";
import {IssuedReward, Reward} from "../../../../lib/model/reward.model";
import {MovebeState} from "../../app/movebe-state.model";
import {BusyService} from "../../core/busy/busy.service";
import {Logger} from "../../core/logger/logger.service";
import {MerchantsService} from "../../core/merchants/merchants.service";
import {ProvidersService} from "../../core/providers/providers.service";
import {RewardsService} from "../../core/rewards/rewards.service";
import {RewardScanRequestParams} from "../../core/scan-request/scan-request-params.model";
import {ScanRequestAction} from "../../core/scan-request/scan-request.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {SurveyModal} from "../../lib/survey/survey-form/survey.modal";

@Component({
	selector: "movebe-reward",
	styleUrls: ["./reward.component.scss"],
	templateUrl: "./reward.component.html"
})
export class RewardComponent implements OnInit, OnChanges {

	BenefitType = BenefitType;
	ScanRequestAction = ScanRequestAction;
	ProviderType = ProviderType;

	@Input() reward: IssuedReward;
	@Input() autoPopupSurvey = false;

	@Output() deleted = new EventEmitter<any>();

	bikeShareProviders$: Observable<Provider[]>;
	cabProviders$: Observable<Provider[]>;
	ferryProviders$: Observable<Provider[]>;
	fuelProviders$: Observable<Provider[]>;
	merchant$: Observable<Merchant | null>;
	parkingProviders$: Observable<Provider[]>;
	redemptionMethod = ProviderType.parkingApp;
	reward$ = new BehaviorSubject<Reward | null>(null);
	tollProviders$: Observable<Provider[]>;
	transitProviders$: Observable<Provider[]>;
	scanRequestParams$: Observable<RewardScanRequestParams>;

	constructor(private alertCtrl: AlertController,
							private busyService: BusyService,
							private logger: Logger,
							private merchantsService: MerchantsService,
							private nav: Nav,
							private modalCtrl: ModalController,
							private providersService: ProvidersService,
							private rewardsService: RewardsService,
							private store: Store<MovebeState>,
							private translate: TranslateService) {

		//TODO: filter by provider  type depending on benefit type
		//TODO: filter by provider on server using query
		this.bikeShareProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.bikeShare)
			);

		this.cabProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.cab)
			);

		this.ferryProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.ferry)
			);

		this.fuelProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.fuel)
			);

		this.parkingProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.parkingApp)
			);

		this.tollProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.toll)
			);

		this.transitProviders$ = this.providersService.getProviders()
			.map(providers => providers
				.filter((provider: Provider) =>
					provider.type === ProviderType.transit)
			);

		this.merchant$ = this.reward$
			.pipe(filterNulls())
			.flatMap(reward => this.merchantsService.getMerchant(this.reward.merchant.key));

		this.scanRequestParams$ = this.reward$
			.pipe(filterNulls())
			.map(reward => ({
				merchantId: reward.merchant.key,
				rewardId: reward.$key!
			}));

	}

	ngOnInit() {
		if (this.autoPopupSurvey) {
			this.merchant$
				.pipe(filterNulls())
				.first()
				.subscribe(merchant => {
					this.presentSurveyModal(this.reward, merchant);
				});
		}
	}

	ngOnChanges(): void {
		if (this.reward) {
			this.reward$.next(this.reward);
		}
	}

	presentSurveyModal(reward: Reward, merchant: Merchant) {
		this.modalCtrl
			.create({
				component: SurveyModal,
				componentProps: {merchant, reward}
			})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}

	dismiss(reward: Reward) {
		this.alertCtrl.create({
			buttons: [
				{
					text: this.translate.instant("BUTTONS.CANCEL") as string
				}, {
					handler: () => {
						this.rewardsService.dismissReward(reward.$key!)
							.then(() => this.nav.pop())
							.catch(error => this.logger.error(error));
					},
					text: this.translate.instant("BUTTONS.DISMISS") as string
				}
			],
			header: this.translate.instant("REWARDS.CONFIRM_DISMISS") as string, //"Dismiss this voucher?",
			message: this.translate.instant("REWARDS.FIND_IN_HISTORY") as string, //"You will still be able to find this voucher in your history",
		})
			.then(alert => alert.present())
			.catch(error => this.logger.error(error));
	}

//TODO:  get rid of alert, display details of amount applied & provider info inline
	redeem(reward: Reward, provider: Provider): void {

		const redeemedPromise = this.rewardsService.setRewardRedeemed(reward, provider);
		this.busyService.setBusy(redeemedPromise, this.translate.instant("REWARDS.REDEEMING_OFFER"));

		redeemedPromise
			.then(() => this.presentRedeemedAlert())
			.catch(error => this.logger.error(error));
	}

	requestApproved(): void {
		this.presentRedeemedAlert();
	}

	presentRedeemedAlert(): void {
		this.alertCtrl
			.create({
				buttons: [{
					handler: () => {
						this.nav.pop().catch(error => this.logger.error(error));
					},
					text: this.translate.instant("BUTTONS.OK") as string
				}
				],
				header: this.translate.instant("REWARDS.REDEEMED") as string,
				message: this.translate.instant("REWARDS.REDEEMED_MSG") as string,
			})
			.then(alert => alert.present())
			.catch(error => this.logger.error(error));
	}

}
