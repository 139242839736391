import {Component} from "@angular/core";
import {Nav} from "@ionic/angular";
import {Logger} from "../../../core/logger/logger.service";
import {MovebeMarkersService} from "../../../core/movebe-markers/movebe-markers.service";
import {MarkerPage} from "./marker.page";

@Component({
	selector: "page-mb-marker-list",
	templateUrl: "marker-list.page.html"
})
export class MarkerListPage {

	readonly markerScans$;

	constructor(private nav: Nav,
							private logger: Logger,
							private markersService: MovebeMarkersService) {
		this.markerScans$ = this.markersService.getCurrentUserRecentMarkerScans();
	}

	showScan(scanId) {
		this.nav.push(MarkerPage, {scanId})
			.catch(error => this.logger.error(error));
	}
}
