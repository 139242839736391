export interface AppLink {
	type: AppLinkType | string;
	value: string;
}

export enum AppLinkType {
	auth = "au",
	movebeMarker = "mm",
	pushValidation = "pv",
	scanRequest = "sr",
	voucher = "vc"
}
