import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MenuController, Nav} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import * as fromMerchant from "../+state";
import {CurrentMerchantActions} from "../+state/current-merchant/current-merchant.actions";
import {AppLink, AppLinkType} from "../../../../../lib/model/app-link.model";
import {Employer} from "../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {AppLinkService} from "../../../core/app-links/app-link.service";
import {Logger} from "../../../core/logger/logger.service";
import * as fromUser from "../../../lib/user/+state";
import {CouponsPage} from "../coupons/coupons.page";
import {DashboardPage} from "../dashboard/dashboard.page";
import {DisplayOffersPage} from "../display-offers/display-offers.page";
import {EmailOffersPage} from "../email-offers/email-offers.page";
import {LocationsPage} from "../locations/locations.page";
import {GenerateSignsPage} from "../movebe-markers/generate-signs/generate-signs.page";
import {GeotagSignsPage} from "../movebe-markers/geotag-signs/geotag-signs.page";
import {MovebeMarkersPage} from "../movebe-markers/movebe-markers.page";
import {OffersPage} from "../offers/offers.page";
import {ProfilePage} from "../profile/profile.page";
import {ScanRequestReviewPage} from "../scan-request/scan-request-review.page";
import {ScanPage} from "../scan/scan.page";
import {SmsOffersPage} from "../sms-offers/sms-offers.page";
import {UsersPage} from "../users/users.page";

@Component({
	selector: "page-mm-app",
	styleUrls: ["./app.page.scss"],
	templateUrl: "./app.page.html"
})
export class AppPage implements OnInit, OnDestroy {

	@ViewChild("nav") nav: ElementRef<Nav>;

	rootParams;

	readonly couponsPageRoot = CouponsPage;
	readonly dashboardPageRoot = DashboardPage;
	readonly displayOffersPageRoot = DisplayOffersPage;
	readonly emailOffersPageRoot = EmailOffersPage;
	readonly generateSignsPageRoot = GenerateSignsPage;
	readonly geotagSignsPageRoot = GeotagSignsPage;
	readonly locationsPageRoot = LocationsPage;
	readonly movebeMarkersPageRoot = MovebeMarkersPage;
	readonly offersPageRoot = OffersPage;
	readonly profilePageRoot = ProfilePage;
	readonly rootPage;
	readonly scanPageRoot = ScanPage;
	readonly smsOffersPageRoot = SmsOffersPage;
	readonly usersPageRoot = UsersPage;
	readonly scanRequestPageRoot = ScanRequestReviewPage;

	readonly appLinkEvent$: Observable<AppLink>;
	readonly currentMerchant$: Observable<Merchant | undefined>;
	private readonly done$ = new Subject<void>();
	readonly employers$: Observable<Employer[]>;
	readonly isUserSignedIn$: Observable<boolean>;
	readonly isUserAccountLinkedWithEmail$: Observable<boolean>;

	constructor(private menuCtrl: MenuController,
							private appLinkService: AppLinkService,
							private logger: Logger,
							private store: Store<MovebeState>) {

		this.isUserSignedIn$ = this.store.pipe(select(fromUser.getIsUserSignedIn));
		this.isUserAccountLinkedWithEmail$ = this.store.pipe(select(fromUser.getIsUserAccountLinkedWithEmail));
		this.rootPage = DashboardPage;

		this.appLinkEvent$ = this.appLinkService.appLinkEvent$
			.takeUntil(this.done$);

		this.currentMerchant$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant),
			takeUntil(this.done$)
		);

		this.employers$ = this.store.pipe(
			select(fromMerchant.getEmployers),
			takeUntil(this.done$)
		);
	}

	ngOnInit() {
		this.store.dispatch(new CurrentMerchantActions.QueryCurrentEmployer());
		this.initializeAppLinkHandling();
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	closeMenu() {
		this.menuCtrl.close()
			.catch(error => this.logger.error(error));
	}

	initializeAppLinkHandling() {

		this.appLinkEvent$
			.filter(appLink => appLink.type === AppLinkType.movebeMarker)
			.subscribe(appLink => {
				this.openPage(this.geotagSignsPageRoot, {markerCode: appLink.value});
			});

		this.appLinkEvent$
			.filter(appLink => appLink.type === AppLinkType.scanRequest)
			.subscribe(appLink => {
				this.openPage(this.scanRequestPageRoot, {scanRequestId: appLink.value});
			});
	}

	openPage(page, params?) {
		this.nav.nativeElement.setRoot(page, params)
			.catch(error => this.logger.error(error));
		this.closeMenu();
	}
}
