import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserProfile} from "../../../../lib/model/user/user-profile.model";
import {FirestoreService} from "../firebase/firestore.service";

@Injectable()
export class UsersService {

	constructor(private firestore: FirestoreService) {
	}

	getUser(userId: string): Observable<UserProfile | null> {
		return this.firestore.toObjectStream(this.firestore.getUserProfile(userId));
	}

	setUser(userId: string, userProfile: UserProfile): Promise<void> {
		return this.firestore.getUserProfile(userId)
			.set(userProfile);
	}

	updateUser(userId: string, userProfile: Partial<UserProfile>): Promise<void> {
		return this.firestore.getUserProfile(userId)
			.update(userProfile);
	}
}
