import {Directive, HostBinding, Input, OnChanges} from "@angular/core";
import {paramCase} from "change-case";
import {BenefitType} from "../../../../lib/model/benefit.model";

@Directive({
	selector: "img[movebeBenefitTypeIcon]"
})
export class BenefitTypeIconDirective implements OnChanges {

	readonly BLANK_IMAGE = "assets/img/1x1.transparent.png";

	@HostBinding("style.height") height = "auto";
	@HostBinding("style.width") width = "auto";
	@HostBinding("src") src: string;
	@Input() imageSize = 40;
	@Input() benefitType: BenefitType;

	ngOnChanges() {
		this.src = this.benefitType
			? `assets/img/benefit-type/${paramCase(this.benefitType)}.icon.svg`
			: this.BLANK_IMAGE;
		this.height = `${this.imageSize}px`;
		this.width = `${this.imageSize}px`;
	}

}
