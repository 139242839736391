import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Logger} from "../logger/logger.service";

/**
 * Convenience wrapper for Ionic ModalController .
 * Returns a promise which resolves to the data passed to the dismiss() function.
 * This differs from the Ionic ModalController behavior which is to return a promise that resolves when initial
 * animation is complete and requires use of onDidDismiss to retrieve results.
 */
@Injectable()
export class PromiseModalController {

	constructor(private logger: Logger,
							private modalCtrl: ModalController) {
	}

	presentModal<T>(component: any, data?: any, backdropDismiss = true): Promise<T> {
		const modalPromise = this.modalCtrl
			.create({
				backdropDismiss,
				component,
				componentProps: data,
			});

		modalPromise.then(modal => modal.present())
			.catch(error => this.logger.error(error));

		return modalPromise
			.then(modal => modal.onDidDismiss())
			.then(details => details.data);

	}

}
