import {Component, OnInit} from "@angular/core";
import {Nav, NavParams, ToastController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {FileHolder} from "angular2-image-upload";
import {Observable} from "rxjs/Observable";
import {Employee} from "../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantTypes} from "../../../core/merchant/merchant-types";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import * as fromUser from "../../../lib/user/+state";
import {DashboardPage} from "../dashboard/dashboard.page";

@Component({
	selector: "page-mm-profile",
	styleUrls: ["./profile.page.scss"],
	templateUrl: "./profile.page.html"
})
export class ProfilePage implements OnInit {
	businessTypes: string[];
	readonly currentUserId$: Observable<string | undefined>;
	merchant: Merchant;
	merchantLogo: Blob;
	readonly merchantTypes = MerchantTypes;
	newMerchantManager: Employee;

	constructor(private logger: Logger,
							private merchantsService: MerchantsService,
							private nav: Nav,
							private navParams: NavParams,
							private toastCtrl: ToastController,
							private store: Store<MovebeState>,
							private translateService: TranslateService) {
		this.currentUserId$ = store.select(fromUser.getUserId);
	}

	ngOnInit(): void {
		this.businessTypes = Object.keys(this.merchantTypes);
		this.merchant = this.navParams.get("merchant") as Merchant;
		if (!this.merchant) {
			this.merchant = this.merchantsService.getNewMerchant();
		}
		if (!this.merchant.webLinks) {
			this.merchant.webLinks = [];
		}
		this.newMerchantManager = this.navParams.get("newMerchantManager") as Employee;
	}

	/*tslint:disable:member-ordering*/
	addWebLink(): void {
		this.merchant.webLinks!.push(this.merchantsService.getNewWebLink());
	}

	deleteWebLink(index: number): void {
		this.merchant.webLinks!.splice(index, 1);
	}

	imageUploaded(event: FileHolder) {
		this.merchantLogo = new Blob([event.file], {type: event.file.type});
	}

	/*tslint:enable:member-ordering*/
	saveMerchant() {
		this.merchantsService.addOrUpdateMerchant(this.merchant)
			.first()
			.withLatestFrom(this.currentUserId$)
			.subscribe(([merchant, userId]) => {
				this.merchantsService.uploadLogo(merchant!, this.merchantLogo);
			});
		this.toastCtrl
			.create({
				duration: 3000,
				message: this.translateService.instant("MERCHANT_FORM.SAVED") as string,
				position: "middle"
			})
			.then(toast => toast.present())
			.catch(error => this.logger.error(error));
		this.nav.setRoot(DashboardPage).catch(error => this.logger.error(error));
	}
}
