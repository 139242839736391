import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {IonicModule} from "@ionic/angular";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {ImageUploadModule} from "angular2-image-upload";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import * as fromMerchant from "./+state/index";
import {AddLocationsModal} from "./add-locations/add-locations.modal";
import {AppPage} from "./app/app.page";
import {CouponModal} from "./coupon/coupon.modal";
import {CouponsPage} from "./coupons/coupons.page";
import {DashboardPage} from "./dashboard/dashboard.page";
import {DisplayOffersPage} from "./display-offers/display-offers.page";
import {EmailOffersPage} from "./email-offers/email-offers.page";
import {LocationModal} from "./location/location.modal";
import {LocationsPage} from "./locations/locations.page";
import {ChooseMerchantModal} from "./merchants/choose-merchant.modal";
import {FloorPlanModal} from "./movebe-markers/floor-plan-modal/floor-plan.modal";
import {GenerateSignsPage} from "./movebe-markers/generate-signs/generate-signs.page";
import {GeotagSignsPage} from "./movebe-markers/geotag-signs/geotag-signs.page";
import {LocationDescriptorEditorComponent} from "./movebe-markers/location-descriptor-editor/location-descriptor-editor.component";
import {MovebeMarkersPage} from "./movebe-markers/movebe-markers.page";
import {UploadFloorPlanModal} from "./movebe-markers/upload-floor-plan-modal/upload-floor-plan.modal";
import {OfferPage} from "./offer/offer.page";
import {OffersPage} from "./offers/offers.page";
import {ProfilePage} from "./profile/profile.page";
import {JoinMerchantScanRequestReviewComponent} from "./scan-request/join-merchant-scan-request-review.component";
import {OfferScanRequestReviewComponent} from "./scan-request/offer-scan-request-review.component";
import {RewardScanRequestReviewComponent} from "./scan-request/reward-scan-request-review.component";
import {ScanRequestReviewPage} from "./scan-request/scan-request-review.page";
import {ScanPage} from "./scan/scan.page";
import {SmsOffersPage} from "./sms-offers/sms-offers.page";
import {UserInvitationPage} from "./user-invitation/user-invitation.page";
import {UserModal} from "./user/user.modal";
import {UsersPage} from "./users/users.page";

//tslint:disable:no-unsafe-any
@NgModule({
	declarations: [
		AppPage,
		ChooseMerchantModal,
		CouponModal,
		CouponsPage,
		DashboardPage,
		DisplayOffersPage,
		EmailOffersPage,
		AddLocationsModal,
		FloorPlanModal,
		GenerateSignsPage,
		GeotagSignsPage,
		LocationDescriptorEditorComponent,
		LocationModal,
		LocationsPage,
		MovebeMarkersPage,
		MovebeMarkersPage,
		OfferPage,
		OffersPage,
		ProfilePage,
		ScanPage,
		SmsOffersPage,
		UploadFloorPlanModal,
		UserInvitationPage,
		UserModal,
		UsersPage,
		ScanRequestReviewPage,
		OfferScanRequestReviewComponent,
		RewardScanRequestReviewComponent,
		JoinMerchantScanRequestReviewComponent,
	],
	entryComponents: [
		AppPage,
		ChooseMerchantModal,
		CouponModal,
		CouponsPage,
		DashboardPage,
		DisplayOffersPage,
		EmailOffersPage,
		AddLocationsModal,
		FloorPlanModal,
		GenerateSignsPage,
		GeotagSignsPage,
		LocationModal,
		LocationsPage,
		MovebeMarkersPage,
		OfferPage,
		OffersPage,
		ProfilePage,
		ScanPage,
		SmsOffersPage,
		UploadFloorPlanModal,
		UserInvitationPage,
		UserModal,
		UsersPage,
		ScanRequestReviewPage
	],
	exports: [
		AppPage,
		DashboardPage,
		DisplayOffersPage,
		AddLocationsModal,
		FloorPlanModal,
		GenerateSignsPage,
		GeotagSignsPage,
		LocationModal,
		LocationsPage,
		MovebeMarkersPage,
		OfferPage,
		OffersPage,
		ProfilePage,
		ScanPage,
		UploadFloorPlanModal,
		UserInvitationPage,
		UserModal,
		UsersPage,
		ScanRequestReviewPage,
	],
	imports: [
		FlexLayoutModule,
		ImageUploadModule.forRoot(),
		IonicModule,
		MovebeSharedModule,
		StoreModule.forFeature(fromMerchant.featureName, fromMerchant.reducer),
		EffectsModule.forFeature(fromMerchant.effects)
	],
	providers: []
})
export class MovebeMerchantsModule {
}
