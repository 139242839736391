import {combineLatest as observableCombineLatest, Observable, of as observableOf} from "rxjs";
import {map, switchMap} from "rxjs/operators";

export type ChildLookupFunction<ParentType, ChildType> = (ParentType) => Observable<ChildType>;

export type JoinFunction<ParentType, ChildType, HybridType> = (ParentType, ChildType) => HybridType;

export const childJoin
	: <ParentType, ChildType, HybridType> (
	childLookupFunction: ChildLookupFunction<ParentType, ChildType>,
	joinFunction: JoinFunction<ParentType, ChildType, HybridType>)
	=> (source: Observable<ParentType[]>)
	=> Observable<HybridType[]>
	= (childLookupFunction, joinFunction) => (source) => source
	.pipe(switchMap((parentItems) =>
			parentItems.length === 0
				? observableOf([])
				: observableCombineLatest(parentItems
					.map((parentItem) => childLookupFunction(parentItem)
						.pipe(
							map((childItem) => joinFunction(parentItem, childItem)))
					)
				)
		)
	);
