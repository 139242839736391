import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import {FormControl} from "ngx-strongly-typed-forms";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state";
import {Employee, MerchantRole, MerchantUserStatus} from "../../../../../lib/model/employment-relationship.model";
import {Merchant, SummaryFromMerchant} from "../../../../../lib/model/merchant.model";
import {MovebeState} from "../../../app/movebe-state.model";

import {MerchantsService} from "../../../core/merchants/merchants.service";
import {OffersService} from "../../../core/offers/offers.service";
import {
	JoinMerchantScanRequestParams,
	JoinMerchantScanRequestResponse
} from "../../../core/scan-request/scan-request-params.model";
import {ScanRequest, ScanRequestStatus} from "../../../core/scan-request/scan-request.model";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {ScanRequestReviewComponent} from "./scan-request-review-component.model";

@Component({
	selector: "movebe-join-merchant-scan-request-review",
	styleUrls: ["./join-merchant-scan-request-review.component.scss"],
	templateUrl: "./join-merchant-scan-request-review.component.html"
})
export class JoinMerchantScanRequestReviewComponent implements OnInit, ScanRequestReviewComponent {

	@Input() scanRequest: ScanRequest;
	@Output() isValidChanged = new EventEmitter<boolean>();
	@Output() merchantRoleSelected = new EventEmitter<MerchantRole>();

	ScanRequestStatus = ScanRequestStatus;
	MerchantRole = MerchantRole;
	requestParams: JoinMerchantScanRequestParams;
	merchantRole = new FormControl<MerchantRole>();
	readonly currentMerchant$: Observable<Merchant>;
	existingMember$: Observable<Employee | null>;

	constructor(private merchantsService: MerchantsService,
							private store: Store<MovebeState>,
							private offersService: OffersService) {

		this.currentMerchant$ = this.store.select(fromMerchant.getCurrentMerchant)
			.pipe(filterNulls());

		this.merchantRole.statusChanges.subscribe(status => {
			this.isValidChanged.emit(this.merchantRole.valid);
		});

	}

	ngOnInit(): void {
		this.isValidChanged.emit(false);
		this.requestParams = this.scanRequest.requestParams as JoinMerchantScanRequestParams;

		this.existingMember$ = this.currentMerchant$
			.switchMap(merchant => this.merchantsService.getEmployee(merchant.$key!, this.scanRequest.requestedByUserId));
	}

	approve(): Promise<JoinMerchantScanRequestResponse> {
		const employmentRelationship = {
			role: this.merchantRole.value,
			status: MerchantUserStatus.active,
			universal: true
		};
		return this.currentMerchant$
			.pipe(filterNulls())
			.first()
			.toPromise()
			.then(merchant =>
				this.merchantsService.setMerchantUserEmployment(merchant, {
						displayName: this.requestParams.name,
						key: this.scanRequest.requestedByUserId,
					},
					employmentRelationship
				)
					.then(() => ({
						employer: {
							employmentRelationship,
							merchant: SummaryFromMerchant(merchant),
						}
					}))
			);
	}

}
