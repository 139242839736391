import {Component, Input, OnInit} from "@angular/core";
import * as moment from "moment/moment";
import {Observable} from "rxjs/Observable";
import {Merchant} from "../../../../lib/model/merchant.model";
import {MerchantLocation} from "../../core/merchant/merchant-location.model";
import {MerchantsService} from "../../core/merchants/merchants.service";
import {collapseHorizontal, collapseVertical} from "../animations/animations";

@Component({
	animations: [
		collapseHorizontal,
		collapseVertical
	],
	selector: "movebe-merchant-location-profile",
	styleUrls: ["merchant-location-profile.component.scss"],
	templateUrl: "merchant-location-profile.component.html"
})
export class MerchantLocationProfileComponent implements OnInit {

	@Input() location: MerchantLocation;
	@Input() showProviders = true;
	merchant$: Observable<Merchant | null>;
	readonly currentDayOfWeek$: Observable<number>;
	showAllDays = false;

	constructor(private merchantsService: MerchantsService) {
		const minuteInMilliseconds = 60000;
		this.currentDayOfWeek$ = Observable
			.interval(minuteInMilliseconds)
			.startWith(0)
			.map(() => moment().day())
			.distinctUntilChanged();
	}

	ngOnInit(): void {
		this.merchant$ = this.merchantsService.getMerchant(this.location.merchantId);
	}

	toggleShowAllDays() {
		this.showAllDays = !this.showAllDays;
	}
}
