import {HttpClient, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import "rxjs/add/operator/map";
import {map, shareReplay} from "rxjs/operators";
import * as slug from "slug";
import {Disbursement} from "../../../../lib/model/disbursement.model";
import {Gateway} from "../../../../lib/model/gateway.model";
import {Listing} from "../../../../lib/model/listing.model";
import {Merchant} from "../../../../lib/model/merchant.model";
import {Provider} from "../../../../lib/model/provider.model";
import {Redemption} from "../../../../lib/model/redemption.model";
import {Settlement} from "../../../../lib/model/settlement.model";
import {SurveyQuestionResponse} from "../../../../lib/model/survey/survey-question-response.model";
import {Survey} from "../../../../lib/model/survey/survey.model";
import {UserProfile} from "../../../../lib/model/user/user-profile.model";
import {appEnvironment} from "../../app/app-environment";
import {MerchantLocation} from "../merchant/merchant-location.model";

@Injectable()
export class MovebeApiService {

//TODO: implement client api using https://github.com/2muchcoffeecom/ngx-restangular
	constructor(private http: HttpClient) {
	}

	cleanEnvironment() {
		return this.http.delete(this.apiUrl("data/clean"))
			.first()
			.toPromise();
	}

	deleteTransactions() {
		return this.http.delete(this.apiUrl("data/deleteTransactions"))
			.first()
			.toPromise();
	}

	getAuthToken(): Observable<string> {
		return this.http.get(this.apiUrl("auth/auth-token")).pipe(
			map((res: {customToken: string}) => res.customToken));
	}

	getAuthTokenForAuthId(authId: string): Observable<string> {
		return this.http.get(this.apiUrl(`auth/auth-token/${authId}`)).pipe(
			map((res: {customToken: string}) => res.customToken));
	}

	getMarkerSignPdf(howMany): Observable<Blob> {
		return this.http.get(this.apiUrl(`movebe-markers/sign-pdf/${howMany}`), {responseType: "blob"});
	}

	getMovebeRevenue() {
		return this.http.get(`${appEnvironment.apiUrl}/entries/movebeRevenue`)
			.first()
			.toPromise();
	}

	getRecentTransactions(limit: number) {
		return this.http.get(`${appEnvironment.apiUrl}/transactions/recent/`, {params: new HttpParams().set("limit", limit.toString())})
			.first()
			.toPromise();
	}

	getStakeholderBalances(stakeholderId: string) {
		return this.http.get(`${appEnvironment.apiUrl}/entries/balance/${stakeholderId}`).pipe(
			map(balances => balances[0] as {booked: number; currency_code: string; pending: number}))
			.first()
			.toPromise();
	}

	getStakeholdersBalances() {
		return this.http.get(`${appEnvironment.apiUrl}/entries/balances`)
			.first()
			.toPromise();
	}

	putDisbursement(disbursement: Disbursement) {
		return this.http.put(`${appEnvironment.apiUrl}/transactions/disbursement`, disbursement)
			.first()
			.toPromise();
	}

	putGateway(gateway: Gateway): Observable<string> {
		const id = slug(gateway.name, {lower: true}); //tslint:disable-line:no-unsafe-any
		return this.http.put(`${appEnvironment.apiUrl}/gateways/gateway/${id}`, gateway).pipe(
			map((res: {id: string}) => res.id));
	}

	putListing(listing: Listing) {
		return this.http.put(this.apiUrl("transactions/listing"), listing)
			.first()
			.toPromise();
	}

	putLocation(location: MerchantLocation): Promise<string> {
		return this.http.put(this.apiUrl("merchants/merchant-location"), location)
			.first()
			.map((res: {id: string}) => res.id)
			.toPromise();
	}

	putMerchant(merchant: Merchant): Observable<string> {
		return this.http.post(this.apiUrl("merchants/merchant"), merchant).pipe(
			map((res: {id: string}) => res.id));
	}

	putProvider(provider: Provider): Observable<string> {
		return this.http.put(this.apiUrl("providers/provider"), provider).pipe(
			map((res: {id: string}) => res.id));
	}

	putRedemption(redemption: Redemption): Promise<any> {
		return this.http.put(this.apiUrl("transactions/redemption"), redemption)
			.first()
			.toPromise();
	}

	putSettlement(settlement: Settlement) {
		return this.http.put(`${appEnvironment.apiUrl}/transactions/settlement`, settlement)
			.first()
			.toPromise();
	}

	putSurvey(survey: Survey) {
		return this.http.put(`${appEnvironment.apiUrl}/surveys/survey`, survey).pipe(
			map((res: {id: string}) => res.id));
	}

	putSurveyResponses(surveyResponses: SurveyQuestionResponse[]): Observable<any> {
		return this.http.put(`${appEnvironment.apiUrl}/surveys/surveyQuestionResponses`, surveyResponses)
			.pipe(
				shareReplay(1)
			);
	}

	putUser(user: UserProfile): Promise<Object> {
		return this.http.put(this.apiUrl(`users/user/${user.$key}`), user)
			.first()
			.toPromise();
	}

	sendAuthSMS(mobileNumber: string, email?: string, password?: string): Observable<any> {
		return this.http.post(this.apiUrl(`auth/send-auth-sms/${mobileNumber}`), {
			email,
			password,
		}, {responseType: "text"});
	}

	validateMarkerCode(markerCode): Observable<boolean> {
		return this.http
			.get(this.apiUrl(`movebe-markers/is-valid/${markerCode}`)).pipe(
				map((res: {isValid: boolean}, index) => res.isValid));
	}

	getImageServingUrl(storageFilePath: string): Observable<string> {
		const host = appEnvironment.firebaseConfig.storageBucket;
		const url = `https://${host}/image-url?image=${storageFilePath}&storageBucket=${host}`;
		return this.http.get(url).pipe(
			map((res: {url: string}) => res.url));
	}

	private apiUrl(path): string {
		return `${appEnvironment.apiUrl}/${path}`;
	}

}
