import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ItemSliding, ModalController} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {filter} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import {AppLink} from "../../../../lib/model/app-link.model";
import {AppLinkService} from "../../core/app-links/app-link.service";
import {EffectsService} from "../../core/effects/effects.service";
import {Logger} from "../../core/logger/logger.service";
import {ScanRequestParams, ScanRequestResponse} from "../../core/scan-request/scan-request-params.model";
import {
	ApprovedScanRequest,
	ScanRequest,
	ScanRequestAction,
	ScanRequestStatus
} from "../../core/scan-request/scan-request.model";
import {ScanRequestService} from "../../core/scan-request/scan-request.service";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {ScanRequestEnlargedModal} from "./scan-request-enlarged.modal";

@Component({
	selector: "movebe-scan-request",
	styleUrls: ["scan-request.component.scss"],
	templateUrl: "scan-request.component.html"
})
export class ScanRequestComponent implements OnInit, OnDestroy {

	ScanRequestStatus = ScanRequestStatus;

	private readonly done$ = new Subject<void>();
	request$: Observable<ScanRequest | null>;

	@Input() action: ScanRequestAction;
	@Input() instructionsMessage: string;
	@Input() params: ScanRequestParams;
	@Output() requestApproved = new EventEmitter<ScanRequestResponse>();

	constructor(private appLinkService: AppLinkService,
							private scanRequestService: ScanRequestService,
							private effects: EffectsService,
							private logger: Logger,
							private modalCtrl: ModalController) {
	}

	ngOnInit() {
		if (this.params) {
			this.request$ = this.scanRequestService
				.generateScanRequest(this.action, this.params)
				.takeUntil(this.done$);

			//delete this scan request from firebase when this component closes
			this.request$
				.last()
				.subscribe(scanRequest => {
					if (scanRequest) {
						this.scanRequestService
							.removeScanRequest(scanRequest.$key!)
							.catch(error => this.logger.error(error));
					}
				});

			this.request$.pipe(filterNulls())
				.filter((scanRequest) => scanRequest.status !== ScanRequestStatus.pending)
				.subscribe(() => {
						this.modalCtrl
							.dismiss()
							.catch(error => this.logger.error(error));
					}
				);

			this.request$
				.pipe(
					filterNulls(),
					filter((scanRequest) => scanRequest.status === ScanRequestStatus.approved)
				)
				.subscribe((scanRequest: ApprovedScanRequest) => {
					this.effects.approve();
					this.requestApproved.emit(scanRequest.responseData);
				});
		}
	}

	enlargeScanRequest() {
		this.modalCtrl.create({
			component: ScanRequestEnlargedModal,
			componentProps: {
				instructionsMessage: this.instructionsMessage,
				scanRequest$:
				this.request$
			}
		})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}

	/*tslint:disable:member-ordering*/
	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	getAppLinkUrl(appLink: AppLink) {
		return this.appLinkService.generateAppLinkUrl(appLink);
	}

	closeSlidingItem(slidingItem: ItemSliding) {
		slidingItem.close();
	}

}
