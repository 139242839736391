export enum BarCodeType {
	qr = "QR",
	code39 = "CODE39",
	code128 = "CODE128",
}

export namespace BarCodeType {
	export function values() {
		return Object.keys(BarCodeType)
			.filter(type => isNaN(type as any) && type !== "values")
			.map(type => BarCodeType[type] as string);
	}
}
