import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {ElasticModule} from "ng-elastic";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import * as fromUser from "./+state";
import {AccountModal} from "./account/account.modal";
import {AddEmailModal} from "./add-email/add-email.modal";
import {AppModeChooserComponent} from "./app-mode-chooser/app-mode-chooser.component";
import {LanguageChooserComponent} from "./language-chooser/language-chooser.component";
import {PartnerModeChooserComponent} from "./partner-mode-chooser/partner-mode-chooser.component";
import {SignInModal} from "./sign-in/sign-in.modal";
import {VerifyEmailModal} from "./verify-email/verify-email.modal";

@NgModule({
	declarations: [
		AccountModal,
		AddEmailModal,
		AppModeChooserComponent,
		PartnerModeChooserComponent,
		LanguageChooserComponent,
		SignInModal,
		VerifyEmailModal,
	],
	entryComponents: [
		AccountModal,
		AddEmailModal,
		SignInModal,
		VerifyEmailModal,
	],
	exports: [
		AccountModal,
		AddEmailModal,
		AppModeChooserComponent,
		LanguageChooserComponent,
		PartnerModeChooserComponent,
		SignInModal,
		VerifyEmailModal,
	],
	imports: [
		CommonModule,
		FormsModule,
		ElasticModule,
		MovebeSharedModule,
		StoreModule.forFeature(fromUser.featureName, fromUser.reducer),
		EffectsModule.forFeature(fromUser.effects),
	],
	providers: []
})

export class UserModule {
}
