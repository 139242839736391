import {ChangeDetectorRef, Component, OnDestroy} from "@angular/core";
import {AlertController, ModalController} from "@ionic/angular";
import {Actions} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {Observable} from "rxjs/Observable";
import * as fromUser from "../+state";
import {UserActions} from "../+state/user.actions";
import {Employer, MerchantRole, MerchantUserStatus} from "../../../../../lib/model/employment-relationship.model";
import {Language} from "../../../../../lib/model/language.model";
import {Settings} from "../../../../../lib/model/settings.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {ImportPage} from "../../../app-modes/admin/import/import.page";
import * as fromMerchant from "../../../app-modes/merchant/+state";
import {AppInfoPage} from "../../../app/app-info/app-info.page";
import {MovebeState} from "../../../app/movebe-state.model";
import {AppMode} from "../../../core/app-mode/app-mode.model";
import {BusyService} from "../../../core/busy/busy.service";
import {DataImporterService} from "../../../core/data/data-importer.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {NavService} from "../../../core/nav/nav.service";
import {SettingsService} from "../../../core/settings/settings.service";
import {CurrentUserService} from "../../../core/user/current-user.service";
import {ChatPage} from "../../chat/chat/chat.page";
import {MerchantMembershipActions} from "../../merchant-membership/+state/merchant-membership.actions";
import {JoinExistingMerchantModal} from "../../merchant-membership/join-existing-merchant/join-existing-merchant.modal";
import {AddEmailModal} from "../add-email/add-email.modal";
import {SignInModal} from "../sign-in/sign-in.modal";
import {VerifyEmailModal} from "../verify-email/verify-email.modal";

@Component({
	selector: "page-mb-account",
	templateUrl: "./account.modal.html"
})
export class AccountModal implements OnDestroy {
	async: any;
	email: string;
	readonly userProfile$: Observable<UserProfile>;
	readonly isUserSignedIn$: Observable<boolean>;
	readonly isUserAccountLinkedWithEmail$: Observable<boolean>;
	readonly isUserEmailVerified$: Observable<boolean>;
	readonly done$ = new Subject<void>();
	employers$: Observable<Employer[]>;
	modal: any;
	password: string;
	scanning = false;
	AppMode = AppMode;
	readonly settings$: Observable<Settings | null>;
	readonly supportedLanguages = Object.keys(Language);

	constructor(private actions$: Actions,
							private alertCtrl: AlertController,
							private busyService: BusyService,
							private currentUserService: CurrentUserService,
							private dataImporterService: DataImporterService,
							private logger: Logger,
							private merchantsService: MerchantsService,
							private modalCtrl: PromiseModalController,
							private navService: NavService,
							private modalController: ModalController,
							private ref: ChangeDetectorRef,
							private settingsService: SettingsService,
							private store: Store<MovebeState>,
							private translate: TranslateService) {

		this.employers$ = this.store.select(fromMerchant.getEmployers);
		this.userProfile$ = store.select(fromUser.getUserProfile);
		this.isUserSignedIn$ = store.select(fromUser.getIsUserSignedIn);
		this.isUserAccountLinkedWithEmail$ = store.select(fromUser.getIsUserAccountLinkedWithEmail);
		this.isUserEmailVerified$ = store.select(fromUser.getIsUserEmailVerified);
		this.settings$ = this.settingsService.getSettings();

		const refreshIntervalMilliseconds = 10000;
		Observable.combineLatest(this.isUserAccountLinkedWithEmail$, this.isUserEmailVerified$)
			.switchMap(([isUserAccountLinkedWithEmail, isUserEmailVerified]) =>
				(isUserAccountLinkedWithEmail && !isUserEmailVerified)
					? Observable.timer(0, refreshIntervalMilliseconds)
					: Observable.empty()
			)
			.takeUntil(this.done$)
			.subscribe(() => {
				return this.refreshAuth();
			});

		this.isUserEmailVerified$
			.pairwise()
			.filter(([wasEmailVerifiedBefore, isEmailVerified]) => isEmailVerified && !wasEmailVerifiedBefore)
			.takeUntil(this.done$)
			.subscribe(() => this.alertCtrl
				.create({
					buttons: [this.translate.instant("BUTTONS.OK") as string],
					header: this.translate.instant("AUTH.EMAIL_VERIFIED") as string,
					subHeader: this.translate.instant("AUTH.THANK_YOU_FOR_VERIFYING_EMAIL") as string,
				})
				.then(alert => alert.present())
			);

	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	refreshAuth() {
		this.store.dispatch(new UserActions.ReloadAuthState());
	}

	appModeSelected(chosenAppMode) {
		this.employers$.first().subscribe(employers => {
			if (chosenAppMode === AppMode.merchant && (!employers || employers.length === 0)) {
				this.signUpAsMerchant();
			} else {
				this.dismiss();
			}
		});
	}

	dismiss() {
		this.modalController.dismiss()
			.catch(error => this.logger.error(error));
	}

	appInfo() {
		this.navService.navCtrl
			.push(AppInfoPage)
			.then(() => this.modalController.dismiss())
			.catch(error => this.logger.error(error));
	}

	openSupportPage() {
		this.navService.navCtrl
			.push(ChatPage)
			.then(() => this.modalController.dismiss())
			.catch(error => this.logger.error(error));
	}

	presentSignInModal() {
		this.modalCtrl
			.presentModal(SignInModal)
			.catch(error => this.logger.log(error));
	}

	presentAddEmailModal() {
		this.modalCtrl
			.presentModal(AddEmailModal)
			.catch(error => this.logger.log(error));
	}

	presentImportModal() {
		this.navService.navCtrl
			.push(ImportPage)
			.then(() => this.modalController.dismiss())
			.catch(error => this.logger.log(error));
	}

	joinExistingMerchant() {
		this.navService.navCtrl
			.push(JoinExistingMerchantModal)
			.then(() => this.modalController.dismiss())
			.catch(error => this.logger.error(error));
	}

	signUpAsMerchant() {
		this.store.dispatch(new MerchantMembershipActions.BecomeMerchant());
	}

	emailClicked() {
		this.isUserEmailVerified$
			.first()
			.toPromise()
			.then(isEmailVerified => (isEmailVerified
					? this.alertCtrl
						.create({
							buttons: [{
								text: this.translate.instant("BUTTONS.CANCEL") as string
							}, {
								handler: () => this.unlinkPasswordProvider(),
								text: this.translate.instant("AUTH.UNLINK") as string
							}],
							header: this.translate.instant("AUTH.UNLINK_EMAIL_QUESTION") as string,
							message: this.translate.instant("AUTH.ARE_YOU_SURE_UNLINK_EMAIL") as string,
						})
						.then(alert => alert.present())
					: Promise.resolve(this.refreshAuth())
						.then(() => this.modalCtrl.presentModal(VerifyEmailModal))
			) as Promise<any>)
			.catch(error => this.logger.error(error));
	}

	unlinkPasswordProvider() {
		this.busyService.setBusy(this.currentUserService.unlinkPasswordProvider());
	}

	signOut() {
		this.store.dispatch(new UserActions.SignOut());
	}

	manageAllMerchants() {
		this.busyService.setBusy(new Promise((resolve, reject) => {
			this.merchantsService.getMerchants()
				.first()
				.subscribe(merchants => {
					merchants.forEach(merchant =>
						//TODO: move this into merchantsService & harmonize with same button on importer page
						this.dataImporterService.addMerchantUser(merchant, {
							role: MerchantRole.manager,
							status: MerchantUserStatus.active,
							universal: true
						})
					);
					resolve();

					this.alertCtrl
						.create({
							buttons: [
								{text: this.translate.instant("BUTTONS.OK") as string}
							],
							message: this.translate.instant("ADMIN.YOU_HAVE_BEEN_MADE_MANAGER") as string
						})
						.then(alert => alert.present())
						.catch(error => this.logger.error(error));
				});
		}));
	}

}
