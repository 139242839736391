import {createFeatureSelector, createSelector} from "@ngrx/store";
import {UserEffects} from "./user.effects";
import * as fromUserStore from "./user.reducer";
import {UserState} from "./user.reducer";

export const featureName = "user";

export interface FeatureState {
	user: UserState; //the name of this property must match the value of featureName const above
}

export const reducer = fromUserStore.userReducer;
export const effects = [UserEffects];

export const getUserState = createFeatureSelector<UserState>(featureName);

export const getUserAuthState = createSelector(getUserState, fromUserStore.getAuthState);
export const getUserAuthAppLinkEvent = createSelector(getUserState, fromUserStore.getAuthAppLinkEvent);
export const getUserProfile = createSelector(getUserState, fromUserStore.getProfile);
export const getIsUserAccountLinkedWithEmail = createSelector(getUserState, fromUserStore.getIsUserAccountLinkedWithEmail);
export const getIsUserEmailVerified = createSelector(getUserState, fromUserStore.getIsUserEmailVerified);
export const getIsUserSignedIn = createSelector(getUserState, fromUserStore.getIsUserSignedIn);
export const getLanguage = createSelector(getUserState, fromUserStore.getLanguage);
export const getPartnerMode = createSelector(getUserState, fromUserStore.getPartnerMode);
export const getUserId = createSelector(getUserState, fromUserStore.getUserId);
export const getCurrentMerchant = createSelector(getUserState, fromUserStore.getCurrentMerchant);
