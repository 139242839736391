import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BenefitType} from "../../../../lib/model/benefit.model";
import {GeoQuery} from "../../lib/geo-query/geo-query";
import {GeoQueryCriteria} from "../../lib/geo-query/geo-query-criteria.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {OffersService} from "../offers/offers.service";
import {RewardSearchFilter} from "../rewards/reward-search.model";
import {RewardsService} from "../rewards/rewards.service";
import {MerchantLocationOffers} from "./merchant-location-offers.model";

@Injectable()
export class OfferSearchService {

	private geoQuery: GeoQuery<MerchantLocationOffers>;

	constructor(private fb: FirebaseService,
							private firestore: FirestoreService,
							private offersService: OffersService,
							private rewardsService: RewardsService) {
	}

	initializeOfferSearch(queryCriteria: GeoQueryCriteria): Observable<MerchantLocationOffers[]> {
		this.geoQuery = this.fb.searchMerchantLocations(
			queryCriteria,
			item => {
				const [merchantId, locationId] = (item.key).split("|");
				return this.getMerchantLocationOffers(merchantId, locationId);
			}
		);
		return this.geoQuery.results$;
	}

	updateSearchCriteria(newQueryCriteria: GeoQueryCriteria): any {
		if (!this.geoQuery) {
			throw new Error("Must initialize offer search before updating search criteria ");
		}
		return this.geoQuery.updateQueryCriteria(newQueryCriteria);
	}

	private getMerchantLocationOffers(merchantId: string, locationId: string): Observable<MerchantLocationOffers> {

		return Observable.combineLatest(
			this.firestore.toObjectStream(this.firestore.getMerchantLocation(merchantId, locationId)).pipe(filterNulls()),
			this.firestore.toObjectStream(this.firestore.getMerchant(merchantId)).pipe(filterNulls()),
			this.offersService.getVisibleOffers(merchantId),
			this.rewardsService.getCurrentUserRewards({
				benefitType: BenefitType.coupon,
				filter: RewardSearchFilter.current,
				merchantId
			})
		)
			.map(([location, merchant, offers, rewards]) => ({location, merchant, offers, rewards}));
	}

}
