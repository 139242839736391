import {GatewayAccount} from "../../../../lib/model/gateway-account.model";
import {Provider, ProviderType} from "../../../../lib/model/provider.model";

const gatewayAccounts: GatewayAccount[] = [
	{gatewayId: "cash"},
	{gatewayId: "cheque"},
	{gatewayId: "eft"},
	{gatewayId: "other"},
	{gatewayId: "paypal",	number: "12345678"},
];

export const providersData: Partial<Provider>[] = [
	{currency: "CAD", flatFee: 0.45, name: "App Park", percentFee: 3.5, type: ProviderType.parkingApp, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "City Bike", percentFee: 3.5, type: ProviderType.bikeShare, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Coast Connections", percentFee: 3.5, type: ProviderType.ferry, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Future Fuel", percentFee: 3.5, type: ProviderType.fuel, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Lot Buddy", percentFee: 3.5, type: ProviderType.parkingApp, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Parkmate", percentFee: 3.5, type: ProviderType.parkingApp, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Paygo", percentFee: 3.5, type: ProviderType.parkingApp, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Payment Kiosk", percentFee: 3.5, type: ProviderType.parkingKiosk, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Taxi Call", percentFee: 3.5, type: ProviderType.cab, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Toll Pass", percentFee: 3.5, type: ProviderType.toll, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Transit Pass", percentFee: 3.5, type: ProviderType.transit, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Zip Fuel", percentFee: 3.5, type: ProviderType.fuel, gatewayAccounts},
	{currency: "CAD", flatFee: 0.45, name: "Zoom Toll", percentFee: 3.5, type: ProviderType.toll, gatewayAccounts}
];
