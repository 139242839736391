import {Component} from "@angular/core";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalController, NavParams} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state";
import {BenefitType, Coupon} from "../../../../../lib/model/benefit.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {BarCodeType} from "../../../core/coupon/coupon.model";
import {Logger} from "../../../core/logger/logger.service";
import {OffersService} from "../../../core/offers/offers.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";

@Component({
	selector: "movebe-merchants-coupon",
	styleUrls: ["./coupon.modal.scss"],
	templateUrl: "./coupon.modal.html"
})
export class CouponModal {

	readonly defaultBarCodeValue = "000000000";
	readonly currentMerchantId$: Observable<string>;
	barCodeTypes = BarCodeType;
	coupon: Coupon;

	couponForm: FormGroup;

	readonly newCoupon: Coupon = {
		barCodeType: BarCodeType.code39,
		barCodeValue: "",
		description: "",
		title: "",
		type: BenefitType.coupon,
	};

	get barCodeType(): AbstractControl {
		return this.couponForm.get("barCodeType")!;
	}

	get barCodeValue(): AbstractControl {
		return this.couponForm.get("barCodeValue")!;
	}

	get description(): AbstractControl {
		return this.couponForm.get("description")!;
	}

	get title(): AbstractControl {
		return this.couponForm.get("title")!;
	}

	constructor(private busyService: BusyService,
							private navParams: NavParams,
							private offersService: OffersService,
							private logger: Logger,
							private store: Store<MovebeState>,
							private modalController: ModalController,
							private formBuilder: FormBuilder) {
		this.currentMerchantId$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchantId),
			filterNulls()
		);
		this.coupon = (this.navParams.get("coupon") || this.newCoupon) as Coupon;

		this.couponForm = formBuilder.group({
			barCodeType: [this.coupon.barCodeType, Validators.required],
			barCodeValue: [this.coupon.barCodeValue, Validators.required],
			description: [this.coupon.description, Validators.required],
			title: [this.coupon.title, Validators.required],
		});
	}

	saveCoupon() {
		Object.assign(this.coupon, this.couponForm.value as Coupon);

		const busyPromise = this.currentMerchantId$.first()
			.toPromise()
			.then(
				merchantId => (this.coupon.$key
						? this.offersService.updateCoupon(merchantId, this.coupon)
						: this.offersService.addCoupon(merchantId, this.coupon)
				) as Promise<any>
			)
			.then(() => this.modalController.dismiss());

		this.busyService.setBusy(busyPromise);
	}

}
