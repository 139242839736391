import {Directive, HostBinding, Input, OnChanges, OnDestroy} from "@angular/core";
import {Subject} from "rxjs/Subject";
import {Provider} from "../../../../lib/model/provider.model";
import {ProvidersService} from "../../core/providers/providers.service";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";

@Directive({
	selector: "img[movebeProviderLogo]"
})

export class ProviderLogoDirective implements OnChanges, OnDestroy {

	readonly BLANK_IMAGE = "assets/img/1x1.transparent.png";

	@HostBinding("style.height") height = "auto";
	@HostBinding("style.width") width = "auto";
	@HostBinding("src") src = this.BLANK_IMAGE;

	@Input() imageSize: number;
	@Input() provider: Provider;
	@Input() providerId: string;

	private done$ = new Subject<void>();

	constructor(private providers: ProvidersService) {
	}

	ngOnChanges() {
		this.height = `${this.imageSize}.px`;
		this.width = `${this.imageSize}.px`;
		this.src = (this.provider && this.provider.logoUrl)
			? this.getSizedLogoUrl(this.provider)
			: this.BLANK_IMAGE;

		if (this.providerId) {
			this.providers.get(this.providerId)
				.takeUntil(this.done$)
				.pipe(filterNulls())
				.filter(provider => !!provider.logoUrl)
				.subscribe(provider => this.src = this.getSizedLogoUrl(provider));
		}
	}

	getSizedLogoUrl(provider: Provider) {
		return `${provider.logoUrl}=s${this.imageSize}`;
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

}
