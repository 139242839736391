import {Component, OnInit, ViewChild} from "@angular/core";
import {Content, ModalController, NavParams, ToastController} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import {BenefitType} from "../../../../../lib/model/benefit.model";
import {Offer} from "../../../../../lib/model/offer.model";
import {Reward} from "../../../../../lib/model/reward.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {EffectsService} from "../../../core/effects/effects.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {Duration} from "../../../core/misc/duration.model";
import {OffersService} from "../../../core/offers/offers.service";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {OfferScanRequestParams, OfferScanRequestResponse} from "../../../core/scan-request/scan-request-params.model";
import {ScanRequestAction} from "../../../core/scan-request/scan-request.model";
import * as fromUser from "../../../lib/user/+state";
import {SignInModal} from "../../../lib/user/sign-in/sign-in.modal";

@Component({
	selector: "page-mb-offer",
	styleUrls: ["./offer.page.scss"],
	templateUrl: "./offer.page.html"
})
export class OfferPage implements OnInit {
	ScanRequestAction = ScanRequestAction;
	benefit: any;
	@ViewChild("content") content: Content;
	footerHidden = false;
	readonly isUserSignedIn$: Observable<boolean>;
	location: MerchantLocation;
	offer: Offer;
	reward$: Observable<Reward | null>;
	scanRequestParams: OfferScanRequestParams;
	voucher: any;

	constructor(private effects: EffectsService,
							private logger: Logger,
							private modalCtrl: ModalController,
							private navParams: NavParams,
							private offersService: OffersService,
							private rewardsService: RewardsService,
							private store: Store<MovebeState>,
							private translate: TranslateService,
							private toastCtrl: ToastController) {
		this.isUserSignedIn$ = this.store.pipe(select(fromUser.getIsUserSignedIn));
		this.location = this.navParams.get("location") as MerchantLocation;
		this.offer = this.navParams.get("offer") as Offer;
		this.scanRequestParams = {
			locationId: this.location.$key!,
			merchantId: this.location.merchantId,
			offerId: this.offer.$key!,
		};

	}

	ngOnInit() {
		(this.offer.benefit.type === BenefitType.coupon
				? this.offersService.getCoupon(this.location.merchantId, this.offer.benefit.key)
				: this.offersService.getBenefit(this.offer.benefit.key)
		)
			.first()
			.subscribe(benefit => {
				this.benefit = benefit;
			});

	}

	rewardIssued(scanResponse: OfferScanRequestResponse) {
		this.reward$ = this.rewardsService.getReward(scanResponse.rewardId);
		this.effects.reward();
		this.hideFooter();
		this.toastCtrl.create({
			duration: 3000,
			message: `${this.translate.instant("SCAN.REQUEST_VALIDATED") as string}\n${this.translate.instant("SCAN.YOU_HAVE", {timeframe: this.getTimeframe(this.offer.expires)}) as string}`,
			position: "middle"
		})
			.then(toast => toast.present())
			.catch(error => this.logger.error(error));
	}

	getTimeframe(duration: Duration): string {
		if (duration.hours) {
			return `${duration.hours} hours`;
		}
		if (duration.days) {
			return `${duration.days} days`;
		}
		if (duration.minutes) {
			return `${duration.minutes} minutes`;
		}
		if (duration.weeks) {
			return `${duration.weeks} weeks`;
		}
		if (duration.months) {
			return `${duration.months} months`;
		}
		if (duration.years) {
			return `${duration.years} years`;
		}
		return "";
	}

	hideFooter() {
		this.footerHidden = true;
	}

	signIn() {
		this.modalCtrl
			.create({component: SignInModal})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}
}
