import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Content, ModalController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {Subject} from "rxjs/Subject";
import * as fromMerchant from "../../+state/index";
import {MovebeState} from "../../../../app/movebe-state.model";
import {Logger} from "../../../../core/logger/logger.service";
import {MerchantLocation} from "../../../../core/merchant/merchant-location.model";
import {FloorPlan} from "../../../../core/movebe-markers/floor-plan.model";
import {MarkerCodeGeotag} from "../../../../core/movebe-markers/marker-code-geotag.model";
import {MarkerDescriptor} from "../../../../core/movebe-markers/marker-descriptor.model";
import {MovebeMarkersService} from "../../../../core/movebe-markers/movebe-markers.service";
import {FloorPlanModal} from "../floor-plan-modal/floor-plan.modal";
import {MarkerEditMode} from "./marker-edit-mode.model";

@Component({
	selector: "page-mm-geotag-signs",
	styleUrls: ["./geotag-signs.page.scss"],
	templateUrl: "./geotag-signs.page.html",
})
export class GeotagSignsPage implements OnInit, OnDestroy {

	MarkerEditMode = MarkerEditMode;

	async: any;
	readonly currentLocation$: Observable<MerchantLocation | null>;
	private readonly done$ = new Subject<void>();
	readonly filterIsValidMarkerCode$: Observable<boolean>;
	readonly floorPlans$: Observable<FloorPlan[]>;
	readonly locations$: Observable<MerchantLocation[]>;
	readonly markerCodeSearchString$ = new BehaviorSubject<string>("");
	readonly markerCodesFiltered$: Observable<MarkerCodeGeotag[]>;
	readonly markerCodes$: Observable<MarkerCodeGeotag[]>;
	readonly markerDescriptors$: Observable<MarkerDescriptor[]>;
	markerCodeSearchString: string;
	mode = MarkerEditMode.markers;
	readonly selectedLocation$ = new BehaviorSubject<MerchantLocation | null>(null);

	@ViewChild("content") content: Content;

	constructor(private logger: Logger,
							private modalCtrl: ModalController,
							private movebeMarkersService: MovebeMarkersService,
							private navParams: NavParams,
							private store: Store<MovebeState>) {

		this.currentLocation$ = this.selectedLocation$
			.filter(location => location !== null);

		this.markerCodes$ = this.currentLocation$
			.flatMap(location => this.movebeMarkersService.getMarkerCodeGeotags(location!.merchantId, location!.$key!));

		this.markerCodesFiltered$ = Observable.combineLatest(
			this.markerCodes$, this.markerCodeSearchString$, (markerCodes, filter) =>
				markerCodes.filter(markerCode => markerCode.code
					.toLowerCase()
					.startsWith((filter || "").toLowerCase()))
		);

		this.filterIsValidMarkerCode$ = this.markerCodeSearchString$
			.flatMap(filter => this.movebeMarkersService.validateMarkerCode(filter));

		this.markerDescriptors$ = this.currentLocation$
			.flatMap(location =>
				location!.$key ?
					this.movebeMarkersService.getMarkerDescriptors(location!.$key!)
					: []
			);

		this.floorPlans$ = this.currentLocation$
			.flatMap(location =>
				location!.$key ?
					this.movebeMarkersService.getFloorPlans(location!.merchantId, location!.$key!)
					: []
			);

		this.locations$ = this.store.select(fromMerchant.getMerchantLocations);
	}

	ngOnInit(): void {

		const initialMarkerCode: string = this.navParams.get("markerCode") as string;

		if (initialMarkerCode) {
			this.markerCodeSearchString = initialMarkerCode;
			this.markerCodeSearchString$.next(initialMarkerCode);
			this.movebeMarkersService
				.getMarkerData(initialMarkerCode) //see if this marker has already been set up
				.first()
				.subscribe(  //if marker data exists, automatically select that location
					({merchantLocation, markerData}) => {
						this.selectedLocation$.next(merchantLocation);
					},
					error => { //if no marker data exists, wait for user to select a location and then add the marker to that location
						this.currentLocation$
							.first()
							.subscribe(merchantLocation => {
								this.addMarkerCode(initialMarkerCode);
							});
					}
				);
		}

		// this.markerDescriptors$
		// 	.filter(markerDescriptors => markerDescriptors.length === 0)
		// 	.takeUntil(this.done$)
		// 	.subscribe(markerDescriptors => {
		// 	TODO: Figure out a good UI to prompt user to create descriptors if none exist for current lot or allow user to add descriptors right from here
		// 	});

	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	setDescriptorValue(markerCode: string, descriptor: string, value: string) {
		this.movebeMarkersService.setMarkerCodeDescriptorValue(this.selectedLocation$.value!.$key!, markerCode, descriptor, value);
	}

	async setFloorPlanLocation(markerCode: MarkerCodeGeotag) {
		const floorPlanModal = await this.modalCtrl
			.create({
				component: FloorPlanModal,
				componentProps: {
					location: this.selectedLocation$.value,
					markerCode
				}
			});
		floorPlanModal
			.onDidDismiss()
			.then(detail => {
				const floorPlanPlacement = detail.data;
				if (floorPlanPlacement) {
					this.movebeMarkersService.setMarkerCodeFloorPlanPlacement(markerCode.$key!, floorPlanPlacement);
				}
			})
			.catch(error => this.logger.error(error));
		floorPlanModal.present().catch(error => this.logger.error(error));
	}

	addMarkerCode(newMarkerCode: string) {
		const selectedLocation = this.selectedLocation$.value!;
		this.movebeMarkersService.addMarkerCode(selectedLocation.merchantId, selectedLocation.$key!, newMarkerCode || this.markerCodeSearchString$.value);
	}

	setLocation(location: MerchantLocation) {
		this.selectedLocation$.next(location);
	}
}
