import {FirebaseEntity} from "./firebase-entity.model";
import {Language} from "./language.model";
import {OfferSummary} from "./offer.model";
import {Summary} from "./summary.type";

export enum MerchantStatus {
	approved = "approved",
	disabled = "disabled",
	pending = "pending",
	rejected = "rejected"
}

export interface WebLink {
	label: string;
	url: string;
}

export interface Merchant extends FirebaseEntity {
	barCodeType?: string;
	country?: string;
	createdBy?: string;
	currency: string;
	description: string;
	displayNames?: any;
	flatFee?: number;
	homepage: string;
	id?: string;
	languages?: Language[];
	logoFilename?: string;
	logoUrl?: string;
	name: string;
	nextLocation?: number;
	percentFee?: number;
	primaryLanguage?: Language;
	providers?: string[];
	referringPartner: string;
	status: MerchantStatus;
	survey?: string;
	telephone: string;
	type: string;
	visibleOffers?: OfferSummary[];
	webLinks?: WebLink[];
}

export type MerchantSummary = Summary<Merchant, "name" | "status">;

export function SummaryFromMerchant(merchant: Merchant): MerchantSummary {
	return {
		key: merchant.$key!, //tslint:disable-line:no-unnecessary-type-assertion
		name: merchant.name,
		status: merchant.status,
	};
}
