import {animate, style, transition, trigger} from "@angular/animations";

export const collapseHorizontal = trigger("collapseHorizontal", [
	transition(":enter", [
		style({height: 0, opacity: 0, overflow: "hidden"}),
		animate("250ms", style({height: "*", opacity: 1}))
	]),
	transition(":leave", [
		style({height: "*", opacity: 1, overflow: "hidden"}),
		animate("250ms", style({height: 0, opacity: 0}))
	]),
]);

export const collapseVertical = trigger("collapseVertical", [
	transition(":enter", [
		style({width: 0, opacity: 0, overflow: "hidden"}),
		animate("250ms", style({width: "*", opacity: 1}))
	]),
	transition(":leave", [
		style({width: "*", opacity: 1, overflow: "hidden"}),
		animate("250ms", style({width: 0, opacity: 0}))
	])
]);

export const fade = trigger("fade", [
	transition(":enter", [
		style({opacity: 0}),
		animate("250ms", style({opacity: 1}))
	]),
	transition(":leave", [
		style({opacity: 1}),
		animate("250ms", style({opacity: 0}))
	])
]);
