import {Inject, Injectable} from "@angular/core";
import {FirebaseApp} from "angularfire2";
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from "angularfire2/firestore";
import {QueryFn} from "angularfire2/firestore/interfaces";
/*tslint:disable:member-ordering*/
import * as firebase from "firebase/app";
import "firebase/storage";
import {mimeTypes} from "mime-wrapper";
import * as randomString from "randomstring";
import {Observable} from "rxjs";
import "rxjs/add/operator/map";
import * as slug from "slug";
import {Benefit, Coupon} from "../../../../lib/model/benefit.model";
import {ChatMessage} from "../../../../lib/model/chat/chat-message.model";
import {Chat} from "../../../../lib/model/chat/chat.model";
import {Employee, Employer} from "../../../../lib/model/employment-relationship.model";
import {FirebaseEntity} from "../../../../lib/model/firebase-entity.model";
import {Gateway} from "../../../../lib/model/gateway.model";
import {StakeholderBalances} from "../../../../lib/model/merchant-balance.model";
import {Merchant} from "../../../../lib/model/merchant.model";
import {Offer} from "../../../../lib/model/offer.model";
import {Provider} from "../../../../lib/model/provider.model";
import {Reward} from "../../../../lib/model/reward.model";
import {Settings} from "../../../../lib/model/settings.model";
import {Survey} from "../../../../lib/model/survey/survey.model";
import {UserProfile} from "../../../../lib/model/user/user-profile.model";
import {Logger} from "../logger/logger.service";
import {MerchantLocation} from "../merchant/merchant-location.model";
import {MarkerCodeGeotag} from "../movebe-markers/marker-code-geotag.model";
import {MarkerDescriptor} from "../movebe-markers/marker-descriptor.model";
import {ScanRequest} from "../scan-request/scan-request.model";
import CollectionReference = firebase.firestore.CollectionReference;
import Transaction = firebase.firestore.Transaction;

@Injectable()
export class FirestoreService {
	constructor(private afs: AngularFirestore,
							private logger: Logger,
							@Inject(FirebaseApp) private firebaseApp: firebase.app.App) {
		afs.firestore.settings({timestampsInSnapshots: true});
	}

	init(): Promise<any> {
		return Promise.resolve();
	}

	serverTimestamp(): firebase.firestore.Timestamp {
		return firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
	}

	getAuthToken(): Promise<any> {
		return this.firebaseApp.auth().currentUser!.getIdToken(false);
	}

	runTransaction<T>(fn: (transaction: Transaction) => Promise<T>): Promise<T> {
		return this.afs.firestore.runTransaction<T>(fn);
	}

	generateId(name: string) {
		const prefix = slug(name, {lower: true});	//tslint:disable-line:no-unsafe-any
		const suffix = randomString.generate({length: 5, capitalization: "lowercase"}); //tslint:disable-line:no-unsafe-any
		return `${prefix}-${suffix}`;
	}

	getStorageReference(path: string): firebase.storage.Reference {
		return this.firebaseApp.storage()
			.ref(path);
	}

	//TODO: move this into an ngrx effect
	uploadProviderLogo(provider: Provider, blob: Blob): firebase.storage.UploadTask {
		const fileExtension = mimeTypes.getExtension(blob.type); //tslint:disable-line:no-unsafe-any
		if (provider.logoFilename) {
			const oldLogoRef = this.getStorageReference(`provider-logo/${provider.id}/${provider.logoFilename}`);
			oldLogoRef.delete().catch(error => this.logger.error(error));
		}
		const logoSlug = this.generateId("provider-logo");
		const logoFilename = `${logoSlug}.${fileExtension}`;
		this.getProvider(provider.id!).update({logoFilename}).catch(error => this.logger.error(error));
		return this.getStorageReference(`provider-logo/${provider.id}/${logoFilename}`)
			.put(blob);
	}

	toObjectStream<T extends FirebaseEntity>(angularFirestoreDocument: AngularFirestoreDocument<T>): Observable<T | null> {
		return angularFirestoreDocument.snapshotChanges()
			.map(action => {
				if (!action.payload.exists) {
					return null;
				}
				return this.addNonenumerableKeyAndRef(action.payload.id, action.payload.ref, action.payload.data());
			})
			.shareReplay(1); //make the observable hot
	}

	toListStream<T extends FirebaseEntity>(angularFirestoreCollection: AngularFirestoreCollection<T>): Observable<T[]> {
		return angularFirestoreCollection.snapshotChanges()
			.map(actions => {
				return actions.map(action => {
					return this.addNonenumerableKeyAndRef(action.payload.doc.id, action.payload.doc.ref, action.payload.doc.data());
				});
			})
			.shareReplay(1); //make the observable hot
	}

	addNonenumerableKeyAndRef($key, $ref, data) {
		return Object.defineProperties({$key, $ref, ...data},
			{
				$key: {enumerable: false},
				$ref: {enumerable: false}
			}
		);
	}

	getGateways(queryFn?: QueryFn): AngularFirestoreCollection<Gateway> {
		return this.afs.collection<Gateway>("gateways", queryFn);
	}

	getMerchants(queryFn?: QueryFn): AngularFirestoreCollection<Merchant> {
		return this.afs.collection<Merchant>("merchants", queryFn);
	}

	getMerchant(merchantId: string): AngularFirestoreDocument<Merchant> {
		return this.getMerchants().doc(merchantId);
	}

	getAccountBalances(queryFn?: QueryFn): AngularFirestoreCollection<StakeholderBalances> {
		return this.afs.collection<StakeholderBalances>("account-balances", queryFn);
	}

	getMerchantBalance(merchantId: string): AngularFirestoreDocument<StakeholderBalances> {
		return this.getAccountBalances().doc(merchantId);
	}

	getMerchantEmployees(merchantId: string, queryFn?: QueryFn): AngularFirestoreCollection<Employee> {
		return this.getMerchant(merchantId).collection("employees", queryFn);
	}

	getMerchantEmployee(merchantId: string, userId: string): AngularFirestoreDocument<Employee> {
		return this.getMerchantEmployees(merchantId).doc(userId);
	}

	getMerchantLocations(merchantId: string, queryFn?: QueryFn): AngularFirestoreCollection<MerchantLocation> {
		return this.getMerchant(merchantId).collection<MerchantLocation>("locations", queryFn);
	}

	getMerchantLocation(merchantId: string, locationId: string): AngularFirestoreDocument<MerchantLocation> {
		return this.getMerchantLocations(merchantId).doc(locationId);
	}

	getParentMerchant(merchantLocation: AngularFirestoreDocument<MerchantLocation>) {
		return this.afs.doc(merchantLocation.ref.parent.parent!.path);
	}

	getMerchantOffers(merchantId: string, queryFn?: QueryFn): AngularFirestoreCollection<Offer> {
		return this.getMerchant(merchantId).collection<Offer>("offers", queryFn);
	}

	getMerchantOffer(merchantId: string, offerId: string): AngularFirestoreDocument<Offer> {
		return this.getMerchantOffers(merchantId).doc(offerId);
	}

	getMerchantCoupons(merchantId: string, queryFn?: QueryFn): AngularFirestoreCollection<Coupon> {
		return this.getMerchant(merchantId).collection<Coupon>("coupons", queryFn);
	}

	getMerchantCoupon(merchantId: string, couponId: string): AngularFirestoreDocument<Coupon> {
		return this.getMerchantCoupons(merchantId).doc(couponId);
	}

	getMerchantLocationMarkerCodes(merchantId: string, locationId: string, queryFn?: QueryFn): AngularFirestoreCollection<MarkerCodeGeotag> {
		return this.getMerchantLocation(merchantId, locationId).collection<MarkerCodeGeotag>("marker-codes", queryFn);
	}

	getMerchantLocationMarkerCode(merchantId: string, locationId: string, markerCode: string) {
		return this.getMerchantLocationMarkerCodes(merchantId, locationId).doc(markerCode);
	}

	getMerchantLocationMarkerDescriptors(merchantId: string, locationId: string, queryFn?: QueryFn): AngularFirestoreCollection<MarkerDescriptor> {
		return this.getMerchantLocation(merchantId, locationId).collection<MarkerDescriptor>("marker-descriptors", queryFn);
	}

	getMerchantLocationMarkerDescriptor(merchantId: string, locationId: string, descriptorId: string): AngularFirestoreDocument<MarkerDescriptor> {
		return this.getMerchantLocationMarkerCodes(merchantId, locationId).doc(descriptorId);
	}

	getBenefits(queryFn?: QueryFn): AngularFirestoreCollection<Benefit> {
		return this.afs.collection<Benefit>("benefits", queryFn);
	}

	getBenefit(benefitId: string): AngularFirestoreDocument<Benefit> {
		return this.getBenefits().doc(benefitId);
	}

	getRewards(queryFn?: QueryFn): AngularFirestoreCollection<Reward> {
		return this.afs.collection<Reward>("rewards", queryFn);
	}

	getReward(rewardId: string): AngularFirestoreDocument<Reward> {
		return this.getRewards().doc(rewardId);
	}

	getProviders(queryFn?: QueryFn): AngularFirestoreCollection<Provider> {
		return this.afs.collection<Provider>("providers", queryFn);
	}

	getProvider(providerId: string): AngularFirestoreDocument<Provider> {
		return this.getProviders().doc(providerId);
	}

	getSettings(): AngularFirestoreDocument<Settings> {
		return this.afs.collection<Settings>("settings").doc("settings");
	}

	getSurveys(): AngularFirestoreCollection<Survey> {
		return this.afs.collection<Survey>("surveys");
	}

	getSurvey(id: string): AngularFirestoreDocument<Survey> {
		return this.getSurveys().doc(id);
	}

	getUserProfiles(): AngularFirestoreCollection<UserProfile> {
		return this.afs.collection<UserProfile>("users");
	}

	getUserProfile(userId: string): AngularFirestoreDocument<UserProfile> {
		return this.getUserProfiles().doc(userId);
	}

	getUserEmployers(userId: string): AngularFirestoreCollection<Employer> {
		return this.getUserProfile(userId).collection<Employer>("employers");
	}

	getUserEmployer(userId: string, merchantId: string): AngularFirestoreDocument<Employer> {
		return this.getUserEmployers(userId).doc(merchantId);
	}

	getScanRequests(): AngularFirestoreCollection<ScanRequest | null> {
		return this.afs.collection<ScanRequest>("validation-requests");
	}

	getScanRequest(requestId: string): AngularFirestoreDocument<ScanRequest | null> {
		return this.getScanRequests().doc(requestId);
	}

	getChats(queryFn?: QueryFn): AngularFirestoreCollection<Chat> {
		return this.afs.collection<Chat>("chats", queryFn);
	}

	getChat(chatId: string): AngularFirestoreDocument<Chat> {
		return this.getChats().doc(chatId);
	}

	getChatMessages(chatId: string, queryFn?: QueryFn): AngularFirestoreCollection<ChatMessage> {
		return this.getChat(chatId).collection<ChatMessage>("messages", queryFn);
	}

	getChatMessage(chatId: string, messageId: string): AngularFirestoreDocument<ChatMessage> {
		return this.getChatMessages(chatId).doc(messageId);
	}

	// region Cleanup

	cleanEnv(currentUser: string): Promise<any[]> {
		return Promise.all([
			this.deleteCollectionRecursively(this.getRewards().ref),
			this.deleteCollectionRecursively(this.getBenefits().ref),
			this.deleteCollectionRecursively(this.getMerchants().ref, [{name: "locations"}, {name: "coupons"}, {name: "offers"}, {name: "employees"}]),
			this.deleteCollectionRecursively(this.getChats().ref, [{name: "messages"}]),
			this.deleteCollectionRecursively(this.getProviders().ref),
			this.deleteCollectionRecursively(this.getGateways().ref),
			this.deleteCollectionRecursively(this.getUserProfiles().ref, [{name: "employers"}]),
			this.deleteCollectionRecursively(this.getAccountBalances().ref),
			this.deleteCollectionRecursively(this.getSurveys().ref),
		]);
	}

	private deleteDocAndChildCollections(doc: firebase.firestore.DocumentSnapshot, children: FirestoreCollectionDescription[] = []): Promise<void> {
		return Promise
			.all(children.map(child => this.deleteCollectionRecursively(doc.ref.collection(child.name), child.children)))
			.then(() => doc.ref.delete());
	}

	private deleteCollectionRecursively(collection: CollectionReference, children: FirestoreCollectionDescription[] = []): Promise<never | any[]> {
		return collection.get()
			.then(res =>
				Promise.all(res.docs.map(doc =>
					this.deleteDocAndChildCollections(doc, children)
				))
			);
	}

	//endregion

}
