import {Component} from "@angular/core";
import {Nav, NavParams} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {BenefitType} from "../../../../../lib/model/benefit.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Reward} from "../../../../../lib/model/reward.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {RewardSearchFilter} from "../../../core/rewards/reward-search.model";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {OfferPage} from "../offer/offer.page";
import {RewardPage} from "../reward/reward.page";

@Component({
	selector: "page-mb-merchant-location",
	templateUrl: "merchant-location.page.html",
})
export class MerchantLocationPage {

	location: MerchantLocation;
	merchant$: Observable<Merchant>;
	rewards$: Observable<Reward[]>;

	constructor(private nav: Nav,
							private navParams: NavParams,
							private logger: Logger,
							private merchantsService: MerchantsService,
							private rewardsService: RewardsService) {
		this.location = this.navParams.get("location") as MerchantLocation;
		this.merchant$ = this.merchantsService
			.getMerchant(this.location.merchantId)
			.pipe(filterNulls());
		this.rewards$ = this.merchant$
			.switchMap(merchant => this.rewardsService.getCurrentUserRewards({
				benefitType: BenefitType.coupon,
				filter: RewardSearchFilter.current,
				merchantId: merchant.$key
			}))
			.shareReplay();
	}

	selectOffer(offer) {
		this.nav.push(OfferPage, {offer, location: this.location}).catch(error => this.logger.error(error));
	}

	rewardSelected(reward: Reward) {
		const selectedReward$ = this.rewardsService.getReward(reward.$key!);
		this.nav.push(RewardPage, {reward: selectedReward$}).catch(error => this.logger.error(error));
	}

}
