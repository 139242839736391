import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment/moment";

@Pipe({name: "movebeMinutesToTime"})
export class MinutesToTimePipe implements PipeTransform {

	transform(minutes: number): string {
		return moment().startOf("day").add(minutes, "minutes").format("h:mm A");
	}
}
