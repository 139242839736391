import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {ElasticModule} from "ng-elastic";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import {SurveyModal} from "./survey-form/survey.modal";

@NgModule({
	declarations: [
		SurveyModal
	],
	entryComponents: [
		SurveyModal
	],
	exports: [
		SurveyModal
	],
	imports: [
		ElasticModule,
		IonicModule,
		MovebeSharedModule,
	],
	providers: []
})

export class SurveyModule {
}
