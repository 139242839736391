import {Injectable} from "@angular/core";
import {Geolocation} from "@ionic-native/geolocation/ngx";
import {Storage} from "@ionic/storage";
import {Observable} from "rxjs";
import "rxjs/add/operator/publish";
import "rxjs/add/operator/publishBehavior";
import "rxjs/add/operator/share";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {FirebaseService} from "../firebase/firebase.service";
import {Logger} from "../logger/logger.service";
import {LatLngLiteral} from "../mapping/lat-lng-literal";

@Injectable()
export class GeolocationService {

	readonly geoPositionStorageKey = "GeoPosition";
	readonly currentLatLng$: Observable<LatLngLiteral>;
	readonly currentOrCachedLatLng$: Observable<LatLngLiteral>;

	currentLatLng: LatLngLiteral;

	constructor(private fb: FirebaseService,
							private geolocation: Geolocation,
							private logger: Logger,
							private storage: Storage) {

		const cachedLatLng$: Observable<LatLngLiteral> = Observable
			.fromPromise(
				(this.storage
					.ready()
					.then(() => this.storage.get(this.geoPositionStorageKey)) as Promise<LatLngLiteral | null>)
			)
			.pipe(filterNulls());

		const currentGeoposition$ = this.geolocation.watchPosition()
			.shareReplay();

		this.currentLatLng$ = currentGeoposition$
			.map(geoposition => ({
				lat: geoposition.coords.latitude,
				lng: geoposition.coords.longitude,
			}));

		this.currentOrCachedLatLng$ = this.currentLatLng$.merge(cachedLatLng$);

		this.currentLatLng$.subscribe(latLng => {
				this.currentLatLng = latLng;
				this.storage.ready()
					.then(() => this.storage.set(this.geoPositionStorageKey, latLng))
					.catch(error => this.logger.error(error));
			}
		);
	}

}
