import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FirebaseService} from "../firebase/firebase.service";
import {ConnectionStatus, DisconnectAlert} from "./connection-status.model";

@Injectable()
export class ConnectionStatusService {

	readonly connectionStatus$: Observable<any>;
	readonly disconnectAlert$: Observable<DisconnectAlert>;

	private readonly firebaseIsConnected$: Observable<boolean | null>;
	private readonly isOnLine$: Observable<boolean>;

	constructor(private fb: FirebaseService) {

		this.firebaseIsConnected$ = this.fb.getFirebaseIsConnected()
			.valueChanges();

		this.isOnLine$ = Observable.merge(
			Observable.of(navigator.onLine),
			Observable.fromEvent(window, "online")
				.mapTo(true),
			Observable.fromEvent(window, "offline")
				.mapTo(false)
		);

		this.connectionStatus$ = Observable
			.combineLatest(
				this.isOnLine$,
				this.firebaseIsConnected$,
				(isNetworkConnected: boolean, isFirebaseConnected: boolean) => {
					if (!isNetworkConnected) {
						return ConnectionStatus.noNetworkConnection;
					} else if (!isFirebaseConnected) {
						return ConnectionStatus.noFirebaseConnection;
					} else {
						return ConnectionStatus.connected;
					}
				}
			)
			.distinctUntilChanged().share();

		this.disconnectAlert$ = this.connectionStatus$
			.filter(connectionStatus => connectionStatus !== ConnectionStatus.connected)
			.map(connectionStatus => {
				return {
					connectionStatus,
					connectionStatusEndedPromise: this.connectionStatus$.first()
						.toPromise()
				};
			});

	}

}
