export enum MapMarkerState {
	disabled = "disabled",
	normal = "normal",
	highlight = "highlight"
}

export enum MapMarkerType {
	merchant = "merchant",
	parking = "parking",
	position = "position",
	voucher = "voucher",
}

export interface MapMarkerIcon {
	anchor: [number, number];
	size: [number, number];
	url: string;
}
