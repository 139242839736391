import {Component} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import * as currency from "currency.js";
import {Observable} from "rxjs/Observable";
import {switchMap} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import {StakeholderBalances} from "../../../../../lib/model/merchant-balance.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Settlement} from "../../../../../lib/model/settlement.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";

@Component({
	templateUrl: "./settlement.page.html"
})
export class SettlementPage {
	blankSettlement: Settlement = {
		amount: 0,
		comment: "",
		currency: "",
		feeFlat: 0,
		feePercent: 0,
		merchantId: "",
		total: 0,
		transactionId: ""
	};
	readonly merchantsBalances$;
	readonly numberToPercentDivisor = 100;
	settlement: Settlement = JSON.parse(JSON.stringify(this.blankSettlement)) as Settlement;
	selectedMerchantId$ = new Subject<string>();
	balance$: Observable<StakeholderBalances | null>;

	constructor(private alertCtrl: AlertController,
							private busyService: BusyService,
							private movebeApiService: MovebeApiService,
							private merchantsService: MerchantsService,
							private store: Store<MovebeState>,
							private translate: TranslateService) {
		this.merchantsBalances$ = Observable.combineLatest(this.merchantsService.getMerchants(), this.merchantsService.getMerchantBalances(),
			(merchants, balances) => merchants.map(merchant => ({
					balances: balances.find(balance => balance.$key === merchant.$key),
					merchant
				})
			)
		);

		this.balance$ = this.selectedMerchantId$.pipe(
			filterNulls(),
			switchMap(merchantId => this.merchantsService.getMerchantBalance(merchantId))
		);
	}

	cancelEdit() {
		this.settlement = JSON.parse(JSON.stringify(this.blankSettlement)) as Settlement;
	}

	saveSettlement() {
		const savePromise = this.movebeApiService.putSettlement(this.settlement)
			.then(() => {
				this.settlement = JSON.parse(JSON.stringify({
					...this.blankSettlement,
					currency: this.settlement.currency,
					merchantId: this.settlement.merchantId
				})) as Settlement;
				return this.alertCtrl.create({
					buttons: [this.translate.instant("BUTTONS.OK") as string],
					header: this.translate.instant("TRANSACTIONS.POSTED") as string,
					subHeader: this.translate.instant("TRANSACTIONS.YOUR_SETTLEMENT_HAS_BEEN_SAVED") as string,
				});
			})
			.then(alert => alert.present());
		this.busyService.setBusy(savePromise, this.translate.instant("TRANSACTIONS.SAVING_SETTLEMENT"));
	}

	updateMerchant(merchant: Merchant) {
		if (merchant) {
			this.selectedMerchantId$.next(merchant.$key!);
			this.settlement.currency = merchant.currency;
			this.settlement.merchantId = merchant.$key!;
		}
	}

	updateTotal() {
		const precision = 10;
		const feePercent = currency(this.settlement.feePercent, {precision}).divide(this.numberToPercentDivisor);
		const feeFactor = currency(1, {precision}).add(feePercent);
		this.settlement.total = currency(this.settlement.amount, {precision}).multiply(feeFactor).add(this.settlement.feeFlat).value;
	}
}
