import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {Subject} from "rxjs/Subject";
import * as fromOfferSearch from "../+state/index";
import {OfferSearchActions} from "../+state/offer-search.actions";
import {MovebeState} from "../../../app/movebe-state.model";
import {LatLngLiteral} from "../../../core/mapping/lat-lng-literal";
import {torontoLatitude, torontoLongitude} from "../../../core/merchants/merchants.service";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {MapMarkerType} from "../../../shared/map/map-marker-type.model";
import {MapMarker} from "../../../shared/map/map-marker.class";
import {GeoQueryCriteria} from "../../geo-query/geo-query-criteria.model";

@Component({
	selector: "movebe-offer-search",
	styleUrls: ["offer-search.component.scss"],
	templateUrl: "offer-search.component.html"
})
export class OfferSearchComponent implements OnInit, OnDestroy {

	@Output() merchantLocationSelected = new EventEmitter<MerchantLocationOffers>();

	radiusKm = 5;
	mapCenter: LatLngLiteral = {lat: torontoLatitude, lng: torontoLongitude};
	currentList$: Observable<MerchantLocationOffers[]>;
	offerMarkers$: Observable<MapMarker[]>;
	parkingMarkers$: Observable<MapMarker[]>;
	readonly highlightedItem$ = new Subject<{itemNumber: number; itemType: string}>();
	readonly scrollTo$ = new Subject<number>();
	listMode$ = new BehaviorSubject<string>("merchant");
	markerClick$ = new Subject<{itemNumber: number; itemType: string}>();
	private done$: Subject<void> = new Subject<void>();
	readonly visibleLocations$: Observable<MerchantLocationOffers[]>;

	constructor(private store: Store<MovebeState>) {
		this.visibleLocations$ = this.store.select(fromOfferSearch.getSearchResults);
	}

	ngOnInit(): void {

		this.store.dispatch(new OfferSearchActions.SearchOffers(this.getGeoQuerySearchCriteria()));

		this.currentList$ = Observable.combineLatest(this.visibleLocations$, this.listMode$,
			(locationOffers, listMode) =>
				locationOffers.filter((locationOffer =>
						(listMode === "parking" && locationOffer.merchant.type === "parking_lot")
						|| (listMode !== "parking" && locationOffer.merchant.type !== "parking_lot")
					)
				)
		);

		this.offerMarkers$ = this.visibleLocations$
			.map((locationOffers: MerchantLocationOffers[]) => locationOffers
				.filter(locationOffer => locationOffer.merchant.type !== "parking_lot")
				.map((locationOffer: MerchantLocationOffers) =>
					new MapMarker(locationOffer.location.geo!, locationOffer.rewards.length > 0 ? MapMarkerType.voucher : MapMarkerType.merchant
					)
				)
			);

		this.parkingMarkers$ = this.visibleLocations$
			.map((locationOffers: MerchantLocationOffers[]) => locationOffers
				.filter(locationOffer => locationOffer.merchant.type === "parking_lot")
				.map((locationOffer: MerchantLocationOffers) =>
					new MapMarker(locationOffer.location.geo!, MapMarkerType.parking
					)
				)
			);

		this.markerClick$
			.takeUntil(this.done$)
			.subscribe((item) => {
					if (this.listMode !== item.itemType) {
						this.listMode = item.itemType;
					}
					setTimeout(() => {// when listMode changes,
						this.scrollTo$.next(item.itemNumber);
					});
				}
			);

		this.listMode$
			.takeUntil(this.done$)
			.subscribe(() => {
				this.scrollTo$.next(1);
			});
	}

	ngOnDestroy() {
		this.store.dispatch(new OfferSearchActions.ClearSearchResults());
		this.done$.next();
		this.done$.complete();
	}

	chooseMerchantLocation(merchantLocationOffers: MerchantLocationOffers) {
		this.merchantLocationSelected.emit(merchantLocationOffers);
	}

	mapMoved(event: {lat: number; lng: number}) {
		this.mapCenter = {lat: event.lat, lng: event.lng};
		this.store.dispatch(new OfferSearchActions.UpdateSearchCriteria(this.getGeoQuerySearchCriteria()));
	}

	mapSized(radiusMeters: number) {
		const metersPerKilometer = 1000;
		this.radiusKm = radiusMeters / metersPerKilometer;
	}

	scrolledToMerchantItem(itemNumber: number) {
		this.highlightedItem$.next({itemNumber, itemType: this.listMode});
	}

	get listMode() {
		return this.listMode$.value;
	}

	set listMode(value) {
		this.listMode$.next(value);
	}

	private getGeoQuerySearchCriteria(): GeoQueryCriteria {
		return {
			center: [this.mapCenter.lat, this.mapCenter.lng],
			radius: this.radiusKm,
		};
	}
}
