import {Action} from "@ngrx/store";

export interface MerchantMembershipState {
}

const defaultState: MerchantMembershipState = {
	messages: []
};

export function merchantMembershipReducer(state: MerchantMembershipState = defaultState, action: Action): MerchantMembershipState {
	return state;
}
