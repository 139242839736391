import {Component, OnInit} from "@angular/core";
import {Nav} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state/index";
import {Offer} from "../../../../../lib/model/offer.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {OffersService} from "../../../core/offers/offers.service";
import {collapseHorizontal} from "../../../shared/animations/animations";
import {OfferPage} from "../offer/offer.page";

@Component({
	animations: [
		collapseHorizontal
	],
	selector: "page-mm-offers",
	styleUrls: ["./offers.page.scss"],
	templateUrl: "./offers.page.html"
})
export class OffersPage implements OnInit {
	readonly currentMerchantId$: Observable<string | undefined>;
	readonly offers$: Observable<Offer[]>;
	offersPublished: Offer[];
	offersUnpublished: Offer[];

	constructor(private logger: Logger,
							private nav: Nav,
							private offersService: OffersService,
							private store: Store<MovebeState>) {
		this.currentMerchantId$ = this.store.select(fromMerchant.getCurrentMerchantId);
		this.offers$ = this.store.select(fromMerchant.getMerchantOffers);
	}

	ngOnInit() {
		this.offers$.subscribe((offers: Offer[]) => {
			this.offersPublished = offers.filter(offer => offer.published).sort((a, b) => a.presentationSequence - b.presentationSequence);
			this.offersUnpublished = offers.filter(offer => !offer.published);
		});
	}

	/*tslint:disable:member-ordering*/

	activateOffer(offer: Offer) {
		const highestSequence = this.offersPublished
			.map(o => o.presentationSequence)
			.reduce((prev: number, curr: number) => Math.max(prev, curr), -1);
		offer.presentationSequence = highestSequence + 1;
		offer.published = true;
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			this.offersService.updateOffer(merchantId!, offer).catch(error => this.logger.error(error));
		});
	}

	addNewOffer(): void {
		this.nav.push(OfferPage).catch(error => this.logger.error(error));
	}

	deactivateOffer(offer: Offer) {
		const highestSequence = this.offersPublished
			.map(o => o.presentationSequence)
			.reduce((prev: number, curr: number) => Math.max(prev, curr || -1), -1);
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			for (let i = offer.presentationSequence; i <= highestSequence; i++) {
				if (this.offersPublished[i]) {
					this.offersPublished[i].presentationSequence--;
				}
				this.offersService.updateOffer(merchantId!, this.offersPublished[i])
					.catch(error => this.logger.error(error));
			}
			offer.published = false;
			offer.presentationSequence = -1;
			this.offersService.updateOffer(merchantId!, offer).catch(error => this.logger.error(error));
		});
	}

	deleteOffer(location: MerchantLocation): void {
		//
	}

	editOffer(offerId: string): void {
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			this.nav.push(OfferPage, {offerId})
				.catch(error => this.logger.error(error));
		});
	}

	offerMoved(fromTo: {from: number; to: number}) {
		const movedOffer = this.offersPublished[fromTo.from];
		this.offersPublished.splice(fromTo.from, 1);
		this.offersPublished.splice(fromTo.to, 0, movedOffer);
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			this.offersPublished.forEach((offer, i) => {
				offer.presentationSequence = i;
				this.offersService.updateOffer(merchantId!, offer).catch(error => this.logger.error(error));
			});
		});
	}
}
