import {action, payload} from "ts-action";
import {Employee} from "../../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../../lib/model/merchant.model";
import {Offer} from "../../../../../../lib/model/offer.model";
import {RewardDetails} from "../../../../../../lib/model/reward.model";
import {MerchantLocation} from "../../../../core/merchant/merchant-location.model";
import {UserInvitation} from "../../../../core/user/user-invitation.model";

export namespace CurrentMerchantActions {

	export const QueryCurrentEmployer = action("[Current Merchant] Query Current Merchant");

	export const SelectFirstMerchant = action("[Current Merchant] Select First Merchant");

	export const QueryMerchant = action("[Current Merchant] Query Merchant", payload<string>());

	export const CurrentMerchantReceived = action("[Current Merchant] Current Employer Received", payload<Merchant>());

	export const LocationsReceived = action("[Current Merchant] Locations Received", payload<MerchantLocation[]>());

	export const OffersReceived = action("[Current Merchant] Offers Received", payload<Offer[]>());

	export const UserInvitationsReceived = action("[Current Merchant] User Invitations Received", payload<UserInvitation[]>());

	export const UsersReceived = action("[Current Merchant] Users Received", payload<Employee[]>());

	export const RecentValidationsReceived = action("[Current Merchant] Recent Validations Received", payload<RewardDetails[]>());

}
