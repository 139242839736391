import {NgModule} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {ElasticModule} from "ng-elastic";
import {NgxMdModule} from "ngx-md";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import * as fromChat from "./+state";
import {ChatBubbleComponent} from "./chat-bubble";
import {ChatPage} from "./chat/chat.page";

@NgModule({
	declarations: [
		ChatBubbleComponent,
		ChatPage
	],
	entryComponents: [
		ChatPage
	],
	exports: [
		ChatPage
	],
	imports: [
		ElasticModule,
		IonicModule,
		NgxMdModule.forRoot(),
		MovebeSharedModule,
		StoreModule.forFeature(fromChat.featureName, fromChat.reducer),
		EffectsModule.forFeature(fromChat.effects),
	],
	providers: []
})

export class ChatModule {
}
