import {Component, OnDestroy, OnInit} from "@angular/core";
import {ModalController, Platform} from "@ionic/angular";
import {Actions} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AsYouType, ParsedNumber, parseNumber} from "libphonenumber-js";
import {Subject} from "rxjs";
import {filter, first, takeUntil} from "rxjs/operators";
import {ofType} from "ts-action-operators";
import * as fromUser from "../+state";
import {UserActions} from "../+state/user.actions";
import {AuthMethod} from "../../../../../lib/model/auth-method.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {CurrentUserService} from "../../../core/user/current-user.service";

@Component({
	selector: "page-mm-sign-in-modal",
	styleUrls: ["./sign-in.modal.scss"],
	templateUrl: "./sign-in.modal.html"
})
export class SignInModal implements OnInit, OnDestroy {
	AuthMethod = AuthMethod;
	authMethod: AuthMethod;
	readonly authState$ = this.store.select(fromUser.getUserAuthState);
	email: string;
	messageSent = false;
	mobileNumber = "";
	mobileNumberFormatted;
	mobileNumberParsed: ParsedNumber;
	mobileNumberValid = false;
	password: string;
	signInError = false;
	smsError;
	private done$: Subject<void> = new Subject<void>();

	constructor(private actions$: Actions,
							private busyService: BusyService,
							private platform: Platform,
							private modalController: ModalController,
							private currentUserService: CurrentUserService,
							private logger: Logger,
							private store: Store<MovebeState>) {
		this.authMethod = platform.is("cordova") ? AuthMethod.sms : AuthMethod.email;
	}

	ngOnInit() {
		this.authState$.pipe(
			filter(user => !user!.isAnonymous)
		).subscribe(() => {
			this.dismiss();
		});
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	numberChanged() {
		this.mobileNumberFormatted = new AsYouType("US").input(this.mobileNumber);
		this.mobileNumberParsed = parseNumber(this.mobileNumber, "US");
		this.mobileNumberValid = !!(this.mobileNumberParsed && this.mobileNumberParsed.phone);
	}

	dismiss() {
		this.modalController.dismiss().catch(error => this.logger.error(error));
	}

	sendAuthSMS() {
		this.store.dispatch(new UserActions.SendAuthSms(this.mobileNumberParsed.phone));
		this.busyService.setBusy(this.actions$
			.pipe(
				ofType(UserActions.AuthSmsSent),
				first(),
				takeUntil(this.done$),
			)
			.subscribe(() => {
				this.messageSent = true;
			})
		);
	}

	setAuthMethod(authMethod: AuthMethod) {
		this.authMethod = authMethod;
	}

	signInWithEmailPassword() {
		this.signInError = false;
		this.store.dispatch(new UserActions.SignInWithEmail({email: this.email, password: this.password}));
		this.busyService.setBusy(this.actions$
			.pipe(ofType({
				SignInFailed: UserActions.SignInFailed,
				SignedIn: UserActions.SignedIn,
			}))
			.first()
			.toPromise()
		);

		this.actions$.pipe(ofType(UserActions.SignInFailed))
			.first().subscribe(() => {
			this.signInError = true;
		});
	}

}
