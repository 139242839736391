import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {GeoQueryCriteria} from "../../geo-query/geo-query-criteria.model";
import {OfferSearchActions} from "./offer-search.actions";

export interface OfferSearchState {
	searchCriteria?: GeoQueryCriteria;
	searchResults: MerchantLocationOffers[];
}

const defaultState: OfferSearchState = {
	searchResults: []
};

export function offerSearchReducer(state: OfferSearchState = defaultState, action: Action): OfferSearchState {
	if (isType(action, OfferSearchActions.SearchResultsReceived)) {
		return {...state, searchResults: action.payload};
	}
	if (isType(action, OfferSearchActions.SearchCriteriaReceived)) {
		return {...state, searchCriteria: action.payload};
	}
	if (isType(action, OfferSearchActions.ClearSearchResults)) {
		return {searchResults: []};
	}
	return state;
}

export const getSearchResults = (state: OfferSearchState) => state.searchResults;
