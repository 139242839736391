import {Injectable} from "@angular/core";
import {IBusyConfig} from "ng-busy";
import {Subject} from "rxjs";
import {BusyAsyncObject} from "./busy-async-object.model";

@Injectable()
export class BusyService {

	private readonly busySubject$ = new Subject<IBusyConfig>();
	readonly busy$ = this.busySubject$.asObservable();

	setBusy(busyAsyncObject: BusyAsyncObject, message ?: string) {
		this.busySubject$.next({
			busy: [busyAsyncObject],
			...(message ? {message} : {})
		});
	}

}
