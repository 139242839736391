import {
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChild,
} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Subject} from "rxjs/Subject";
import {ScrollListItemComponent} from "./scroll-list-item.component";

@Component({
	selector: "movebe-scroll-list",
	styleUrls: ["scroll-list.component.scss"],
	templateUrl: "scroll-list.component.html"
})
export class ScrollListComponent implements AfterViewInit, OnDestroy {

	//TODO: resolve bug where scroll highlighting stops working after clicking around several times

	private readonly done$ = new Subject<void>();
	itemOffsets$ = new Observable<number[]>();
	listScrolled$ = new Subject<HTMLElement>();
	scrollTo$ = new Subject<number>();
	scrollTop$ = new Subject<number>();

	@Output() scrolledToNumber = new EventEmitter<number>(); //the index number of the item currently scrolled to
	@ContentChildren(ScrollListItemComponent) scrollListItems: QueryList<ScrollListItemComponent>;
	@ViewChild("scrollElement") scrollElement: ElementRef;

	@Input() /*tslint:disable-line:no-unsafe-any*/
	set scrollTo(itemNumber: number) {
		this.scrollTo$.next(itemNumber);
	}

	ngAfterViewInit() {

		//a list of offset heights for each item in the current list
		this.itemOffsets$ = this.scrollListItems.changes
			.map((items: QueryList<ScrollListItemComponent>) => {
				return items.map((item, index) => {
					return item.nativeElement.offsetTop;
				});
			});

		//whenever the list changes, reset the scroll to top
		this.scrollListItems.changes
			.takeUntil(this.done$)
			.delay(0)/// .delay(0) avoids ExpressionChangedAfterItHasBeenCheckedError. (see: https://goo.gl/vDazKY)
			.subscribe((items) => {
				(this.scrollElement.nativeElement as HTMLElement).scrollTop = 0; //scroll the list back to the top
				this.scrolledToNumber.emit(0);  //select the first item in the list
			});

		//whenever the user scrolls the list, calculate the selected item based on scroll height
		Observable.combineLatest(
			this.listScrolled$,
			this.itemOffsets$.filter((offests: number[]) => offests.length > 0),
			(scrolledElement: HTMLElement, offsets: number []) => {
				return offsets.findIndex(o => o >= scrolledElement.scrollTop);
			}
		)
			.distinctUntilChanged() //don't emit a new value if the item hasn't changed due to scrolling a short distance
			.delay(0) // .delay(0) avoids ExpressionChangedAfterItHasBeenCheckedError. (see: https://goo.gl/vDazKY)
			.takeUntil(this.done$)
			.subscribe(n => {
				this.scrolledToNumber.emit(n);
			});

		//whenever scrollTo input value changes to a new value n, scroll to the nth item in the list
		this.scrollTo$
			.withLatestFrom(
				this.itemOffsets$,
				(selected: number, offsets: number[]) => offsets[selected]) //find the scroll offset of the selected item
			.takeUntil(this.done$)
			.subscribe(scrollTop =>
				this.scrollTop$.next(scrollTop) //triggers scrolling via the [scrollTop] binding in the template
			);
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}
}
