import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
//TODO reimplement native maps
// import {GoogleMaps} from "@ionic-native/google-maps";
import {Platform} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {LatLngLiteral} from "../../core/mapping/lat-lng-literal";
import {MapMarker} from "./map-marker.class";

@Component({
	selector: "movebe-map",
	templateUrl: "map.component.html"
})
export class MapComponent implements OnInit {

	isCordova: boolean;

	@Input() highlightedItem$: Observable<{itemNumber: number; itemType: string}>;
	@Input() mapCenter: LatLngLiteral;
	@Input() offerMarkers$: Observable<MapMarker[]>;
	@Input() parkingMarkers$: Observable<MapMarker[]>;
	@Output() markerClicked = new EventEmitter<{itemNumber: number; itemType: string}>();
	@Output() onMapMoved = new EventEmitter<{target: google.maps.Map}>();
	@Output() onMapSized = new EventEmitter<number>();

	constructor(private platform: Platform /*, private googleMaps: GoogleMaps*/) {
	}

	ngOnInit() {
		this.isCordova = this.platform.is("cordova");
	}

	mapMoved(event: {target: google.maps.Map}) {
		this.onMapMoved.emit(event);
	}

	mapSized(event: number) {
		this.onMapSized.emit(event);
	}
}
