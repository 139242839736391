import {Component} from "@angular/core";
import {Nav} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {Reward} from "../../../../../lib/model/reward.model";
import {Logger} from "../../../core/logger/logger.service";
import {RewardSearchFilter} from "../../../core/rewards/reward-search.model";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {RewardPage} from "../reward/reward.page";

@Component({
	selector: "page-mb-history",
	templateUrl: "history.page.html"
})
export class HistoryPage {

	readonly reward$: Observable<Reward[]>;

	constructor(private nav: Nav,
							private logger: Logger,
							private rewardsService: RewardsService) {
		this.reward$ = this.rewardsService.getCurrentUserRewards({filter: RewardSearchFilter.old}).shareReplay();
	}

	rewardSelected(reward: Reward) {
		const selectedReward$ = this.rewardsService.getReward(reward.$key!);
		this.nav.push(RewardPage, {reward: selectedReward$}).catch(error => this.logger.error(error));
	}

}
