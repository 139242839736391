import {ChangeDetectorRef, Component, NgZone} from "@angular/core";
import {ModalController, NavController, ToastController} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {first, map, shareReplay} from "rxjs/operators";
import * as fromMerchant from "../+state/index";
import {Dictionary} from "../../../../../lib/model/dictionary.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {indexBy} from "../../../../../lib/util/array-map";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {GeolocationService} from "../../../core/geolocation/geolocation.service";
import {Logger} from "../../../core/logger/logger.service";
import {LatLngLiteral} from "../../../core/mapping/lat-lng-literal";
import {MappingService} from "../../../core/mapping/mapping.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {SelectableList} from "../../../core/misc/selectable-list.model";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {LocationModal} from "../location/location.modal";
import PlaceResult = google.maps.places.PlaceResult;

@Component({
	selector: "page-mm-add-locations",
	styleUrls: ["./add-locations.modal.scss"],
	templateUrl: "./add-locations.modal.html"
})
export class AddLocationsModal {
	readonly currentMerchant$: Observable<Merchant>;
	readonly existingLocations$: Observable<Dictionary<MerchantLocation>>;
	selectablePlaces$: Observable<SelectableList<PlaceResult>>;
	selectablePlaces: SelectableList<PlaceResult>;
	readonly searchRadius = 5000;
	private currentLatLng$: Observable<LatLngLiteral>;
	readonly searchPlace$: BehaviorSubject<google.maps.places.PlaceResult | null>;
	readonly addressMatchPlace$: Observable<google.maps.places.PlaceResult | null>;

	readonly searchReferencePoint$: Observable<LatLngLiteral>;

	constructor(private busyService: BusyService,
							private changeDetector: ChangeDetectorRef,
							private geolocationService: GeolocationService,
							private logger: Logger,
							private mappingService: MappingService,
							private merchantsService: MerchantsService,
							private modalCtrl: PromiseModalController,
							private navCtrl: NavController,
							private ngZone: NgZone,
							private store: Store<MovebeState>,
							private toastCtrl: ToastController,
							private translateService: TranslateService,
							private modalController: ModalController) {

		this.currentMerchant$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant),
			filterNulls()
		);
		this.currentLatLng$ = this.geolocationService.currentOrCachedLatLng$;

		this.existingLocations$ = this.store
			.pipe(select(fromMerchant.getMerchantLocations),
				map(locations => locations.filter(location => !!location.googlePlaceId)),
				map(locations => indexBy(locations, location => location.googlePlaceId!)),
				shareReplay()
			);

		this.searchPlace$ = new BehaviorSubject<google.maps.places.PlaceResult | null>(null);

		this.addressMatchPlace$ = this.searchPlace$
			.map(place => (place && this.isAddressMatchPlace(place)) ? place : null);

		this.searchReferencePoint$ = this.searchPlace$
			.switchMap(p => p ?
				Observable.of(this.mappingService.googleGeometryToLatLng(p.geometry))
				: this.currentLatLng$.first());

		this.selectablePlaces$ = this.searchReferencePoint$
			.withLatestFrom(this.currentMerchant$, this.existingLocations$)
			.switchMap(([searchReferencePoint, currentMerchant]) => this.getMerchantLocations(currentMerchant.name, searchReferencePoint))
			.withLatestFrom(this.existingLocations$)
			.map(([locations, existingLocations]) => locations
				.map(location => ({
					disabled: !!existingLocations[location.place_id],
					item: location,
					selected: false,
				}))
			);
		this.selectablePlaces$.subscribe(selectablePlaces => this.selectablePlaces = selectablePlaces);
	}

	isAddressMatchPlace(place: google.maps.places.PlaceResult): boolean {
		const candidateTypes = [
			"establishment",
			"premise",
			"street_address",
			"shopping_mall",
		];
		return place.types.filter(type => candidateTypes.includes(type)).length > 0;
	}

	getMerchantLocations(merchantName: string, searchReferencePoint: LatLngLiteral) {
		const getMerchantLocationsPromise = this.mappingService.getMerchantLocations(merchantName, searchReferencePoint, this.searchRadius);
		this.busyService.setBusy(getMerchantLocationsPromise);
		return getMerchantLocationsPromise;
	}

	addSelectedLocations() {
		const selectedPlaces = this.selectablePlaces
			.filter(l => l.selected).map(l => l.item);
		const addLocationsPromise = this.currentMerchant$
			.first()
			.toPromise()
			.then(merchant => this.merchantsService.addLocationsFromGoogleMapData(merchant, selectedPlaces))
			.then(results => {
				const addedCount = results.filter(item => !!item).length;
				const skippedCount = results.length - addedCount;
				return this.presentLocationsAddedMessageAndDismiss(addedCount, skippedCount);
			});
		this.busyService.setBusy(addLocationsPromise);
	}

	presentLocationsAddedMessageAndDismiss(addedCount: number, skippedCount = 0): Promise<void> {
		const addedMessage = this.translateService.instant(addedCount === 1 ? "LOCATIONS.LOCATION_ADDED" : "LOCATIONS.LOCATIONS_ADDED", {addedCount}) as string;
		const skippedMessage = this.translateService.instant(skippedCount === 1 ? "LOCATIONS.LOCATION_SKIPPED" : "LOCATIONS.LOCATIONS_SKIPPED", {skippedCount}) as string;
		const message = skippedCount ? `${addedMessage} ${skippedMessage}` : addedMessage;
		return this.toastCtrl
			.create({
				cssClass: skippedCount ? "toast-failure" : "toast-success",
				duration: 3000,
				message,
				position: "middle"
			})
			.then(toast => toast.present())
			.then(() => this.modalController.dismiss());
	}

	countSelected() {
		return this.selectablePlaces.filter(item => item.selected).length;
	}

	updateSearchReferencePoint(place: google.maps.places.PlaceResult | null) {
		this.searchPlace$.next(place);
	}

	placeSelectedChanged() {
		//TODO: figure out the proper way to force change detection for the map icons
		this.selectablePlaces = [...this.selectablePlaces]; //hacky way of forcing change detection for the map icons
	}

	async add(place: google.maps.places.PlaceResult) {
		const currentMerchant = await this.currentMerchant$
			.pipe(
				filterNulls(),
				first()
			)
			.toPromise();
		const newLocation = {
			...await this.mappingService.addLocationDetails(this.merchantsService.getNewMerchantLocation(currentMerchant), place),
			name: currentMerchant.name
		};
		return this.modalCtrl
			.presentModal(LocationModal, {
				location: newLocation
			})
			.then(added => {
				if (added) {
					this.presentLocationsAddedMessageAndDismiss(1)
						.catch(error => this.logger.error(error));
				}
			});
	}

}
