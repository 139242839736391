import {ActionReducerMap, createFeatureSelector, createSelector} from "@ngrx/store";
import {CurrentMerchantEffects} from "./current-merchant/current-merchant.effects";
import * as fromCurrentMerchantStore from "./current-merchant/current-merchant.reducer";
import {CurrentUserEffects} from "./current-user/current-user.effects";
import * as fromCurrentUserStore from "./current-user/current-user.reducer";

export const featureName = "merchantMode";

export interface FeatureState {
	merchantMode: MerchantModeState; //the name of this property must match the value of featureName const above
}

export interface MerchantModeState {
	currentMerchant: fromCurrentMerchantStore.CurrentMerchantState;
	currentUser: fromCurrentUserStore.CurrentUserState;
}

export const reducer: ActionReducerMap<any> = {
	currentMerchant: fromCurrentMerchantStore.merchantReducer,
	currentUser: fromCurrentUserStore.currentUserReducer,
};

export const effects = [CurrentMerchantEffects, CurrentUserEffects];

export const getMerchantState = createFeatureSelector<MerchantModeState>(featureName);

export const getCurrentMerchantState = createSelector(getMerchantState, state => state.currentMerchant);
export const getCurrentUserState = createSelector(getMerchantState, state => state.currentUser);

export const getCurrentMerchant = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getCurrentMerchant);
export const getCurrentUserMerchant = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getCurrentUserMerchant);
export const getCurrentMerchantId = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getCurrentMerchantId);
export const getMerchantLocations = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getMerchantLocations);
export const getMerchantOffers = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getMerchantOffers);
export const getMerchantRecentValidations = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getMerchantRecentValidations);
export const getMerchantUserInvitations = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getMerchantUserInvitations);
export const getMerchantEmployees = createSelector(getCurrentMerchantState, fromCurrentMerchantStore.getMerchantEmployees);

export const getEmployers = createSelector(getCurrentUserState, fromCurrentUserStore.getEmployers);
