import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {Blotter} from "../../../../../lib/model/blotter.model";
import {StakeholderBalances} from "../../../../../lib/model/merchant-balance.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";
import {childJoin} from "../../../lib/rxjs-operators/child-join";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {StakeholderModal} from "../stakeholder.modal/stakeholder.modal";

export interface AccountBalance {
	booked: {amount: number; currency_code: string};
	id: string;
	pending: {amount: number; currency_code: string};
	stakeholderName: string;
	type: string;
}

export interface MovebeRevenue {
	day: number;
	month: number;
	week: number;
}

export enum AccountBalanceType {
	merchant = "merchant",
	movebe = "movebe",
	provider = "provider"
}

@Component({
	selector: "page-admin-transactions",
	styleUrls: ["./transactions.page.scss"],
	templateUrl: "./transactions.page.html"
})
export class TransactionsPage {
	movebeRevenue: MovebeRevenue[];
	providersBalances: AccountBalance[];
	recentTransactions: Blotter[];
	transactionsLimit = 10;
	merchantBalances$: Observable<{merchant: Merchant; balances: StakeholderBalances}[]>;

	constructor(private logger: Logger,
							private modalCtrl: ModalController,
							private merchantsService: MerchantsService,
							private movebeApiService: MovebeApiService) {

		this.merchantBalances$ = this.merchantsService.getMerchantBalances()
			.pipe(childJoin<StakeholderBalances, Merchant, {merchant: Merchant; balances: StakeholderBalances}>(
				(balances: StakeholderBalances) => this.merchantsService.getMerchant(balances.$key!).pipe(filterNulls()),
				(balances: StakeholderBalances, merchant: Merchant) => {
					return {balances, merchant};
				}
			));

		this.movebeApiService.getMovebeRevenue()
			.then((results: [MovebeRevenue]) => {
				this.movebeRevenue = results;
			}).catch(error => console.error(error));

		this.movebeApiService.getStakeholdersBalances()
			.then((results: [any]) => {
				this.providersBalances = results.filter((result: AccountBalance) => result.type === AccountBalanceType.provider);
			}).catch(error => console.error(error));

		this.movebeApiService.getRecentTransactions(this.transactionsLimit)
			.then((transactions: Blotter[]) => {
				this.recentTransactions = transactions;
			}).catch(error => console.error(error));
	}

	showStakeholderDetails(type, stakeholderId) {

		const stakeholderTransactions = this.recentTransactions
			.filter(t =>
				(type === "merchant" && t.merchantId === stakeholderId)
				|| (type === "provider" && t.providerId === stakeholderId)
			);
		this.modalCtrl
			.create({
				component: StakeholderModal,
				componentProps: {
					stakeholderTransactions,
					type
				}
			})
			.then(modal => modal.present())
			.catch(error => this.logger.error(error));
	}
}
