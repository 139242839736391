import {ActionReducerMap, createFeatureSelector} from "@ngrx/store";

export const featureName = "consumerMode";

export interface FeatureState {
	consumerMode: ConsumerState; //the name of this property must match the value of featureName const above
}

export interface ConsumerState {
}

export const reducer: ActionReducerMap<any> = {};

export const effects = [];

export const getConsumerState = createFeatureSelector<ConsumerState>(featureName);
