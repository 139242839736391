import {Component, Input} from "@angular/core";
import {BarCodeType} from "../../core/coupon/coupon.model";

@Component({
	selector: "movebe-bar-code",
	styleUrls: ["./bar-code.component.scss"],
	templateUrl: "./bar-code.component.html"
})
export class BarCodeComponent {

	BarCodeType = BarCodeType;

	@Input() type: BarCodeType;
	@Input() value: string;

}
