import {Component} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {FileHolder} from "angular2-image-upload";
import {Logger} from "../../../../core/logger/logger.service";

@Component({
	selector: "page-mm-upload-floor-plan-modal",
	styleUrls: ["upload-floor-plan.modal.scss"],
	templateUrl: "upload-floor-plan.modal.html"
})
export class UploadFloorPlanModal {

	floorPlanName: string;
	imageBlob?: Blob;
	imageSrc?: string;

	constructor(private modalController: ModalController,
							private logger: Logger) {

	}

	imageUploaded(event: FileHolder) {
		this.imageBlob = new Blob([event.file], {type: event.file.type});
		this.imageSrc = event.src;
	}

	removeImage(event) {
		this.imageBlob = undefined;
		this.imageSrc = undefined;
	}

	dismiss() {
		this.modalController.dismiss().catch(error => this.logger.error(error));
	}

	submit() {
		this.modalController.dismiss({
			floorPlanName: this.floorPlanName,
			imageBlob: this.imageBlob,
			imageSrc: this.imageSrc,
		}).catch(error => this.logger.error(error));
	}
}
