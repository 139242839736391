import {Component} from "@angular/core";
import {ModalController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Reward} from "../../../../../lib/model/reward.model";
import {SurveyQuestionResponse} from "../../../../../lib/model/survey/survey-question-response.model";
import {SurveyQuestion, SurveyQuestionType} from "../../../../../lib/model/survey/survey-question.model";
import {Survey} from "../../../../../lib/model/survey/survey.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {SurveysService} from "../../../core/surveys/surveys.service";

@Component({
	selector: "movebe-survey",
	styleUrls: ["survey.modal.scss"],
	templateUrl: "survey.modal.html"
})
export class SurveyModal {

	readonly merchant: Merchant;
	rankingResponses = [];
	readonly reward: Reward;
	survey$: Observable<Survey | null>;
	submitted = false;
	readonly SurveyQuestionType = SurveyQuestionType;
	textResponses = [];

	constructor(private busyService: BusyService,
							private logger: Logger,
							private navParams: NavParams,
							private rewardService: RewardsService,
							private store: Store<MovebeState>,
							private surveysService: SurveysService,
							private translate: TranslateService,
							private modalController: ModalController) {
		this.reward = navParams.get("reward") as Reward;
		this.merchant = navParams.get("merchant") as Merchant;
		this.survey$ = this.surveysService.getSurvey(this.merchant.survey!);
	}

	dismiss() {
		this.modalController
			.dismiss()
			.catch(error => this.logger.error(error));
	}

	submit() {
		this.submitted = true; //TODO: figure out if this is necessary or not
		const submitSurveyPromise: Promise<any> = this.survey$.first()
			.toPromise()
			.then((survey: Survey) => {
				const surveyQuestionResponses: SurveyQuestionResponse[] = survey.questions.map((question: SurveyQuestion, i) => {
					const response: SurveyQuestionResponse = {rewardId: this.reward.$key!, surveyQuestionId: question.id!};
					if (question.type === SurveyQuestionType.multipleChoice) {
						response.surveyChoiceId = question.choices![this.rankingResponses[i] - 1].id;
					} else {
						response.text = this.textResponses[i];
					}
					return response;
				});
				return this.surveysService.saveResponses(surveyQuestionResponses)
					.toPromise();
			})
			.then(() => this.rewardService.setRewardSurveyCompleted(this.reward.$key!))
			.then(() => this.dismiss());
		this.busyService.setBusy(submitSurveyPromise, this.translate.instant("SURVEY.SUBMITTING_SURVEY"));
		return submitSurveyPromise;
	}
}
