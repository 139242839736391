import {HttpClientModule} from "@angular/common/http";
import {NgModule, Optional, SkipSelf} from "@angular/core";
import {AppVersion} from "@ionic-native/app-version/ngx";
import {BarcodeScanner} from "@ionic-native/barcode-scanner/ngx";
import {Device} from "@ionic-native/device/ngx";
import {Geolocation} from "@ionic-native/geolocation/ngx";
import {NativeAudio} from "@ionic-native/native-audio/ngx";
import {Network} from "@ionic-native/network/ngx";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {Vibration} from "@ionic-native/vibration/ngx";
import {NguiMapModule} from "@ngui/map";
import {AngularFireModule, FirebaseNameOrConfigToken, FirebaseOptionsToken} from "angularfire2";
import {AngularFireAuthModule} from "angularfire2/auth";
import {AngularFireDatabaseModule} from "angularfire2/database";
import {AngularFirestoreModule} from "angularfire2/firestore";
import {appEnvironment, getFirebaseAppName, getFirebaseConfig} from "../app/app-environment";
import {TelephoneNumberPipe} from "../shared/telephone-number/telephone-number.pipe";
import {AppLinkService} from "./app-links/app-link.service";
import {BusyService} from "./busy/busy.service";
import {ConnectionStatusService} from "./connection-status/connection-status.service";
import {CouponService} from "./coupon/coupon.service";
import {DataImporterService} from "./data/data-importer.service";
import {EffectsService} from "./effects/effects.service";
import {FirebaseService} from "./firebase/firebase.service";
import {FirestoreService} from "./firebase/firestore.service";
import {GatewaysService} from "./gateways/gateways.service";
import {GeolocationService} from "./geolocation/geolocation.service";
import {InitRemoteServicesService} from "./init-remote-services.service/init-remote-services.service";
import {Logger} from "./logger/logger.service";
import {MappingService} from "./mapping/mapping.service";
import {MerchantsService} from "./merchants/merchants.service";
import {PromiseModalController} from "./modal/modal.service";
import {MovebeApiService} from "./movebe-api/movebe-api.service";
import {MovebeMarkersService} from "./movebe-markers/movebe-markers.service";
import {NavService} from "./nav/nav.service";
import {OfferSearchService} from "./offer-search/offer-search.service";
import {OffersService} from "./offers/offers.service";
import {ParkingValidationService} from "./parking/parking-validation.service";
import {ProvidersService} from "./providers/providers.service";
import {RewardsService} from "./rewards/rewards.service";
import {ScanRequestService} from "./scan-request/scan-request.service";
import {ScanningService} from "./scanning/scanning.service";
import {SettingsService} from "./settings/settings.service";
import {SurveysService} from "./surveys/surveys.service";
import {CurrentUserService} from "./user/current-user.service";
import {UsersService} from "./user/users.service";

@NgModule
({
	bootstrap: [],
	declarations: [],
	entryComponents: [],
	exports: [
		NguiMapModule
	],
	imports: [
		AngularFireModule
			.initializeApp(appEnvironment.firebaseConfig),
		AngularFireAuthModule,
		AngularFireDatabaseModule,
		AngularFirestoreModule,
		HttpClientModule,
		NguiMapModule.forRoot({
			apiUrl: "https://maps.google.com/maps/api/js?key=AIzaSyCgiA0YM2obLi_iWxXFX1KyMbI89K5hATw&libraries=places,geometry"
		})
	],
	providers: [
		{provide: FirebaseOptionsToken, useFactory: getFirebaseConfig},
		{provide: FirebaseNameOrConfigToken, useFactory: getFirebaseAppName},
		AppLinkService,
		AppVersion,
		BarcodeScanner,
		BusyService,
		ConnectionStatusService,
		CouponService,
		CurrentUserService,
		DataImporterService,
		Device,
		EffectsService,
		FirebaseService,
		FirestoreService,
		GatewaysService,
		Geolocation,
		GeolocationService,
		InitRemoteServicesService,
		Logger,
		MappingService,
		MerchantsService,
		MovebeApiService,
		MovebeMarkersService,
		NativeAudio,
		NavService,
		Network,
		OfferSearchService,
		OffersService,
		ParkingValidationService,
		PromiseModalController,
		ProvidersService,
		RewardsService,
		ScanningService,
		ScanRequestService,
		SettingsService,
		SplashScreen,
		StatusBar,
		SurveysService,
		TelephoneNumberPipe,
		UsersService,
		Vibration,
	]
})
export class MovebeCoreModule {
	constructor(@Optional() @SkipSelf() parentModule: MovebeCoreModule) {
		if (parentModule) {
			throw new Error("MovebeCoreModule has already been loaded. Import Core modules in the AppModule only.");
		}
	}
}
