import {Component, OnInit} from "@angular/core";
import {Nav, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state/index";
import {MerchantRole} from "../../../../../lib/model/employment-relationship.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocation} from "../../../core/merchant/merchant-location.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {CurrentUserService} from "../../../core/user/current-user.service";
import {UserInvitation} from "../../../core/user/user-invitation.model";

@Component({
	selector: "page-mm-user-invitation",
	styleUrls: ["./user-invitation.page.scss"],
	templateUrl: "./user-invitation.page.html"
})
export class UserInvitationPage implements OnInit {
	readonly currentMerchantId$: Observable<string | undefined>;
	locations: MerchantLocation[] = [];
	readonly locations$: Observable<MerchantLocation[]>;
	merchantRoles: string[];
	userInvitation: UserInvitation;

	constructor(private currentUserService: CurrentUserService,
							private logger: Logger,
							private merchantsService: MerchantsService,
							private nav: Nav,
							private navParams: NavParams,
							private store: Store<MovebeState>) {
		this.currentMerchantId$ = this.store.select(fromMerchant.getCurrentMerchantId);
		this.locations$ = this.store.select(fromMerchant.getMerchantLocations);
	}

	ngOnInit() {
		this.userInvitation = this.navParams.get("userInvitation") as UserInvitation;
		if (!this.userInvitation) {
			this.userInvitation = this.merchantsService.getNewUserInvitation();
		}

		this.merchantRoles = Object.keys(MerchantRole);
		this.locations$.subscribe((locations: MerchantLocation[]) => {
			this.locations = locations;
		});
	}

	/*tslint:disable:member-ordering*/
	cancelEdit() {
		this.nav.pop().catch(error => this.logger.error(error));
	}

	/*tslint:enable:member-ordering*/
	saveUserInvitation(): void {
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			if (!this.userInvitation.$key) {
				this.currentUserService.pushUserInvitation(merchantId!, this.userInvitation);
			} else {
				this.currentUserService.addUpdateUserInvitation(this.userInvitation.$key, merchantId!, this.userInvitation);
			}
			this.nav.pop().catch(error => this.logger.error(error));
		});
	}
}
