import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as HttpStatus from "http-status-codes";
import {Observable} from "rxjs";
import {AppActions} from "../../app/+state/app.actions";
import {appEnvironment} from "../../app/app-environment";
import {MovebeState} from "../../app/movebe-state.model";
import {FirestoreService} from "../firebase/firestore.service";

/**
 * Adds Authorization header to http requests going to MoveBe api
 * Also provides notification for unexpected http request errors
 */
@Injectable()
export class MovebeApiInterceptor implements HttpInterceptor {

	readonly apiHostName = new URL(appEnvironment.apiUrl).hostname;

	constructor(private firestore: FirestoreService,
							private store: Store<MovebeState>) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let httpEvent$: Observable<HttpEvent<any>>;
		try {
			if (new URL(req.url).hostname !== this.apiHostName) {
				httpEvent$ = next.handle(req);
			} else {
				httpEvent$ = Observable.fromPromise(this.firestore.getAuthToken())
					.switchMap(token => {
						const headers = req.headers.set("Authorization", `Bearer ${token}`);
						const reqClone = req.clone({headers});
						return next.handle(reqClone);
					});
			}
		} catch { //ignore exception thrown when parsing relative filesystem urls used to load data files from the filesystem (i.e. "new URL('./file.dat')" throws an exception )
			httpEvent$ = next.handle(req);
		}

		return httpEvent$
			.do(
				() => null,
				err => {
					if (err instanceof HttpErrorResponse && err.status !== HttpStatus.BAD_REQUEST) {
						this.store.dispatch(new AppActions.HttpErrorOccurred(err));
					}
				}
			);
	}

}
