import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import {Subject} from "rxjs";
import {AppLink, AppLinkType} from "../../../../lib/model/app-link.model";
import {BranchResponse, BranchType} from "../../../../lib/model/branch.model";
import {appEnvironment} from "../../app/app-environment";
import {Logger} from "../logger/logger.service";

declare const Branch: BranchType;

@Injectable()
export class AppLinkService {

	readonly appLinkEvent$ = new Subject<AppLink>();
	private appLinkUrlParserRegExp: RegExp;

	constructor(private platform: Platform,
							private logger: Logger) {
		const appLinkTypes: AppLinkType[] = Object.keys(AppLinkType).map(key => AppLinkType[key]); //tslint:disable-line:no-unsafe-any
		const appLinkUrlParserPattern = `^https?://(?:dev\\.)?mvb\\.io/(${appLinkTypes.join("|")})\\?q=([\\w-]+)$`;
		this.appLinkUrlParserRegExp = new RegExp(appLinkUrlParserPattern, "i");

		if (this.canReadDeepLinks) {
			Branch.setDebug(true);
		}
	}

	/**
	 * Generates a properly formatted MoveBe App Link URL.
	 * @param {AppLink} an AppLink object to convert into a URL
	 * @return {string} the app link URL
	 */
	generateAppLinkUrl(appLink: AppLink): string {
		const domain = appEnvironment.isProduction ? "mvb.io" : "dev.mvb.io";
		return `https://${domain}/${encodeURIComponent(appLink.type)}?q=${encodeURIComponent(appLink.value)}`;
	}

	/**
	 *  Retrieves deep link data from Branch.io server and processes app link
	 */
	checkDeepLinks() {
		if (this.canReadDeepLinks) {
			Branch
				.initSession(() => null)
				.then((branchResponse: BranchResponse) => {
					this.logger.info("branchResponse:", branchResponse);
					if (branchResponse["~referring_link"]) {
						const appLink = this.parseAppLinkUrl(branchResponse["~referring_link"]);
						if (appLink !== null) {
							this.advertiseAppLinkEvent(appLink);
						}
					}
				})
				.catch(error => this.logger.error(error));
		}
	}

	/**
	 *  Converts an App Link URL into an AppLink object.
	 *  Throws an exception of the string does not a properly formatted MoveBe App link URL
	 * @param {string} appLinkUrl
	 * @return {AppLink}
	 */
	parseAppLinkUrl(appLinkUrl: string): AppLink | null {
		const [fullMatch = "", type = "", value = ""] = appLinkUrl.match(this.appLinkUrlParserRegExp) || [];
		if (!fullMatch) {
			return null;
		}
		return {type, value};
	}

	/**
	 * Emits this appLinkEvent to the AppLinkEvent$ so that subscribers can react to this event.
	 * @param {AppLink} appLink
	 */
	advertiseAppLinkEvent(appLink: AppLink) {
		//TODO: possibly switch appMode if appLink requires it and user has permission (i.e. allow merchant to scan validation request from consumer mode, & just switch to merchant mode
		this.appLinkEvent$.next(appLink);
	}

	private get canReadDeepLinks(): boolean {
		return this.platform.is("cordova") && window.hasOwnProperty("Branch");
	}

}
