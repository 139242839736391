import {action, payload} from "ts-action";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {GeoQueryCriteria} from "../../geo-query/geo-query-criteria.model";

export module OfferSearchActions {

	export const SearchOffers = action("[Offer Search] Search Offers", payload<GeoQueryCriteria>());

	export const InitializeSearchQuery = action("[Offer Search] Initialize Search Query", payload<GeoQueryCriteria>());

	export const UpdateSearchCriteria = action("[Offer Search] Update Search Criteria", payload<GeoQueryCriteria>());

	export const SearchCriteriaReceived = action("[Offer Search] Search Criteria Received", payload<GeoQueryCriteria>());

	export const SearchResultsReceived = action("[Offer Search] Search Results Received", payload<MerchantLocationOffers[]>());

	export const ClearSearchResults = action("[Offer Search] Clear Search Results");

}
